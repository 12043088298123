.goods {
  margin: 0 auto;
  max-width: 188rem;

  &__header {
    display: flex;
    align-items: center;
    max-width: 188rem;
    padding: 0 5.5rem 0 1.6rem;
    margin: 0 auto;
  }

  &__content {
    display: flex;
    margin-top: 1.6rem;
    justify-content: space-between;
  }

  &__title {
    @include heading-1;
  }

  &__list {
    display: flex;
    margin-top: 0.5rem;
  }

  &__item {
    margin-right: 2.4rem;
  }

  &__link {
    @include heading-4;

    color: $color-blue-product;
    transition: opacity $ease-main, color $ease-main;

    &:hover {
      opacity: 0.5;
    }

    &_isActive {
      color: $color-black;
      pointer-events: none;
    }
  }

  &__controlers {
    margin-top: 0.5rem;
  }

  &__btn {
    position: relative;
    width: 8rem;
    height: 1.6rem;

    border-radius: 1.6rem;
    background: $color-grey-product;

    transition: all $ease-main;

    &:hover,
    &:active {
      background: $color-blue-product;

      svg {
        fill: $color-white;
      }
    }

    & svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;

      fill: $color-white;
    }
  }

  &__prev {
    margin-right: 0.6rem;
  }

  &__caption {
    margin-left: auto;
    margin-top: 0.4rem;

    @include heading-4;
    color: $color-grey-header-first;
  }

  &__drone {
    background: url('../images/white-square.svg') no-repeat bottom, #C5CAD5;

    &::before {
      @include pseudo;

      width: 1.2rem;
      height: 3.2rem;

      right: 3.4rem;
      top: 2.4rem;

      background: $color-white;
      border-radius: 0.6rem;
    }

    &::after {
      @include pseudo;

      width: 3.2rem;
      height: 1.2rem;

      right: 2.4rem;
      top: 3.4rem;

      background: $color-white;
      border-radius: 0.6rem;
    }

    .card__image {
      margin-top: 0.6rem;
    }

    .card__details {
      margin-left: 2.4rem;
    }

    .card__text {
      width: 28%;
      margin-top: -1.5rem;
    }

    .card__picture {
      margin: -10.2rem 0 0 8.1rem;
    }
  }

  &__surface {
    padding: 0;

    background: $color-white;

    &::before {
      @include pseudo;

      width: 3.2rem;
      height: 3.2rem;

      top: 2.4rem;
      right: 2.4rem;

      background: $color-red;
      border-radius: 50%;
    }

    .card__title {
      width: 52%;
    }

    .card__details {
      flex-direction: column;
      margin-top: 4.5rem;
      overflow: hidden;

      border-radius: 0.6rem;
    }

    .card__picture {
      width: 100%;
      margin-top: 2.6rem;
      overflow: hidden;

      border-radius: 0.8rem;
    }

    .card__text {
      width: 46%;
      margin-left: 2.4rem;

      line-height: 1.2;
    }
  }

  &__galaxy {
    display: none;
    width: 40rem !important;
    padding: 0;
    justify-content: space-between;

    overflow: visible;

    &:hover {
      cursor: default;

      .card__more {
        display: none;
      }
    }
  }

  &__lenovo {
    padding: 0;

    background: $color-white;

    &::before {
      @include pseudo;

      width: 3.2rem;
      height: 3.2rem;

      top: 2.4rem;
      right: 2.4rem;

      background: $color-dark-blue-product;
      border-radius: 50%;
    }

    .card__title {
      width: 55%;
    }

    .card__image {
      display: flex;
      height: 100%;
      margin-top: 1.8rem;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      border-radius: 0.8rem;
    }
  }

  &__flagmans {
    display: block;
    width: 49.1%;

    @include screen('sm') {
      width: 59%;
    }

    @include screen('md') {
      width: 65.8%;
    }

    @include screen('lg') {
      width: 112rem;
    }

    .collection__title {
      opacity: 1;
    }

    .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .collection__controlers {
      @include screen('lg') {
        bottom: 1.3rem;
      }
    }

    .collection__lists {
      justify-content: space-between;
    }

    .collection__list {
      &:last-child {
        display: none;

        @include screen('md') {
          display: block;
        }
      }

      &:nth-child(3) {
        display: none;

        @include screen('sm') {
          display: block;
        }
      }
    }

  }

  &__cam {
    display: block;
    width: 49.2%;

    @include screen('sm') {
      width: 39.6%;
    }

    @include screen('md') {
      width: 33%;
    }

    @include screen('lg') {
      width: 67.2rem;
    }

    .collection__title {
      opacity: 1;
    }

    .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      &::before {
        width: 89.2%;

        left: 2.3rem;

        background: rgba(22, 33, 88, 0.75);

        @include screen('lg') {
          width: 93.2%;
        }
      }

      &::after {
        left: 4.6rem;

        background: rgba(22, 33, 88, 0.5);
      }
    }

    .collection__controlers {
      @include screen('lg') {
        bottom: 1.3rem;
      }
    }

    .collection__bottom {
      background: $color-collection-blue;

      .swiper-container {
        overflow: hidden;
      }

      .swiper-slide {
        opacity: 1;
      }
    }

    .collection__list {
      &:last-child {
        display: none;

        @include screen('lg') {
          display: block;
        }
      }
    }

  }

  &__notebook {
    display: block;
    width: 39.6%;

    @include screen('md') {
      width: 49.4%;
    }

    .collection__title {
      opacity: 1;
      color: $color-black;
    }

    .collection__link {
      color: $color-blue-product;
    }

    .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background: linear-gradient(#ececec 0%, rgba(255, 255, 255, 0) 100%);
    }

    .collection__controlers {
      & button {
        background: $color-blue-product;
      }
    }

    .swiper-pagination-bullet {
      background: $color-blue-product;
    }

    .collection__bottom {
      background: linear-gradient(#ececec, hsla(0, 0%, 100%, 0));
    }

    .swiper-container {
      z-index: unset;
    }

    .collection__list {
      &:last-child {
        display: none;

        @include screen('md') {
          display: block;
        }
      }
    }

    .collection__btn {
      color: $color-white;
      background: $color-grey-product;
    }
  }

  &__mouses {
    display: block;
    width: 59%;

    @include screen('md') {
      width: 49.4%;
    }

    .collection__title {
      opacity: 1;
    }

    .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background: linear-gradient(#ececec 0%, rgba(255, 255, 255, 0) 100%);
    }

    .swiper-pagination-bullet {
      background: $color-blue-product;
    }

    .collection__link {
      color: $color-blue-product;
    }

    .collection__bottom {
      background: linear-gradient(#ececec, hsla(0, 0%, 100%, 0));
    }

    .swiper-container {
      z-index: unset;
    }

    .collection__btn {
      color: $color-white;
      background: $color-grey-product;
    }
  }

  &__internet,
  &__souvenirs,
  &__car {
    display: block;

    position: relative;
    width: 49%;
    height: 436px;
    padding: 1.7rem 0 3rem 0;

    @include screen('sm') {
      width: 49.3%;
    }

    @include screen('md') {
      width: 33%;
    }

    @include screen('lg') {
      width: 44.8rem;
    }

    .collection__btn {
      position: absolute;
      top: 92%;
      left: 50%;
      transform: translateX(-50%);

      background: $color-grey-product;
    }

    .collection__wrapper {
      display: flex;
      position: relative;
      margin-top: 1.5rem;
      padding-top: 0.6rem;
      justify-content: space-between;

      @include screen('sm') {
        padding-top: 0.7rem;
      }

      &::before {
        @include pseudo;

        width: 0.1rem;
        height: 70%;

        top: 0;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);

        background: $color-grey-product;
      }
    }

    .collection__controlers {
      bottom: 1.4rem;
      left: 47%;

      & button {
        margin-right: 0.5rem;
        background: $color-blue-product;
      }
    }

    .collection__title {
      opacity: 1;
    }

    .swiper-pagination-bullet {
      background: $color-blue-product;
    }

    .collection__link {
      color: $color-blue-product;
    }

    .collection__list {
      width: 23.2rem;
      padding-left: 1.6rem;

      @include screen('sm') {
        width: 28.6rem;
      }

      @include screen('md') {
        width: 22.4rem;
      }
    }

    .collection__item {
      margin-bottom: 0.56rem;
    }

    .collection__inner {
      margin-top: 0;
    }

    .product {
      width: 23.2rem;

      @include screen('sm') {
        width: 28.6rem;
      }

      @include screen('md') {
        width: 22.4rem;
        padding: 0 1.6rem 2.4rem 1.6rem;
      }

      &::after {
        background: transparent;
      }

      &:hover {
        // transform: none;
        // box-shadow: none;
        // z-index: 1;

        &::before {
          box-shadow: 0 1.6rem 2.4rem 0 rgba(0, 0, 0, 0.24);
        }
      }
    }

    .product__info {
      margin-top: 1.3rem;
    }

    .product__image {
      margin: 0 auto;
    }

    .swiper-container {
      width: 22.4rem;
    }

    .swiper-pagination-bullet {
      display: none;
      margin-right: 0.5rem;
    }

    .product__buttons {
      transition: none;
    }
  }

  &__car {
    display: none;

    @include screen('lg') {
      display: block;
    }

    &:last-child {
      @include screen('md') {
        display: block;
      }
    }
  }

  &__playstation {
    background: url('../images/white-square-second.svg') no-repeat bottom, $color-blue-product;

    &::before {
      @include pseudo;

      width: 1.2rem;
      height: 3.2rem;

      right: 3.4rem;
      top: 2.4rem;

      background: $color-white;
      border-radius: 0.6rem;
    }

    &::after {
      @include pseudo;

      width: 3.2rem;
      height: 1.2rem;

      right: 2.4rem;
      top: 3.4rem;

      background: $color-white;
      border-radius: 0.6rem;
    }

    .card__title {
      margin-left: 2.4rem;

      color: $color-white;
    }

    .card__image {
      margin-top: 4.5rem;
    }

    .card__details {
      margin-left: 2.4rem;
    }

    .card__text {
      width: 44%;
      margin-top: 6.8rem;

      color: $color-grey-header-first;
    }

    .card__picture {
      margin: -7rem -1.5rem 0 0;
    }
  }

  &__ctulhu {
    padding: 0;

    border-radius: 0.8rem;
    background: $color-white;

    .card__title {
      margin-left: 2.4rem;
      margin-bottom: 3.7rem;
    }

    .card__image {
      border-radius: 0.8rem;
      overflow: hidden;

      img {
        border-radius: 0.8rem;
      }
    }
  }

  &__gamepad {
    width: 44.8rem !important;
    height: 40.4rem;

    background: $color-white;

    .card__title {
      margin-left: 1.6rem;
    }

    .card__wrapper {
      display: flex;
      margin-top: 0.4rem;
    }

    .product {
      width: 26.4rem;

      &:hover {
        box-shadow: none;

        .product__promo {
          opacity: 0;
        }

        &::after {
          display: block;
        }
      }

      &:nth-child(2) {
        &::after {
          background: transparent;
        }
      }
    }

    .swiper-container {
      overflow: hidden !important;
    }
  }

  &__acoustics {
    display: block;
    width: 49.1%;

    @include screen('sm') {
      width: 39.6%;
    }

    @include screen('md') {
      width: 33%;
      height: 60.5rem;
    }

    @include screen('lg') {
      width: 67.2rem;
    }

    .collection__title {
      opacity: 1;
    }

    .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background: $color-promo-first;

      &::before {
        left: 2.6rem;

        background: rgba($color-promo-first, 0.5);
      }

      &::after {
        left: 4.6rem;
        bottom: -1.6rem;

        background: rgba($color-promo-first, 0.2);
      }
    }

    .collection__inner {
      @include screen('lg') {
        justify-content: flex-start;
      }
    }

    .collection__products {
      justify-content: center;
    }

    .collection__link {
      color: $color-black;
    }

    .product {
      width: 23.2rem;

      @include screen('sm') {
        width: 23rem;
      }

      @include screen('md') {
        width: 22.4rem;
      }

      &:nth-child(2) {

        &::after {
          background: transparent;

          @include screen('lg') {
            background: $color-grey-product;
          }
        }
      }

      &:nth-child(3) {
        display: none;

        @include screen('lg') {
          display: block;
        }

        &::after {
          background: transparent;
        }
      }
    }

    .swiper-container {
      overflow: hidden;
    }

    .swiper-slide {
      opacity: 1;
    }
  }

  &__buyers {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49.2% !important;
    height: 60.3rem;
    margin-right: 0;
    padding: 0;
    flex-grow: 0;

    &:hover {
      transform: scale(1);
      box-shadow: none;
    }

    @include screen('sm') {
      width: 59.7% !important;
    }

    @include screen('md') {
      width: 66.5% !important;
    }

    @include screen('lg') {
      width: 112.8rem !important;
    }

    .card:hover {
      cursor: default;
    }

    .card__plus-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      height: 37.6rem;
      padding-bottom: 3.1rem;

      border-radius: 0.8rem;
      background: url('../images/green-square-big.svg') no-repeat bottom center, $color-promo-first;
      background-size: 100%;

      transition: box-shadow $ease-main;

      &:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
      }

      @include screen('sm') {
        background: url('../images/white-square-buyers.svg') no-repeat right top, $color-promo-first;
      }

      @include screen('md') {
        background: url('../images/big-plus.svg') no-repeat 9%, url('../images/green-square-big.svg') no-repeat bottom center, $color-promo-first;
      }

      @include screen('lg') {
        background: url('../images/white-square-buyers-desktop.svg') no-repeat right top, $color-promo-first;
      }

      &::before {
        @include pseudo;
        width: 1.2rem;
        height: 3.2rem;
        right: 3.3rem;
        top: 2.4rem;

        background: $color-white;
        border-radius: .6rem;
      }

      &::after {
        @include pseudo;
        width: 3.2rem;
        height: 1.2rem;
        right: 2.3rem;
        top: 3.4rem;

        background: $color-white;
        border-radius: .6rem;
      }

      .card__text {
        display: none;

        @include heading-1;
        color: $color-black;
        line-height: 1.3;

        @include screen('sm') {
          display: block;
          width: 48%;
          margin-left: 2.4rem;
        }

        @include screen('md') {
          position: absolute;
          width: 30%;
          top: 14rem;
          left: 6.4rem;

          font-size: 2.4rem;
          text-align: center;
        }

        @include screen('lg') {
          position: relative;
          top: auto;
          left: auto;

          font-size: 3.2rem;
          text-align: left;
        }
      }
    }

    .card__gift-card,
    .card__loan {
      display: flex;
      height: 21.6rem;
      padding: 1.6rem 2rem 2.2rem 2.4rem;
      flex-grow: 0;

      background: $color-white;
      border-radius: 0.8rem;

      transition: box-shadow $ease-main;

      &:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
      }

      .card__title {
        margin: 0;

        color: $color-black;
      }

      @include screen('sm') {
        width: 49% !important;
      }

      @include screen('md') {
        width: 49.6% !important;
      }
    }

    .card__loan {
      display: none;
      margin-right: 0;

      background: url('../images/persent.svg') no-repeat 87% 50%, $color-white;

      @include screen('sm') {
        display: block;

        .card__details {
          padding: 0.2rem 0 0 1.1rem;
        }
      }

      @include screen('lg') {
        background-position: 94% 50%;
      }

      .card__text {
        width: 60%;

        @include screen('md') {
          width: 45%;
        }

        @include screen('lg') {
          width: 30%;
        }
      }
    }

    .card__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .card__picture {
      display: block;
      margin-top: 1.6rem;

      @include screen('sm') {
        display: none;
      }

      @include screen('md') {
        display: block;
        margin-right: -0.8rem;
      }

      @include screen('lg') {
        margin-right: 2rem;
      }
    }

    .card__title {
      margin: 1.6rem 0 0 2.4rem;
      color: $color-white;
    }

    .card__text {
      width: 90%;

      color: $color-grey-header-first;

      @include screen('sm') {
        width: 65%;
      }

      @include screen('md') {
        width: 92%;
      }

      @include screen('lg') {
        width: 65%;
      }
    }

    .card__image {
      position: absolute;
      top: 0;
      left: 32%;

      @include screen('sm') {
        left: 53%;
      }

      @include screen('md') {
        left: 60%;
      }

      @include screen('lg') {
        left: 65%;
      }
    }
  }

  &__garanty {
    display: flex;
    width: 40rem !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;

    overflow: visible;

    &:hover {
      cursor: default;
    }

    .card__setting {
      height: 21.6rem;

      background: url('../images/red-square-big.svg') no-repeat bottom right, $color-red;

      .card-small {
        padding: 1.6rem 0 1.5rem 2.4rem;
      }

      .card-small__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .card-small__title {
        width: 60%;

        color: $color-white;
      }

      .card-small__text {
        width: 60%;
        margin-bottom: 1.5rem;

        color: $color-white;
      }
    }

    .card__insurance {
      height: 21.6rem;

      background: url('../images/white-square-small.svg') no-repeat bottom right, $color-blue-product;

      &.card-small {
        padding: 2.4rem;
      }

      .card-small__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .card-small__title {
        width: 60%;
        margin-top: -0.8rem;

        color: $color-white;
      }

      .card-small__text {
        width: 50%;

        color: $color-white;
      }
    }
  }

  &__games {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: url('../images/illustration.svg') no-repeat center, $color-white;

    .card__list {
      margin-left: 2.4rem;
    }

    .card__item {
      margin-bottom: 0.8rem;
    }

    .card__link {
      color: $color-grey-text;
    }
  }

  &__gifts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: $color-collection-blue;

    .card__title {
      color: $color-white;
    }

    .card__list {
      margin-left: 2.4rem;
    }

    .card__item {
      margin-bottom: 0.8rem;
    }

    .card__link {
      color: $color-white;
    }
  }

  &__accessories {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: $color-white;

    .card__list {
      margin-left: 2.4rem;
    }

    .card__item {
      margin-bottom: 0.8rem;
    }

    .card__link {
      color: $color-grey-text;
    }
  }

  &__popular {
    display: block;
    height: 60.5rem;
    width: 39.6%;

    @include screen('md') {
      width: 33%;
    }

    @include screen('lg') {
      width: 37.2%;
    }

    .collection__navigation {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      background: $color-card-brown;

      &::before {
        width: 87.3%;
        left: 2.3rem;
        bottom: -0.7rem;

        background: rgba($color-card-brown, 0.5);

        @include screen('md') {
          width: 90%;
        }
      }

      &::after {
        width: 78%;
        left: 4.4rem;

        background: rgba($color-card-brown, 0.2);

        @include screen('md') {
          width: 80%;
        }
      }
    }


    .collection__link {
      color: $color-black;
    }

    .collection__products {
      justify-content: center;
    }

    .product {

      &:nth-child(2) {
        &::after {
          background: transparent;
        }
      }

      &:last-child {
        display: none;

        @include screen('lg') {
          display: block;
        }
      }

    }
  }

  &__business {
    display: none;
    margin-top: 2.4rem;

    @include screen('sm') {
      display: block;
    }

    // @include screen('lg') {
    //   display: none;
    // }

    .collection__content {
      display: flex;
      justify-content: space-between;
    }

    .collection__title {
      opacity: 1;
    }

    .goods__header {
      justify-content: space-between;

      @include screen('md') {
        padding-right: 1.6rem;
      }
    }

    .goods__link {
      margin-top: 1.2rem;
    }
  }

  &__bonus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 59.6% !important;
    height: 60rem;
    margin-right: 0;
    padding: 0;
    flex-grow: 0;

    &:hover {
      cursor: default;
    }

    @include screen('md') {
      width: 66.4% !important;
    }

    @include screen('lg') {
      width: 62.3% !important;
      height: 60.5rem;
    }

    .card__plus {
      position: relative;
      height: 37.6rem;
      overflow: hidden;

      background: url('../images/white-square-third.svg') no-repeat right bottom, $color-card-brown;
      border-radius: 0.8rem;

      transition: box-shadow $ease-main;

      &:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
      }

      .card__text {
        width: 50%;
        margin-left: 2.4rem;
        margin-top: 26.5rem;

        @include heading-1;

        @include screen('md') {
          width: 40%;
        }
      }

      .card__background {
        display: none;
        @include fill-img;
        width: 100%;
        height: 100%;
      }
    }

    .card__corporate {
      width: 49.4%;
      height: 21.6rem;
      padding: 0 0 2.1rem 0;

      border-radius: 0.8rem;
      background: $color-dark-blue-product;

      transition: box-shadow $ease-main;

      &:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
      }

      .card__title {
        color: $color-white;

        @include screen('md') {
          width: 50%;
        }
      }

      .card__text {
        width: 50%;
        margin-left: 2.4rem;

        color: rgba($color-white, 0.5);
      }
    }

    .card__delivery {
      width: 49.3%;
      height: 21.6rem;
      padding: 0 0 2.1rem 0;

      border-radius: 0.8rem;
      background: $color-white;

      transition: box-shadow $ease-main;

      &:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
      }

      .card__title {
        @include screen('md') {
          width: 50%;
        }
      }

      .card__text {
        margin-left: 2.4rem;

        color: $color-grey-header-first;
      }
    }

    .card__image {
      position: absolute;
      top: 0;
      left: 56%;

      @include screen('md') {
        left: 58.4%;
      }
    }

    .card__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .card__text {
      width: 60%;

      @include screen('md') {
        width: 35%;
      }
    }

  }

  &__redemption {
    width: 44.8rem !important;

    background: $color-white;

    .card__title {
      width: 70%;
    }

    .card__image {
      margin-top: 2.4rem;

      & img {
        border-radius: 0.8rem;
      }
    }
  }

  &__fortnite {
    padding-bottom: 0;

    background: $color-white;

    .card__image {
      height: 100%;
      padding-top: 2.4rem;
      padding-left: 1.4rem;

      overflow: hidden;
    }
  }

  &__watch {
    padding-bottom: 0;

    background: $color-white;

    .card__image {
      height: 100%;
      padding-top: 2.4rem;

      overflow: hidden;
      border-radius: 0.8rem;

      & img {
        border-radius: 0.8rem;
      }
    }
  }
}




.swiper-container {
  overflow: hidden;
}

.visible {
  display: flex;
  transition: opacity $ease-main;
}

// .swiper-slide {
//   opacity: 0;
// }

// .swiper-slide-visible {
//   opacity: 1;
// }
