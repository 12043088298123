.header {
  width: 100%;
  position: relative;
  z-index: 999;

  .container {
    width: 100%;
  }

  &.is-fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  &__wrapper {
    display: flex;
    height: 60.4rem;
    margin-top: 0.9rem;
    justify-content: space-between;
  }

  &__hits {
    @include screen('sm') {
      display: block;
      width: 18.6%;
    }

    @include screen('md') {
      width: 31.7%;
    }

    @include screen('lg') {
      width: 67.2rem;

      & .product:nth-child(2) {
        &::after {
          background: $color-grey-product;
        }
      }
    }

    .product {
      &:first-child {
        &::after {
          background: transparent;

          @include screen('md') {
            background: $color-grey-product;
          }
        }
      }

      &:nth-child(2) {
        display: none;

        @include screen('md') {
          display: block;
        }

        &::after {
          background: transparent;

          @include screen('lg') {
            background: $color-grey-product;
          }
        }
      }

      &:nth-child(3) {
        display: none;

        @include screen('lg') {
          display: block;
        }

        &::after {
          background: transparent;
        }
      }
    }

    .collection__navigation {
      display: flex;
      position: relative;
      z-index: 22;
      flex-direction: column;
      justify-content: space-between;

      background: $color-red;
      border-radius: 0.8rem;
    }

    .collection__list {
      &:nth-child(2) {
        display: none;

        @include screen('md') {
          display: block;
        }
      }

      &:last-child {
        display: none;

        @include screen('lg') {
          display: block;
        }
      }
    }

    .collection__products {
      justify-content: center;
    }

    .collection__lists {
      display: flex;
      justify-content: space-between;
      // margin-top: 1.6rem;
    }
  }
}
