.footer {
  position: relative;
  margin-top: 10.4rem;
  padding-bottom: 5.6rem;

  background: linear-gradient(to bottom, #eee 0, #fff 35rem);

  @include screen('sm') {
    margin-top: 11.4rem;
  }

  @include screen('lg') {
    margin-top: 11.4rem;
  }

  .user-info__list {
    width: auto;
    margin: -0.3rem 1.4rem 0 0;
  }

  .user-info__item_phone {
    display: flex;
  }

  .user-info__link_phone {
    & span {
      color: $color-blue-product;
    }
  }


  &__content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.2rem;

    @include screen('md') {
      padding-right: 20rem;
    }

    @include screen('lg') {
      padding-right: 50rem;
    }
  }

  &__navigation {
    display: flex;
    flex-wrap: wrap;

    @media(min-width: 1026px) {
      justify-content: space-between;
    }

    @include screen('sm') {
      width: 66%;
      justify-content: flex-start;
    }

    @include screen('lg') {
      width: 100%;
    }
  }

  &__user-block {
    display: flex;
    margin-top: -0.6rem;

    @include screen('sm') {
      flex-direction: column;
      width: 34%;
      padding-top: 4.1rem;
    }

    @include screen('md') {
      width: 31.4%;
      padding-top: 4.4rem;
    }

    @include screen('lg') {
      width: 100%;
      flex-direction: row;
      padding-top: 0;
    }
  }

  &__col-left {
    width: 45.7%;

    @include screen('sm') {
      width: 100%;
      margin-bottom: 7rem;
    }

    @include screen('lg') {
      width: 23.5%;
    }
  }

  &__col-right {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
    margin-left: auto;

    @include screen('sm') {

    }

    .footer__title {
      width: 100%;
      margin-right: 0;
      max-width: none;
      margin-bottom: 2.2rem;

      @include screen('sm') {
        margin-top: 12.5rem;
      }

      @include screen('lg') {
        margin-top: 0;
      }
    }

    @include screen('sm') {
      width: 100%;
      align-items: flex-start;
    }

    @include screen('lg') {
      width: 50%;
      flex-grow: 0;
      margin-left: 10.7%;
    }

    & .socials {
      display: none;

      @include screen('sm') {
        display: flex;
      }

      @include screen('lg') {
        display: none;
      }
    }
  }

  &__subscribe {
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // height: 4rem;
    // width: 100%;
    margin-bottom: 1.4rem;
    // padding: 1.1rem 1.9rem 1.1rem 0;

    input {
      background: rgba(0, 0, 0, 0.05);
    }

    // border-radius: 0.6rem;
  }

  &__btn {
    position: absolute;
    width: 1.4rem;
    height: 1.2rem;
    margin: 0.2rem -0.7rem 0 0;
    right: 1.6rem;
    top: 1.2rem;

    background: url('../images/subscribe.svg') no-repeat;
    transition: all $ease-main;
    opacity: 0.5;
  }

  &__title {
    max-width: 25rem;
    margin-bottom: 2.3rem;

    @include heading-3;

    &_margin {
      margin-bottom: 2.5rem;
    }
  }

  &__list {
    width: 19.5rem;
    margin-bottom: 6.8rem;
    // padding-top: 3.7rem;
    // background: url('../images/user.svg') no-repeat left top;
    margin-right: 11.9%;

    @media(min-width: 1026px) {
      width: 30rem;
      margin-right: 0;
    }

    svg {
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke: #ccc;
    }

    @include screen('sm') {
      margin-right: 7%;
      width: 19.5rem;
    }

    @include screen('md') {
      margin-right: 12.4%;
    }

    @include screen('lg') {
      margin-right: 6.1%;
    }

    &:last-child {
      margin-right: 0;
    }


    &_services {
      // background: url('../images/services.svg') no-repeat left top;

      @include screen('sm') {
        margin-right: 0;
      }

      @include screen('lg') {
        margin-right: 6.2%;
      }
    }

    &_business {
      // background: url('../images/business.svg') no-repeat left top;

      @include screen('lg') {
        margin-right: 8.9%;
      }
    }

    &_press {
      // background: url('../images/press.svg') no-repeat left top;
    }

    &_company {
      // background: url('../images/company.svg') no-repeat left top;
    }
  }

  &__text {
    font-weight: 500;
    letter-spacing: -0.1px;
  }

  &__icon {
    margin-bottom: 2.3rem;
  }

  &__item {
    margin-bottom: 0.96rem;
  }

  &__link {
    font-size: 2rem;
    color: $color-blue-product;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }

    &_triangle {

      &:hover {
        opacity: 1;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: #ccc;
        transition: all $ease-main;
      }
    }
  }

  &__info {
    max-width: 30rem;

    @include about;

    & a {
      color: $color-blue-product;
      transition: all $ease-main;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__about {
    width: 50%;
    margin-top: 9.5rem;

    @include screen('sm') {
      width: 51%;
    }

    @include screen('md') {
      margin-top: 9.7rem;
    }

    @include screen('lg') {
      margin-top: 9.2rem;
    }

    .socials {
      display: flex;

      margin: 4.2rem 0 0 0;

      @include screen('sm') {
        display: none;
      }
    }
  }

  &__city {
    margin-bottom: 2.3rem;
    margin-top: -2.3rem;
    position: relative;
  }

  &__city-text {
    @include heading-3;

    position: relative;
    color: $color-blue-product;
    transition: opacity $ease-main;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    &::after {
      @include pseudo;

      width: 1rem;
      height: 1rem;
      right: -2.1rem;
      top: 55%;
      transform: translateY(-50%);
      background: url('../images/location-pin.svg') no-repeat;
      background-position: 100%;
    }
  }

  &__company {
    margin-bottom: 1.4rem;

    @include about;
  }

  &__descr {
    max-width: 43rem;

    line-height: 1.2;
    @include about;

    @include screen('sm') {
      max-width: 60rem;
    }
  }

  &__creator {
    display: flex;
    align-items: center;
    // padding-left: 5.4rem;
    margin-left: 16.7%;
    margin-top: 11.5rem;

    // background: url('../images/design.svg') no-repeat 0;

    svg {
      width: 4rem;
      height: 3rem;
      margin-right: 1.3rem;
      fill: $color-blue-product;
    }

    @include screen('sm') {
      margin-top: 11rem;
      margin-left: 15%;
    }

    @include screen('md') {
      display: flex;
      margin-top: 11.2rem;
      margin-left: 17.6%;
    }

    @include screen('lg') {
      margin: 10.6rem 0 0 0.7rem;
    }

    & b {
      display: block;

      @include heading-9;

      color: $color-black;
    }

    & span {
      display: block;
      margin-top: -0.4rem;
      transition: color $ease-main;

      color: $color-blue-product;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__contacts {
    max-width: 31rem;
    margin-right: -0.6rem;
  }

  &__adress {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px;
  }

  &__phones {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-right: 0.9rem;
    width: 29.5rem;

    @include screen('sm') {
      width: 31.5rem;
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;

    @include about;
    color: rgba(0, 0, 0, 0.4);
  }

  &__tel {
    @include heading-9;
    color: $color-black;
  }

  &__authors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.1rem;

    @media(min-width: 1025px) {
      justify-content: space-between;
    }

    @include screen('sm') {
      width: 100%;
      margin-top: -7rem;
      justify-content: flex-start;
    }

    @include screen('lg') {
      margin-top: 0;
    }

    .socials {
      @include screen('sm') {
        display: none;
      }

      @include screen('lg') {
        display: flex;
      }
    }
  }

  input:valid~button {
    opacity: 1;
  }

  input:invalid {
    border: 1px solid red;
  }

  .modal-city {
    &__input:invalid {
      border: transparent;
    }
  }
}


.socials {
  display: flex;
  width: 100%;

  &__item {
    width: 2.4rem;
    height: 2.4rem;

    &:not(:last-child) {
      margin-right: 2.4rem;
    }
  }

  &__link {
    &:hover {
      svg {
        fill: $color-blue-product;
      }
    }

    svg {
      width: 100%;
      height: 100%;

      fill: $color-grey-product;
      transition: all $ease-main;
    }
  }
}
