.menu {
  display: none;
  position: fixed;
  height: 78vh;
  left: 1.6rem;
  right: 1.6rem;
  top: 3.6rem;
  overflow: hidden;
  transition: opacity $ease-main, transform $ease-main;
  background: $color-white;
  border-radius: 0.8rem;
  opacity: 0;
  transform: translate3d(0, 15px, 0);
  padding-top: 5.6rem;

  .js-scrollbar {
    height: calc(100% - 5.6rem);
    width: 100%;
  }

  &.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: .1s;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__container {
    padding: 2.5rem 4rem 4.5rem 4rem;
  }

  &__content {
    flex-grow: 1;
    margin-right: 2.6rem;
  }

  &__navigation, &__adress-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem 0;
  }

  &__navigation {
    margin-top: -6rem;
  }

  &__adress-list {
    margin-top: -2.2rem;
  }

  &__inner {
    width: 43rem;
    display: block;
  }

  &__title {
    min-width: 22rem;
    letter-spacing: 0.1px;

    @include heading-3;

    a {
      display: block;
      color: $color-blue-product;
      transition: all $ease-main;

      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }

    &_margin {
      margin-bottom: 2.5rem;
    }
  }

  &__list, &__adress-item {
    width: 33.333%;
    padding: 0 2rem 0;
    box-sizing: border-box;
  }

  &__adress-item {
    padding-top: 2.2rem;
  }

  &__list {
    padding-top: 7.6rem;

    svg {
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke: #ccc;
    }

    .menu__title {
      margin-bottom: 2.3rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.96rem;
    }
  }

  &__link {
    font-size: 2rem;
    color: $color-blue-product;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }

    &_triangle {
      display: block;
      padding-top: 3px;

      svg {
        width: 1rem;
        height: 1rem;
        fill: $color-grey-product;
        transition: opacity $ease-main;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  &__icon {
    height: 2rem;
    margin-bottom: 1.8rem;
  }

  &__card {
    display: block;
    position: relative;
    width: 43.1rem;
    height: 37.6rem;
    margin-top: 1.6rem;

    background: url('../images/menu-card.jpg') no-repeat;
    border-radius: 0.8rem;
    overflow: hidden;

    transition: transform $ease-main;

    &:hover {
      transform: translateY(-2px);
    }
  }

  &__circle {
    position: absolute;
    width: 56rem;
    height: 56rem;

    z-index: 2;

    border-radius: 50%;
    background: #fbfbfb;

    bottom: -30.4rem;
    left: -27rem;
  }

  &__device {
    position: relative;
    margin: -2.7rem 0 0 16rem;

    img:first-child {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      top: 1.6rem;
      right: 12.6rem;
    }

    img:last-child {
      position: absolute;
      width: 3rem;
      height: 3.8rem;

      bottom: 54%;
      left: 12px;
    }
  }

  &__caption {
    margin: 1.9rem 0 0 2.4rem;

    font-size: 2.4rem;
    font-family: $font-second;
    color: $color-white
  }

  &__details {
    margin: -16.4rem 0 0 2.4rem;
    position: relative;
    width: 18.3rem;
    z-index: 7;
  }

  &__promo {
    margin-bottom: 0.8rem;

    font-size: 2.4rem;
    font-family: $font-second;
    line-height: 1.3;
  }

  &__footer {
    margin-top: 11.6rem;
  }

  &__activation {
    font-size: 1.6rem;
    color: #666666;
    opacity: 0.8;
  }

  &__user-block {
    position: relative;
    padding-top: 5.8rem;
  }

  &__authors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.1rem;
  }

  &__about {
    width: 50%;
    margin-top: 9.1rem;

    .socials {
      display: flex;

      margin: 4.2rem 0 0 0;

      @include screen('sm') {
        display: none;
      }
    }
  }

  &__city {
    margin-bottom: 2.5rem;
    position: relative;
  }

  &__city-text {
    position: relative;

    @include heading-3;

    color: $color-blue-product;
    transition: opacity $ease-main;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    &::after {
      @include pseudo;

      width: 1rem;
      height: 1rem;
      right: -2.1rem;
      top: 55%;
      transform: translateY(-50%);
      background: url('../images/location-pin.svg') no-repeat;
      background-position: 100%;
    }
  }

  &__company {
    margin-bottom: 1.4rem;

    @include about;
  }

  &__descr {
    max-width: 43rem;

    line-height: 1.2;
    @include about;

    @include screen('sm') {
      max-width: 60rem;
    }
  }

  &__contacts {
    max-width: 55rem;
    margin-right: -0.6rem;
    margin-top: -1px;
  }

  &__adress-box {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px;

    .menu__title {
      margin-bottom: 2.8rem;
    }
  }

  &__adress-item {
    font-size: 0;
  }

  &__adress {
    font-size: 2rem;
  }

  &__phones {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-right: 0.9rem;
    width: 29.5rem;

    @include screen('sm') {
      width: 31.5rem;
    }
  }

  &__phone {
    display: flex;
    flex-direction: column;

    @include about;
    color: rgba(0, 0, 0, 0.4);
  }

  &__tel {
    @include heading-9;
    color: $color-black;
  }

  &__metro {
    position: relative;
    padding-left: 1.7rem;
    line-height: 1;
    font-size: 1.2rem;
    color: #666;
    font-weight: 400;

    &::before {
      position: absolute;
      content: 'М';
      text-align: center;
      width: 1.2rem;
      height: 1.2rem;
      top: 0;
      left: 0;
      line-height: 1.2rem;
      background: $color-blue-product;
      border-radius: 50%;
      color: $color-white;
      font-family: $font-second;
      font-size: 0.8rem;
    }

    &_red {
      &::before {
        background: $color-red;
      }
    }

    &_green {
      &::before {
        background: $color-promo-first;
      }
    }
  }

  &__street {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: -0.1px;
    line-height: 1.3;
  }

  &__time {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1;
    margin-top: .7rem;
    display: block;
  }

  &__more {
    margin-top: 1.4rem;
    font-size: 2rem;
    color: $color-blue-product;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }

    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-left: 1.3rem;

      fill: #ccc;
    }
  }

  &_isActive {
    padding-bottom: 5rem;
    overflow: hidden;

    .user-info__link_phone .link__text {
      color: $color-black;
    }

    .user-info__icon {
      svg {
        stroke: $color-grey-header-first;
      }
    }
  }

  .modal-city {
    left: 0rem;

    &::before {
      left: 1.4rem;
    }
  }
}
