// Текстовые стили, используемые на сайте

@mixin heading-1 {
  font-family: $font-second;
  font-size: 3.2rem;
  line-height: 1.28;
  font-weight: 400;
}

@mixin heading-2 {
  font-size: 2.4rem;
  line-height: normal;
  font-weight: 400;
}

@mixin heading-3 {
  font-family: $font-second;
  font-size: 2.4rem;
  line-height: normal;
  font-weight: 400;
}

@mixin heading-4 {
  font-size: 1.8rem;
  line-height: 1.16;
  font-weight: 400;
}

@mixin heading-5 {
  font-size: 1.6rem;
  line-height: 1.18;
  font-weight: 400;
}

@mixin heading-6 {
  font-size: 1.4rem;
  line-height: normal;
  font-weight: 500;
}

@mixin heading-7 {
  font-size: 1rem;
  line-height: normal;
  font-weight: 500;
}

@mixin heading-8 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.6;
}

@mixin heading-9 {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
}

@mixin text {
  font-size: 14px;
  line-height: 1.65;
  font-weight: 400;
}

@mixin text-small {
  font-size: 11px;
  line-height: 1.65;
  font-weight: 400;
}

@mixin about {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: normal;
  color: #777777;
}

@mixin product-features {
  font-family: 'TTRoundsBold', sans-serif;
  font-size: 1.4rem;
  line-height: 1.35;
  color: $color-white;
}
