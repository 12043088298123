.user-info {
  position: relative;
  height: 3.6rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: transform $ease-main;
  padding-bottom: 3px;

  .header.is-fixed:not(.is-menu-visible) & {
    transform: translate3d(0, -36px, 0);
  }

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__list {
    display: flex;
    width: 50%;
    align-items: center;

    &_left {
      justify-content: flex-start;
    }

    &_right {
      justify-content: flex-end;
    }
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 1.7rem;
    }

    &_phone {
      display: flex;
      align-items: center;
    }
  }

  &__link {
    color: rgba(black, .5);
    fill: rgba(black, .5);

    &:hover {
      opacity: 1;
      color: black;
      fill: black;
    }

    .header.is-menu-visible & {
      color: rgba(white, .7);
      fill: rgba(white, .7);

      &:hover {
        color: white;
        fill: white;
      }
    }

    &_phone {
      margin-left: 0.9rem;
      color: black;

      .header.is-menu-visible & {
        color: white;
      }

      &:hover {
        color: $color-red;
      }
    }

    &_city {
      position: relative;
    }

    &_support {
      position: relative;
    }
  }

  &__icon {
    width: 1.6rem;
    height: 1.6rem;
    position: relative;

    svg {
      width: 100%;
      height: 100%;
      stroke: #ccc;
      fill: none;
      transition: stroke $ease-main;

      .header.is-fixed & {
        stroke: white;
      }
    }
  }
}


.big-height {
  height: 8.4rem;
  opacity: 0;
}

.opened {
  &::after {
    right: -1.1rem;
    top: 30%;

    transform: rotate(-180deg);
  }
}
