.button {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid $color-border;
  vertical-align: middle;
  box-sizing: border-box;
  color: $color-basic;
  background-color: transparent;
  transition: opacity $ease-main;
  cursor: pointer;

  @include text;

  &:disabled,
  &.is-disabled {
    opacity: .5;
    cursor: not-allowed;
  }

  &:hover,
  &.is-active { opacity: .5; }
}
