.product {
  position: relative;
  padding: 0 1.6rem 1.6rem 1.6rem;
  z-index: 1;
  background-color: transparent;
  border-radius: 0.8rem;
  z-index: 221;

  &:hover {
    z-index: 222;

    &::after {
      display: none;
    }

    &::before {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
    }

    .product__promo {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    .product__buttons {
      opacity: 1;
    }
  }

  &::after {
    @include pseudo;
    width: 1px;
    height: 70%;

    right: 0;
    top: 1.2rem;

    background: $color-grey-product;
  }

  &::before {
    @include pseudo;
    opacity: 0;
    width: 110%;
    height: 107%;
    top: -7%;
    left: -5%;
    z-index: 1;
    background: $color-white;
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 0.8rem 2.4rem 0 rgba(0, 0, 0, 0.24);
    transition: opacity $ease-main, transform $ease-main;
    transform: scale(.95);
    pointer-events: none;
  }

  @include screen('md') {
    &::after {
      @include pseudo;
      width: 1px;
      height: 70%;

      right: 0;
      top: 1.2rem;

      background: $color-grey-product;
    }
  }


  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.2rem !important;
    height: 19.2rem;
    margin: 1rem auto 0 auto;

    @include screen('sm') {
      margin: 1.2rem auto 0 auto;
    }
  }

  &__info {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 11.3rem;
    margin: 1.4rem 0 0 0;
    z-index: 1;

    @include screen('sm') {
      height: 10.8rem;
      margin-top: 1.5rem;
    }

    @include screen('lg') {
      margin-top: 1.5rem;
    }
  }

  &__price {
    @include heading-2;
    color: $color-red;
    font-family: $font-second;

    span {
      font-family: $font-main;
    }
  }

  &__descr {
    @include heading-6;
    letter-spacing: -0.1px;
    color: $color-black;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__details {
    position: relative;
    display: flex;
    margin-top: 0.7rem;
    z-index: 1;

    @include screen('sm') {
      margin-top: 0.3rem;
    }
  }

  &__user-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__more {
    position: relative;
    width: 3rem;
    height: 1.4rem;
    margin: 0.5rem 0 0 0.9rem;

    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__article {
    margin: 0.4rem 0 0 auto;

    @include heading-8;
    color: $color-grey-product-second;
  }

  &__features {
    display: flex;
    position: absolute;
    width: 85%;
    height: 2.4rem;
    z-index: 22;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;

    border-radius: 50%;

    @include product-features;

    &:not(:first-child) {
      margin-left: -0.4rem;
    }

    &_persent {
      background: $color-dark-blue-product;

      svg {
        width: 50%;
        height: 50%;
        fill: $color-white;
      }
    }

    &_plus {
      position: relative;
      background: #AAD77D;

      // &::before {
      //   @include pseudo;
      //   width: 14px;
      //   height: 10px;

      //   background: url('../images/plus-one.svg') no-repeat;
      // }
    }

    &_p {
      background: $color-blue-product;
    }

    &_c {
      background: $color-red;
    }

    &_new {
      font-size: 0.8rem;

      background: $color-blue-product;
    }

    &_present {
      position: relative;
      background: $color-red;

      // &::before {
      //   @include pseudo;

      //   width: 14px;
      //   height: 18px;

      //   background: url('../images/present.svg') no-repeat;
      // }

      svg {
        width: 50%;
        height: 50%;
        fill: none;
        stroke: $color-white;
      }
    }

    &_important {
      position: relative;
      background: #EC875A;

      // &::before {
      //   @include pseudo;

      //   width: 2px;
      //   height: 16px;

      //   background: url('../images/important.svg') no-repeat;
      // }

      svg {
        width: 50%;
        height: 70%;

        stroke: $color-white;
      }
    }
  }

  &__points {
    @include heading-7;
    color: $color-promo-first;
    font-weight: 600;
  }

  &__discounts {
    display: flex;
    margin-right: 0.5rem;
  }

  &__persent {
    @include heading-7;
    color: $color-blue-product;
    font-weight: 600;
  }

  &__minus {
    margin-right: 0.7rem;

    text-decoration: line-through;
    @include heading-7;
    font-weight: 500;
    color: $color-grey-product;
  }

  &__buy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 2.4rem;

    background: $color-blue-product;
    border-radius: 1.6rem;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }

    & svg {
      width: 70%;
      height: 70%;

      fill: $color-white;
    }
  }

  &__ellipsis {
    top: 0.5rem;

    &,
    &::before,
    &::after {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;

      border-radius: 50%;
      background: $color-grey-product;
    }

    &::before {
      content: '';
      left: -0.8rem;
    }

    &::after {
      content: '';
      right: -0.8rem;
    }
  }

  &__promo {
    position: relative;
    opacity: 0;
    z-index: 1;
    margin-top: 1.2rem;
    transform: translate3d(0, 10px, 0);
    transition: opacity $ease-main, transform $ease-main;

    p {
      margin-bottom: 0.2rem;

      @include heading-7;
      color: $color-grey-header-first;
      letter-spacing: -0.06px;
      line-height: 1.1;
    }

    span {
      @include heading-7;
      color: $color-red;
      letter-spacing: -0.04px;
    }
  }

  &__buttons {
    display: flex;
    position: absolute;
    width: 104%;
    justify-content: space-between;
    left: -0.6rem;
    top: 25%;
    opacity: 0;
    z-index: 1;
    transition: opacity $ease-main;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      transition: all $ease-main;

      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
