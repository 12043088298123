/* ##########
Убираем outline
########## */

button::-moz-focus-inner {
  border: 0;
}

*:focus {
  outline: none;
}

/* ##########
Цвет выделения текста
########## */

::selection {
  background: $color-main;
  color: white;
}

/* ##########
Стандартный цвет плейсхолдера
########## */

input, textarea {

  &::placeholder {
    color: $color-placeholder;
    font-size: 1.6rem;
  }
}

/* ##########
Настройки для автозаполнения Chrome
########## */

input:-webkit-autofill {
  -webkit-text-fill-color: $color-input !important; // Поменять цвет на аналогичный цвету input
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: background-color 50000000000s ease-in-out 0s !important; // Убираем желтую заливку для автозаполнения в Chrome
}

/* ##########
Основные стили
########## */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; // Убираем горизонтальный скролл
  width: 100%;
  min-height: 100%;
}

body {
  box-sizing: border-box;
  font-family: $font-main; // Задаем основной шрифт
  font-size: 16px; // Убираем проблему с отступами между inline-элементами
  color: $color-basic;
  background: $color-grey-bg;
}

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

img {
  box-sizing: content-box;
}

/* ##########
Сброс стандартных отступов для типографики, списков и таблиц
########## */

h1, h2, h3, h4, h5, h6, p, span, ul, ol, li, tr, td, th, table {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;
}

span {
  font-style: inherit;
  font-family: inherit;
  font-weight: inherit;
}

a {
  text-decoration: none;
  cursor: pointer;
}

/* ##########
Сброс стилей для активных элементов, добавляем стандарнтый transition
########## */

a, input, button, select, textarea {
  transition: color $ease-main, border-color $ease-main;
}

button { cursor: pointer; }

select, input, textarea, button {
  color: $color-input;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0; // Убираем авто-закругление углов на iOS
  background-color: transparent;
  font-family: $font-main;
}
