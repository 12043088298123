@import '../../node_modules/normalize.css/normalize.css';
@import 'helpers/variables'; // Переменные
@import 'helpers/mixins'; // Миксины
@import 'helpers/fonts'; // Шрифты
@import 'helpers/gradients'; // Градиенты
@import 'helpers/basics'; // Основные общие стили
@import 'helpers/text-styles'; // Стили текстов
@import 'helpers/link-styles'; // Стили ссылок
@import 'helpers/list-styles'; // Стили списков
@import "/Users/dima/Sites/key-master/app/blocks/button/button.scss";
@import "/Users/dima/Sites/key-master/app/blocks/button/button.scss";
@import "/Users/dima/Sites/key-master/app/blocks/card/card.scss";
@import "/Users/dima/Sites/key-master/app/blocks/card/card.scss";
@import "/Users/dima/Sites/key-master/app/blocks/form-elements/form-elements.scss";
@import "/Users/dima/Sites/key-master/app/blocks/form-elements/form-elements.scss";
@import "/Users/dima/Sites/key-master/app/blocks/link/link.scss";
@import "/Users/dima/Sites/key-master/app/blocks/link/link.scss";
@import "/Users/dima/Sites/key-master/app/blocks/lists/lists.scss";
@import "/Users/dima/Sites/key-master/app/blocks/lists/lists.scss";
@import "/Users/dima/Sites/key-master/app/blocks/modal/modal.scss";
@import "/Users/dima/Sites/key-master/app/blocks/modal/modal.scss";
@import "/Users/dima/Sites/key-master/app/blocks/navigation/navigation.scss";
@import "/Users/dima/Sites/key-master/app/blocks/navigation/navigation.scss";
@import "/Users/dima/Sites/key-master/app/blocks/product/product.scss";
@import "/Users/dima/Sites/key-master/app/blocks/product/product.scss";
@import "/Users/dima/Sites/key-master/app/blocks/promo/promo.scss";
@import "/Users/dima/Sites/key-master/app/blocks/promo/promo.scss";
@import "/Users/dima/Sites/key-master/app/blocks/scrollbar/scrollbar.scss";
@import "/Users/dima/Sites/key-master/app/blocks/scrollbar/scrollbar.scss";
@import "/Users/dima/Sites/key-master/app/blocks/slider/slider.scss";
@import "/Users/dima/Sites/key-master/app/blocks/slider/slider.scss";
@import "/Users/dima/Sites/key-master/app/blocks/user-info/user-info.scss";
@import "/Users/dima/Sites/key-master/app/blocks/user-info/user-info.scss";

@import "/Users/dima/Sites/key-master/app/components/catalog/catalog.scss";
@import "/Users/dima/Sites/key-master/app/components/catalog/catalog.scss";
@import "/Users/dima/Sites/key-master/app/components/collection/collection.scss";
@import "/Users/dima/Sites/key-master/app/components/collection/collection.scss";
@import "/Users/dima/Sites/key-master/app/components/footer/footer.scss";
@import "/Users/dima/Sites/key-master/app/components/footer/footer.scss";
@import "/Users/dima/Sites/key-master/app/components/goods/goods.scss";
@import "/Users/dima/Sites/key-master/app/components/goods/goods.scss";
@import "/Users/dima/Sites/key-master/app/components/header/header.scss";
@import "/Users/dima/Sites/key-master/app/components/header/header.scss";
@import "/Users/dima/Sites/key-master/app/components/main/main.scss";
@import "/Users/dima/Sites/key-master/app/components/main/main.scss";
@import "/Users/dima/Sites/key-master/app/components/menu/menu.scss";
@import "/Users/dima/Sites/key-master/app/components/menu/menu.scss";
@import "/Users/dima/Sites/key-master/app/components/ui-kit/ui-kit.scss";
@import "/Users/dima/Sites/key-master/app/components/ui-kit/ui-kit.scss";

@import "/Users/dima/Sites/key-master/app/pages/index/index.scss";
@import "/Users/dima/Sites/key-master/app/pages/index/index.scss";
@import "/Users/dima/Sites/key-master/app/pages/sitemap/sitemap.scss";
@import "/Users/dima/Sites/key-master/app/pages/sitemap/sitemap.scss";


body {
  padding-top: 0.5px;

  &::before {
    content: '';
    background: linear-gradient(180deg, white 44.91%, $color-grey-bg 100%);
    height: 94px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.container {
  padding-left: 5.6rem;
  padding-right: 5.6rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;

  @include screen('lg') {
    width: 188rem;

    &__promo {
      padding: 0 1.2rem;
    }
  }

  &_main {
    padding: 0 4rem 0 4rem;

    @include screen('lg') {
      padding: 0 4.7rem;
    }
  }

  &_footer {
    padding: 0 5.6rem;

    @include screen('sm') {
      display: flex;
      flex-wrap: wrap;
    }

    @include screen('md') {
      justify-content: space-between;
    }

    @include screen('lg') {
      padding: 0 6.4rem;
    }
  }

  &_promo {
    max-width: 190rem;
    padding: 0 1.6rem;
    margin: 0 auto;

    @include screen('lg') {
      padding: 0 1.6rem;
    }

    @media(min-width: 1920px) {
      padding: 0 2.2rem;
    }
  }
}

.js-sr {
  visibility: hidden;
}
