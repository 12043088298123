.collection {
  display: none;
  position: relative;
  padding-top: 1.7rem;
  border-radius: 0.8rem;
  background: $color-white;

  &__title {
    opacity: 0;
    margin: 0 0 0.4rem 1.6rem;

    &_white {
      opacity: 1;
      margin-left: -1px;

      color: $color-white;
    }

    @include heading-3;

    @include screen('md') {
      opacity: 1;
    }
  }

  &__subtitle {
    @include heading-3;

    &_white {
      color: $color-white;
    }
  }

  &__products {
    display: flex;

    @include screen('md') {
      & .product:nth-child(2) {
        display: block;
      }
    }
  }

  &__wrapper {
    position: relative;

    &::after {
      @include pseudo;

      height: 80%;
      width: 2px;
      top: 0;
      right: 0;
      z-index: 22;

      background: $color-white;
    }
  }

  &__navigation {
    position: relative;
    width: 100%;
    height: 20rem;
    margin-top: -5.3rem;
    padding: 1.7rem 1.6rem 1rem 1.6rem;

    border-radius: 0.8rem;

    &_ladder {
      &::before {
        @include pseudo;

        width: 89%;
        height: 2rem;

        bottom: -0.8rem;
        left: 1.2rem;

        border-radius: 0.8rem;
        z-index: 0;

        background: rgba($color-red, 0.5);
      }

      &::after {
        @include pseudo;

        width: 80%;
        height: 2rem;

        bottom: -1.6rem;
        left: 2.2rem;

        border-radius: 0.8rem;
        z-index: 0;

        background: rgba($color-red, 0.2);
      }

      @include screen('md') {
        &::before {
          left: 2.4rem;
        }

        &::after {
          left: 4.4rem;
        }
      }

      @include screen('lg') {
        &::before {
          width: 93%;
        }

        &::after {
          width: 86%;
        }
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem;
  }

  &__item {
    width: 19.2rem;
    margin-bottom: 0.54rem;
  }

  &__link {
    color: $color-white;
    line-height: 1.2;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__controlers {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1.3rem;
    left: 50%;

    // transform: translateX(-50%);

    & button {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.2rem;

      border-radius: 50%;
      background: $color-white;
    }
  }

  &__buttons {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 4.4rem;
    top: 2.3rem;
    right: 2.1rem;
    z-index: 1;
  }

  &__prev {
    width: 1.2rem;
    height: 1.2rem;


    &:hover,
    &:active {
      & svg {
        fill: $color-blue-product;
      }
    }

    & svg {
      width: 100%;
      height: 100%;

      transition: all $ease-main;
      fill: rgba(0, 0, 0, 0.2)
    }
  }

  &__next {
    width: 1.2rem;
    height: 1.2rem;

    &:hover,
    &:active {
      & svg {
        fill: $color-blue-product;
      }
    }

    & svg {
      width: 100%;
      height: 100%;

      transition: all $ease-main;
      fill: rgba(0, 0, 0, 0.2)
    }
  }

  &__bottom {
    position: relative;
    border-radius: 0.8rem;
    background: $color-red;
  }

  &__btn {
    display: inline-block;
    position: relative;
    z-index: 22;
    align-self: center;
    width: 16rem;
    margin-top: 1rem auto 0.3rem auto;
    padding: 0 4rem 0 4rem;

    color: $color-white;
    border-radius: 1.6rem;
    @include text;
    background: rgba($color-white, 0.5);
    text-align: center;
    line-height: 1.45;

    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }
}


.swiper-pagination-bullet {
  width: 0.4rem;
  height: 0.4rem;
  background: $color-white;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 0.6rem;
  height: 0.6rem;
}

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
  position: relative;
  background: none;
  fill: rgba(black, .2);
  transition: fill $ease-main, opacity $ease-main;
  width: 1.2rem;
  height: 1.2rem;
  left: auto;
  right: auto;

  &:hover,
  &:active {
    fill: $color-blue-product;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: inherit;
  }
}

.swiper-wrapper {
  box-sizing: border-box;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 1.4rem;
}

.swiper-group-ten,
.swiper-group-eight,
.swiper-group-twelve,
.swiper-group-eleven,
.swiper-group-thirteen {

  &>.swiper-wrapper>.swiper-slide {
    transition: opacity $ease-main;
    opacity: 0;
  }

  &>.swiper-wrapper>.swiper-slide-visible {
    opacity: 1;
  }
}
