/* Образец подключения шрифта
@font-face {
  font-family: 'MyWebFont';
  font-style: normal;
  font-weight: 400;
  src: url('webfont.eot');
  src:
    url('webfont.eot?#iefix') format('embedded-opentype'),
    url('webfont.woff2') format('woff2'),
    url('webfont.woff') format('woff'),
    url('webfont.otf')  format('opentype'),
    url('webfont.ttf')  format('truetype'),
    url('webfont.svg#webfont') format('svg');
}
*/

/* Соответствие письменного названия жирности числовому значению
100 — Thin
200 — Extra Light, Ultra Light
300 — Light
400 — Normal, Book, Regular (стандартный)
500 — Medium
600 — Semi Bold, Demi Bold
700 — Bold
800 — Extra Bold, Ultra Bold
900 — Black, Heavy
*/


@font-face {
  font-family: 'TTSmalls';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/TTSmallsRegular.eot');
  src:
    url('../fonts/TTSmallsRegular.woff2') format('woff2'),
    url('../fonts/TTSmallsRegular.woff') format('woff'),
    url('../fonts/TTSmallsRegular.ttf')  format('truetype'),
    url('../fonts/TTSmallsRegular.svg#TTSmallsRegular') format('svg');
}

@font-face {
  font-family: 'TTSmalls';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/TTSmallsMedium.eot');
  src:
    url('../fonts/TTSmallsMedium.woff2') format('woff2'),
    url('../fonts/TTSmallsMedium.woff') format('woff'),
    url('../fonts/TTSmallsMedium.ttf')  format('truetype'),
    url('../fonts/TTSmallsMedium.svg#TTSmallsMedium') format('svg');
}


@font-face {
  font-family: 'TTRounds';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/TTRoundsRegular.eot');
  src:
    url('../fonts/TTRoundsRegular.woff2') format('woff2'),
    url('../fonts/TTRoundsRegular.woff') format('woff'),
    url('../fonts/TTRoundsRegular.ttf')  format('truetype'),
    url('../fonts/TTRoundsRegular.svg#TTRoundsRegular') format('svg');
}

@font-face {
  font-family: 'TTRoundsBold';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/TTRoundsBold.eot');
  src:
    url('../fonts/TTRoundsBold.woff2') format('woff2'),
    url('../fonts/TTRoundsBold.woff') format('woff'),
    url('../fonts/TTRoundsBold.ttf')  format('truetype'),
    url('../fonts/TTRoundsBold.svg#TTRoundsBold') format('svg');
}


@font-face {
  font-family: 'NeoSansPro';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/NeoSansProBold.eot');
  src:
    url('../fonts/NeoSansProBold.woff2') format('woff2'),
    url('../fonts/NeoSansProBold.woff') format('woff'),
    url('../fonts/NeoSansProBold.ttf')  format('truetype'),
    url('../fonts/NeoSansProBold.svg#NeoSansProBold') format('svg');
}

@font-face {
  font-family: 'NeoSansPro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NeoSansProRegular.eot');
  src:
    url('../fonts/NeoSansProRegular.woff2') format('woff2'),
    url('../fonts/NeoSansProRegular.woff') format('woff'),
    url('../fonts/NeoSansProRegular.ttf')  format('truetype'),
    url('../fonts/NeoSansProRegular.svg#NeoSansProRegular') format('svg');
}

