.catalog {
  display: none;
  position: fixed;
  height: 78vh;
  top: 3.6rem;
  left: 1.6rem;
  right: 1.6rem;
  border-radius: 0.8rem;
  background: white;
  transition: opacity $ease-main, transform $ease-main;
  opacity: 0;
  transform: translate3d(0, 1.5rem, 0);
  box-sizing: border-box;
  padding-top: 5.3rem;

  .js-scrollbar {
    height: calc(100% - 5.3rem);
    width: 100%;
  }

  &.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  &__container {
    display: flex;
    padding: 0 0 4rem 4rem;
    margin-top: 4.4rem;

    @include screen('xl') {
      padding-left: 5.6rem;
    }
  }

  &__brands, &__notebooks {
    width: 100%;
  }

  &__header {
    height: 6rem;
    margin-bottom: 3.8rem;
    padding-right: 4rem;
    width: 100%;
  }

  &__col-left {
    width: 19.7rem;
    padding-right: 3.5rem;
    position: relative;
    transition: opacity $ease-main;
    opacity: 0;

    .catalog.is-active & {
      opacity: 1;
      transition-delay: .2s;
    }

    @include screen('sm') {
      // width: 20%;
    }

    @include screen('md') {
      // width: 17%;
    }
  }

  &__col-right {
    flex: 1;
    display: flex;
    flex-flow: column wrap;
    overflow: hidden;
    transition: transform $ease-main, opacity $ease-main;
    transform: translate3d(0, 1.5rem, 0);
    opacity: 0;

    .catalog.is-active & {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition-delay: .25s;
    }

    &>* {
      order: 0;
    }
  }

  &__wrapper {
    padding-top: .5rem;
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: 3.1rem;
    }
  }

  &__title {
    margin-bottom: 0.9rem;

    @include heading-6;
    color: $color-grey-header-first;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.43rem;
    }
  }

  &__link {
    @include heading-5;
    color: $color-blue-product;
    transition: color $ease-main;

    &:hover {
      color: $color-red;
    }
  }

  &__brands, &__notebooks {
    .goods__catalog {
      justify-content: space-between;
    }

    .card-small {
      width: 40rem;
      height: 21.6rem;
      margin-right: 1.6rem;
      flex-shrink: 0;
    }

    .goods__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-left: -2rem;
      margin-right: 3rem;
      margin-top: -.2rem;
    }

    .goods__item {
      width: 33.3333%;
      box-sizing: border-box;
      padding: 1rem 2rem 0;
      margin-right: 0;

      @include screen('sm') {
        width: 25%;
      }

      @include screen('lg') {
        width: 20%;
      }
    }

    .goods__link {
      color: $color-black;
      @include heading-2;
    }

    .goods__brands {
      display: flex;
      position: relative;
      padding-bottom: 5rem;
      padding-right: 4rem;

      &::after {
        @include pseudo;

        width: 98%;
        height: 1px;
        bottom: 0;

        background: #e6e6e6;
      }
    }
  }

  &__notebooks {
    .goods__brands {
      padding-right: 11.6rem;
    }

    .goods__item {
      width: 50%;

      @include screen('sm') {
        width: 33.333%;
      }

      @include screen('lg') {
        width: 25%;
      }
    }

    .goods__link {
      @include heading-4;

      color: $color-black;
    }
  }

  .goods {
    &:not(:last-child) {
      margin-bottom: 5rem;
    }
  }

  &__active {
    order: -1;
  }

  .goods__header {
    padding: 0 4rem 0 0;
    margin: 0;
    align-items: center;

    @include screen('md') {
      margin-bottom: 2.5rem;
    }
  }

  .goods__title {
    width: 50rem;
    flex-grow: 1;
    padding-right: 40px;
  }

  .goods__controlers {
    flex-shrink: 0;
    margin-top: .9rem;
  }

  .goods__content {
    padding-bottom: 2rem;
  }
}

.scroll-element.scroll-y {
  z-index: 3333;
}
