@mixin list {
  @include text;

  ul, ol {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  li {
    padding-left: 20px;
    position: relative;
  }
}

@mixin ullist {

  li::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '•';
  }
}

@mixin ollist {
  counter-reset: list1;

  li {

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      counter-increment: list1;
      content: counter(list1) '.';
    }
  }
}
