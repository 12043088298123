// Здесь используем комментарии только через "//", т.к. они нам не нужны в итоговом css-файле

// ##########
// Базовые настройки сетки сайта
// ##########
$container-width: 1024px; // Минимальная ширина контейнера контента
$container-padding: 16px; // Отступы слева и справа у контейнера
$grid-container-width: 100%; // Ширина контейнера сетки
$grid-cols: 12; // Количество колонок в сетке
$grid-gap: 20px; // Расстояние между колонками
$grid-gap-vertical: 20px; // Расстояние между строками в сетке
$grid-col-width: $grid-container-width/$grid-cols; // Ширина одной колонки
$breakpoints: (
  'xl': 1920px,
  'lg': 1888px,
  'md': 1440px,
  'sm': 1240px,
  'xs': 1024px
);

// ##########
// Цвета
// ##########
$color-white: #ffffff; // Основной белый цвет
$color-black: #000000; // Основной чёрный цвет
$color-red: #FF0069; // Основной красный цвет
$color-grey-bg: #eeeeee; // Серый цвет фона
$color-grey-product: #cccccc;
$color-blue-product: #6699FF;
$color-dark-blue-product: #162158;
$color-brown-product: #E2D7CE;
$color-grey-header-first: rgba(0, 0, 0, 0.5); // Серый цвет первого списка в хэдере
$color-grey-product-second: rgba(0, 0, 0, 0.4); // Серый цвет первого списка в хэдере
$color-collection-blue: #162158;
$color-card-brown: #C0BABA;
$color-grey-text: #666666; // Второстепенный серый цвет фона
$color-basic: black; // Основной цвет текста (применяется к body)
$color-placeholder: #000; // Основной цвет для placeholder
$color-input: black; // Основной цвет input, select и textarea
$color-main: #2252d8; // Основной фирменный цвет
$color-border: #ccc; // Основной цвет границ таблиц, секций

$color-promo-first: #8ABE56;
$color-promo-second: #E7D1F5;


// ##########
// Transitions
// ##########
$ease-main: .3s ease;

// ##########
// Шрифты
// ##########
$font-main: 'TTSmalls', sans-serif; // Основной шрифт сайта
$font-second: 'NeoSansPro', sans-serif; // Дополнительный шрифт
