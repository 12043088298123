.navigation {
  height: 5.6rem;
  transition: background-color $ease-main, transform $ease-main;
  display: flex;
  align-items: center;

  .js-menu-hidden {
    transition: opacity $ease-main;

    &.is-hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &__container {
    display: flex;
    transition: background $ease-main;
    align-items: center;

    .header.is-fixed & {
      z-index: 1;
    }
  }

  &__icon {
    transition: fill $ease-main, stroke $ease-main, opacity $ease-main;
    width: 2.8rem;
    height: 2.8rem;

    &:hover {
      opacity: 0.5;
    }
  }

  &__left {
    width: 45.5%;

    @include screen('sm') {
      width: 55%;
    }

    @include screen('md') {
      width: 52.5%;
    }

    @include screen('lg') {
      width: 37%;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
  }

  &__arrow {
    width: 11px;
    height: 6px;
    fill: inherit;
    vertical-align: middle;
    display: inline-block;
    margin: 0 0 0.1rem 0.3rem;
  }

  &__logo {
    display: block;
    font-size: 0;
    margin-right: 3rem;
    transition: opacity $ease-main;
    position: relative;

    &:hover {
      opacity: .5;
    }
  }

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 2.5rem;
    }

    &_hidden {
      display: none;

      @include screen('sm') {
        display: block;
      }
    }

    &_ellipsis {
      margin: 0 0 0 -1rem;
      font-size: 1.8rem;

      @include screen('sm') {
        display: none;
      }
    }

    &_key {
      margin: 0.2rem 3.1rem 0 0.8rem;
    }
  }

  &__link {
    @include heading-4;

    color: $color-black;
    fill: $color-black;

    &:hover {
      opacity: .5;
    }
  }

  &__caption {
    display: block;
    margin: 1px 0 0 1px;
    transition: opacity $ease-main;

    @include heading-8;
    color: rgba(57, 49, 88, 0.5);
  }

  &__icon-item {
    margin-left: 4rem;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;

    @include screen('md') {
      margin-left: 3.5rem;
    }
  }

  &__form {
    position: relative;
    flex-grow: 1;
    margin-right: -1rem;
    margin-left: auto;
    max-width: 32rem;

    @include screen('sm') {
      max-width: 38rem;
    }

    @include screen('md') {
      max-width: 45.6rem;
    }

    @include screen('lg') {
      max-width: 85rem;
    }

    input {
      background-color: rgba(black, .05);
      padding-right: 6rem;
      transition: background-color $ease-main;
    }
  }

  &__group {
    position: relative;

    &.is-active {
      z-index: 3;
    }
  }

  &__btn {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    bottom: 0;
    right: 2.2rem;
    margin: auto;

    svg {
      width: 100%;
      height: 100%;
      fill: transparent;
      stroke: rgba(black, .8);
      transition: stroke $ease-main, fill $ease-main;
    }

    &:hover {
      svg {
        stroke: black;
      }
    }
  }

  &__input {
    padding-right: 5rem;
    border-radius: 0.6rem;
  }

  &__user {
    svg {
      stroke: $color-black;
      fill: none;
    }

    &_login {
      display: none;
      width: 28px;
      height: 28px;

      border: 2px solid $color-red;
      border-radius: 50%;
      color: $color-red;
      font-size: 1.4rem;
      font-family: 'TTRounds', sans-serif;
      font-weight: bold;
    }
  }

  &__basket {
    position: relative;
  }

  &__burger {
    display: flex;
    position: relative;
    align-items: center;
    width: 2.6rem;
    height: 2.4rem;
    transition: opacity $ease-main;

    &:hover {
      opacity: 0.5;
    }

    span, &::before, &::after {
      background-color: inherit;
      border-radius: 10rem;
      background: $color-black;
      height: .2rem;
      width: 100%;
      transition: background-color $ease-main, transform $ease-main, opacity $ease-main;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
    }

    &::before {
      transform: translate3d(0, -1.2rem, 0);
    }

    &::after {
      transform: translate3d(0, 1.2rem, 0);
    }

    &.is-active {
      span {
        opacity: 0;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .header.is-fixed:not(.is-normal) & {
    transform: translate3d(0, -36px, 0);
    background-color: $color-black;

    .navigation__caption {
      opacity: 0;
    }

    .navigation__item_catalog:after {
      fill: white;
    }

    .navigation__link {
      color: $color-white;
      fill: $color-white;
    }

    .navigation__burger {
      &::before, &::after, span {
        background: $color-white;
      }
    }

    .navigation__group:not(.is-active) {
      .js-site-search {
        background: rgba(255, 255, 255, 0.3);
      }

      .navigation__btn {
        svg {
          stroke: $color-white;
          fill: transparent;
        }
      }
    }

    .navigation__input {
      color: $color-white;
    }

    .navigation__user {
      svg {
        fill: transparent;
        stroke: $color-white;
      }
    }

    .navgitaion__basket {
      svg {
        fill: transparent;
        stroke: $color-white;
      }
    }

    .navigation__icon {
      fill: white;
    }
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    top: -0.5rem;
    right: -1.3rem;

    background: $color-red;
    border-radius: 50%;
    color: $color-white;
    font-size: 1.1rem;
    line-height: normal;

  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
