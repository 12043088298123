.promo {
  position: relative;
  width: 100%;

  overflow: hidden;

  border-radius: 0.8rem;

  @include screen('sm') {
    width: 80.8%;
  }

  @include screen('md') {
    width: 67.2%;
  }

  @include screen('lg') {
    width: 117.6rem;
  }

  &__item {
    display: block;
    position: relative;
    width: 5.6rem;
    height: 4.8rem;
    margin-bottom: 1.5rem;

    background: $color-white;
    border-radius: 0.8rem;
    opacity: 0.6;

    overflow: hidden;

    &:first-child {
      background: #E7D1F5;
    }

    &:nth-child(3),
    &:nth-child(7) {
      img {
        width: 100%;
        height: 100%;
      }
    }

    & img {
      position: absolute;

      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__block {
    width: 100%;
    height: 100%;
    padding: 3rem 1.5rem 3rem 1.5rem;

    &_first {
      display: block;
      background: #abd87e;
    }

    &_second {
      display: none;
      background: $color-promo-second;
    }

    &_third {
      display: none;
      background: green;
    }

    &_fourth {
      display: none;
      background: skyblue;
    }

    &_fifth {
      display: none;
      background: pink;
    }

    &_six {
      display: none;
      background: yellow;
    }
  }

  &__circle {
    position: absolute;
    width: 80.8rem;
    height: 80.8rem;

    z-index: 2;

    border-radius: 50%;
    background: $color-white;

    &_top-left {
      top: -51.8rem;
      left: -21.6rem;
    }

    &_bottom-left {
      bottom: -45rem;
      left: -19.2rem;
      z-index: 1;
    }
  }

  &__image {
    // margin: 0.2rem 0.8rem 0 0.9rem;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5.3rem;
    left: 12.2rem;
    right: 0;
    bottom: 0;
    margin: auto;

    &_second {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
    }

    &_third {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
    }

    &_fourth {
      position: relative;
      top: auto;
      left: auto;
      width: auto;
    }
  }

  &__info {
    display: flex;
    position: relative;
    width: 46%;
    z-index: 3;

    @include screen('sm') {
      width: 47%;
    }

    @include screen('md') {
      width: 48%;
    }

    @include screen('lg') {
      width: 39%;
    }

    &_second {
      width: 41%;
      margin: 32.7rem 0 0 5.6rem;

      @include screen('lg') {
        margin-left: 1.6rem;
      }
    }
  }

  &__picture {
    @include fill-img;
  }

  &__details {
    position: relative;
    z-index: 3;
    margin-top: 1.1rem;
  }

  &__title {
    @include heading-3;
  }

  &__text {
    width: 80%;
    margin-top: 1.4rem;

    @include heading-4;
    line-height: 1.2;
    color: $color-grey-text;
    opacity: 0.8;
  }

  &__company {
    position: absolute;

    bottom: 3.6rem;
    left: 3.2rem;

    &_second {
      top: 4rem;
    }
  }

  &__device {
    position: absolute;

    top: 4rem;
    left: 30.6%;

    @include screen('sm') {
      left: 29.6%;
    }

    @include screen('md') {
      left: 27.6%;
    }

    @include screen('lg') {
      left: 33.9%;
    }

    & img:first-child {
      position: absolute;

      top: 28.4rem;
      left: 18.7rem;
    }

    & img:last-child {
      position: absolute;
      bottom: 2.2rem;
      left: 8.8%;
    }

    &_man {
      left: 46%;
      top: 0;

      & img:first-child {
        top: 6.5%;
        left: 49%;
      }

      & img:last-child {
        bottom: 54%;
        left: 5.8%;
      }
    }
  }

  &__inner {
    position: absolute;
    width: 5.6rem;
    height: 20rem;
    right: 1rem;
    top: 20rem;
    z-index: 22;
  }

  &__container {
    height: 100%;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.2rem;
    height: 43.5%;
    position: absolute;
    right: 3.9rem;
    top: 3.7rem;
    z-index: 22;

    .promo__prev,
    .promo__next {
      width: 2.5rem;
      height: 2.5rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: $color-white !important;
      }
    }
  }

  &__prev {
    margin-right: 10px;

    transform: rotate(90deg);
  }

  &__next {
    transform: rotate(90deg);
  }

  .swiper-slide-active {
    opacity: 1;
  }
}
