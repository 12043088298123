.input {
  padding: 0 4rem 0 1.7rem;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1;
  border-radius: 0.8rem;
  min-height: 0;
  background-color: #f2f2f2;
}

.inputbox {
  display: block;
}

.textarea {
  @extend .input;

  padding-top: 13px;
  padding-bottom: 13px;
  min-height: 150px;
  resize: vertical;
}

@import '../../../node_modules/choices.js/assets/styles/scss/choices.scss';

.select {
  appearance: none;

  @extend .input;
}


.choices {
  margin-bottom: 1.2rem;

  &__placeholder {
    opacity: 1;
  }

  &.is-open {
    .choices__placeholder {
      opacity: 0.5;
    }
  }

  &__inner,
  &[data-type*='select-one'] &__inner {
    @extend .select;

    border: none;
    transition: border-color $ease-main;
  }

  &[data-type*='select-one'] {

    .choices__inner {
      padding-bottom: 0;
    }

    &::after {
      border-width: 6px 6px 0;
      top: 0;
      bottom: -1px;
      right: 16px;
      margin: auto;
      transform: rotate(0) translate3d(0, 0, 0);
      transition: transform $ease-main;
      border-color: #333 transparent transparent;
    }

    &.is-open::after {
      transform: rotate(180deg) translate3d(0, 0, 0);
      border-color: #333 transparent transparent;
      margin: auto;
    }
  }

  &__list {
    &--single {
      display: table;
      box-sizing: border-box;
      height: 100%;
      padding: 0;
      padding-right: 20px;

      .choices__item {
        display: table-cell;
        vertical-align: middle;
      }
    }

    &--dropdown {

      .choices__item {
        padding: 0.5rem 1.7rem;

        &--selectable {
          transition: opacity $ease-main;

          &.is-highlighted {
            opacity: .5;
            background-color: white;
          }
        }
      }
    }
  }

  &.is-open & {

    &__inner {
      border-color: transparent;
    }

    &__list {
      &--dropdown {
        margin-top: -5px;
        padding-bottom: 10px;
        border: none;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
        background: #f2f2f2;
      }
    }
  }
}
