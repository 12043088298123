$ui-kit-color-gray: #ccc;
$ui-kit-color-text: #222;
$ui-kit-color-darkbg: #111;

.ui-kit {
  display: block;
  color: $ui-kit-color-text;

  &__navigation {
    position: fixed;
    right: 100%;
    top: 0;
    height: 100vh;
    box-shadow: 0 0 30px rgba($color-basic, .15);
    z-index: 10;
    padding: 20px;
    background-color: white;
    transform: translate3d(15%, 0, 0);
    width: 240px;
    opacity: .5;
    transition: opacity $ease-main, transform $ease-main;
    overflow: auto;
    box-sizing: border-box;

    &-burger {
      position: absolute;
      width: 18px;
      height: 14px;
      box-sizing: border-box;
      border-top: 2px solid $color-basic;
      border-bottom: 2px solid $color-basic;
      top: 9px;
      right: 9px;
      opacity: 1;
      transition: opacity $ease-main;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background-color: $color-basic;
      }
    }

    &:hover {
      transform: translate3d(100%, 0, 0);
      opacity: 1;

      .ui-kit__navigation-burger { opacity: 0; }
    }

    li {
      @include text;

      a { @include link; }

      &:not(:last-child) { margin-bottom: 5px; }
    }
  }

  &__head {
    padding-top: 40px;
    padding-bottom: 20px;
  }

  &__name {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.4;
  }

  &__description {
    font-size: 13px;
    line-height: 1.4;

    &:not(:first-child) { margin-top: 30px; }

    &:not(:last-child) { margin-bottom: 10px; }
  }

  &__special {
    padding: $grid-gap;
    border: 1px solid $ui-kit-color-gray;
  }

  &__logo {
    max-height: 80px;
    font-size: 20px;
    text-align: left;

    svg {
      height: 100%;
    }

    & + .ui-kit__name { margin-top: 20px; }
  }

  &__elements-list {
    margin-top: -$grid-gap;
    font-size: 0;

    > * {
      margin-right: $grid-gap;
      margin-top: $grid-gap;
    }
  }

  &__section {
    padding-top: 50px;
    padding-bottom: 50px;

    &_dark {
      background-color: $ui-kit-color-darkbg;
      color: white;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $ui-kit-color-gray;
    }

    &-head {
      margin-bottom: 30px;
      text-align: left;

      h1, h2, h3, h4, h5, h6 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.4;

        &:not(:first-child) { margin-top: 20px; }
        &:not(:last-child) { margin-bottom: 20px; }
      }

      p {
        font-size: 16px;
        line-height: 1.5;

        &:not(:last-child) { margin-bottom: 10px; }
      }
    }

    //
    // Ниже настройки для конкретных секций
    //

    &_grid .grid__col::after {
      content: '';
      display: block;
      height: 40px;
      background-color: $ui-kit-color-gray;
    }
  }

  &__ui-popups {
    margin-top: 30px;

    .popup {
      display: block !important;
      border: 1px solid $color-border;

      &:not(:first-child) { margin-top: 30px; }
    }
  }

  &__scrolldemo {
    border: 1px solid $color-border;
    max-height: 400px;

    &-block { height: 1000px; }
  }

  &__color {
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    box-shadow: inset 0 0 0 1px rgba(black, .1);

    &_bg_dark { color: white; }

    &_gradient {
      &_main { @include gradient-main; }
    }
  }

  &__custom-text {
    &_style-1 { @include text; }

    &:not(:last-child) { margin-bottom: 10px; }
  }
}
