.card {
  display: none;
  flex-direction: column;
  position: relative;
  width: 33.6rem !important; // Чтобы перебить инлайновые стили
  height: 44.8rem;
  margin-right: 1.6rem;
  padding-block-end: 1.6rem;
  flex-shrink: 0;
  transition: all $ease-main;

  border-radius: 0.8rem;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &_double {
    &:hover {
      box-shadow: none;
      transform: scale(1);

      &>.card__more {
        display: none;
      }
    }
  }


  &__title {
    width: 65%;
    margin: 1.7rem 0 0 2.4rem;

    @include heading-3;
    color: $color-black;
  }

  &__details {
    display: flex;
  }

  &__text {
    @include heading-6;
    color: $color-black;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;

    top: 2.4rem;
    right: 2.4rem;

    background: $color-dark-blue-product;
    border-radius: 50%;
  }

  &__day,
  &__month {
    font-size: 1rem;
    font-family: $font-second;
    text-align: center;
    color: $color-white;
  }

  &__day {
    margin-top: 0.7rem;
  }

  &__month {
    margin-top: -0.3rem;
  }

  &__more {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 104%;
    height: 104%;
    padding: 2.4rem 3.2rem 3.2rem 2.9rem;
    left: -2%;
    top: -2%;
    z-index: 22;
    background: $color-white;
    border-radius: 0.8rem;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
    transition: opacity .2s ease;
    color: $color-black;
    opacity: 0;

    .card:hover & {
      opacity: 1;
    }

    .card {
      &__subtitle, &__descr {
        transition: transform $ease-main, opacity $ease-main;
        transform: translate3d(0, 8px, 0);
        opacity: 0;

        .card:hover & {
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition-delay: .1s;
        }
      }

      &__descr {
        .card:hover & {
          transition-delay: .15s;
        }
      }
    }
  }

  &__subtitle {
    margin: 0 0 1.2rem 0;
    width: 100%;

    @include heading-3;
    letter-spacing: -0.5px;
  }

  &__descr {
    font-size: 1.6rem;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.5);
  }

  &__caption {
    width: 50%;
    margin-top: auto;

    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.5);
  }

  .product__item {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 2.4rem;
    top: 2.4rem;

    &_new {
      font-size: 0.8rem;

      background: $color-blue-product;
    }
  }

  &_image {
    overflow: hidden;
    border-radius: 0.8rem;

    img {
      @include fill-img;
      border-radius: 0.8rem;
    }
  }
}


.card-small {
  display: flex;
  padding: 1.6rem 0 0.8rem 2.4rem;
  position: relative;
  width: 40rem;
  height: 21.6rem;
  overflow: hidden;

  border-radius: 0.8rem;

  transition: all $ease-main;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0.8rem 2.4rem 0 rgba(0, 0, 0, 0.24);
    z-index: 2;
  }

  &_galaxy {
    background: url('../images/pink-square.svg') no-repeat right bottom, #C597C4;

    .card-small__title {
      color: $color-white;
      margin-right: 2rem;
    }

    .card-small__text {
      width: 50%;
      margin-top: 5.5rem;

      color: $color-white;
    }

    .card-small__image {
      position: relative;
      margin-top: -0.4rem;
      right: 2.3rem;
    }
  }

  &_nvidia {
    padding-top: 3.8rem;

    background: url('../images/green-square-small.svg') no-repeat right bottom, $color-black;

    .card-small__details {
      margin-top: -2.1rem;
    }

    .card-small__title {
      color: $color-white;
    }

    .card-small__text {
      width: 67%;
      margin-top: 5.5rem;

      color: $color-grey-text;
    }

    .card-small__image {
      position: relative;
      margin-top: -0.4rem;

      top: -1.1rem;
      right: 0.7rem;
    }
  }

  &_apple {
    position: relative;
    background: #f6f6f6;

    .card-small__image {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        right: 12rem;
        bottom: -2.4rem;
      }
    }

    .card-small__details {
      height: 100%;
    }

    .card-small__title {
      position: absolute;
      top: 4rem;
      color: $color-black;
    }

    & span {
      position: absolute;
      width: 2.22rem;
      height: 2.4rem;
      bottom: 2.4rem;
      left: 2.4rem;

      svg {
        width: 100%;
        height: 100%;

        fill: #C4C4C4;
      }
    }
  }

  &_mono {
    position: relative;
    background: #f6f6f6;
    color: $color-black;

    .card-small__title {
      margin-top: 15.1rem;
    }

    .card-small__image {
      position: absolute;
      right: 12.2%;
      bottom: 19px;
    }
  }

  &__title {
    @include heading-3;
  }

  &__text {
    @include heading-6;
    color: $color-grey-text;
  }

  &__preview {
    @include fill-img;
  }
}


@keyframes scale {
  0% {
    opacity: 0;
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes scaleOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
