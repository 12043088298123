@charset "UTF-8";
@import url(../../node_modules/normalize.css/normalize.css);
/*
Миксин для элементов, которые должны сохранять определенное соотношение сторон (напр., блок с видео)
Пример использования:
div {
  @include ratio(16,9);
} */
/*
Миксин для базовых параметров псевдоэлементов.
Пример использования:

div::after {
  @include pseudo;
  top: 10px;
  left: 10px;
  width: 150px;
}
*/
/*
Миксин для треугольников, сделанных через :before и :after
Пример:

div::after {
  @include triangle(black, top, 10px);
}
*/
/*
Миксин для медиа-запросов
Пример использования:
div {
  @include screen('phone') {
    // Правила для разрешений выше 'phone'
  }

  @include screen('phone', max) {
    // Правила для разрешений ниже 'phone'
  }
}
*/
/*
Быстренько подрубаем аппаратное ускорение для анимирующихся элементов.
Пример использования:
div {
  @include performance;
}
*/
/*
Миксин для обрезания длинной строки троеточием.
Пример использования:
p {
  @include ellipsis(100%);
}
*/
/*
Миксин для быстрого добавления clearfix.
Пример использования:
.parent {
  @include clearfix;
}
*/
/*
Миксин для того, чтоб скрыть элемент, но при этом он все равно рендерился
и был доступен в DOM.
Пример использования:
div {
  @include visual-hide;
}
*/
/*
Миксин для быстрого выравнивания блока по центру
Пример использования:
div {
  @include center-block;
}
*/
/*
Миксин для быстрого выравнивания картинки по центру
Пример использования:
div {
  @include center-image;
}
*/
/*
Миксин для изображений.
Нужен, чтобы картинка заполняла другой блок подобно background-size: cover
Пример использования:
div {
  @include fill-img;
}
*/
/* Образец подключения шрифта
@font-face {
  font-family: 'MyWebFont';
  font-style: normal;
  font-weight: 400;
  src: url('webfont.eot');
  src:
    url('webfont.eot?#iefix') format('embedded-opentype'),
    url('webfont.woff2') format('woff2'),
    url('webfont.woff') format('woff'),
    url('webfont.otf')  format('opentype'),
    url('webfont.ttf')  format('truetype'),
    url('webfont.svg#webfont') format('svg');
}
*/
/* Соответствие письменного названия жирности числовому значению
100 — Thin
200 — Extra Light, Ultra Light
300 — Light
400 — Normal, Book, Regular (стандартный)
500 — Medium
600 — Semi Bold, Demi Bold
700 — Bold
800 — Extra Bold, Ultra Bold
900 — Black, Heavy
*/
@import url(../../node_modules/swiper/dist/css/swiper.css);
@import url(../../node_modules/swiper/dist/css/swiper.css);
@font-face {
  font-family: 'TTSmalls';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/TTSmallsRegular.eot");
  src: url("../fonts/TTSmallsRegular.woff2") format("woff2"), url("../fonts/TTSmallsRegular.woff") format("woff"), url("../fonts/TTSmallsRegular.ttf") format("truetype"), url("../fonts/TTSmallsRegular.svg#TTSmallsRegular") format("svg"); }

@font-face {
  font-family: 'TTSmalls';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/TTSmallsMedium.eot");
  src: url("../fonts/TTSmallsMedium.woff2") format("woff2"), url("../fonts/TTSmallsMedium.woff") format("woff"), url("../fonts/TTSmallsMedium.ttf") format("truetype"), url("../fonts/TTSmallsMedium.svg#TTSmallsMedium") format("svg"); }

@font-face {
  font-family: 'TTRounds';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/TTRoundsRegular.eot");
  src: url("../fonts/TTRoundsRegular.woff2") format("woff2"), url("../fonts/TTRoundsRegular.woff") format("woff"), url("../fonts/TTRoundsRegular.ttf") format("truetype"), url("../fonts/TTRoundsRegular.svg#TTRoundsRegular") format("svg"); }

@font-face {
  font-family: 'TTRoundsBold';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/TTRoundsBold.eot");
  src: url("../fonts/TTRoundsBold.woff2") format("woff2"), url("../fonts/TTRoundsBold.woff") format("woff"), url("../fonts/TTRoundsBold.ttf") format("truetype"), url("../fonts/TTRoundsBold.svg#TTRoundsBold") format("svg"); }

@font-face {
  font-family: 'NeoSansPro';
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/NeoSansProBold.eot");
  src: url("../fonts/NeoSansProBold.woff2") format("woff2"), url("../fonts/NeoSansProBold.woff") format("woff"), url("../fonts/NeoSansProBold.ttf") format("truetype"), url("../fonts/NeoSansProBold.svg#NeoSansProBold") format("svg"); }

@font-face {
  font-family: 'NeoSansPro';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NeoSansProRegular.eot");
  src: url("../fonts/NeoSansProRegular.woff2") format("woff2"), url("../fonts/NeoSansProRegular.woff") format("woff"), url("../fonts/NeoSansProRegular.ttf") format("truetype"), url("../fonts/NeoSansProRegular.svg#NeoSansProRegular") format("svg"); }

/* ##########
Убираем outline
########## */
button::-moz-focus-inner {
  border: 0; }

*:focus {
  outline: none; }

/* ##########
Цвет выделения текста
########## */
::selection {
  background: #2252d8;
  color: white; }

/* ##########
Стандартный цвет плейсхолдера
########## */
input::placeholder, textarea::placeholder {
  color: #000;
  font-size: 1.6rem; }

/* ##########
Настройки для автозаполнения Chrome
########## */
input:-webkit-autofill {
  -webkit-text-fill-color: black !important; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  transition: background-color 50000000000s ease-in-out 0s !important; }

/* ##########
Основные стили
########## */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
  min-height: 100%; }

body {
  box-sizing: border-box;
  font-family: "TTSmalls", sans-serif;
  font-size: 16px;
  color: black;
  background: #eeeeee; }

.visually-hidden:not(:focus):not(:active) {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden; }

img {
  box-sizing: content-box; }

/* ##########
Сброс стандартных отступов для типографики, списков и таблиц
########## */
h1, h2, h3, h4, h5, h6, p, span, ul, ol, li, tr, td, th, table {
  margin: 0;
  padding: 0; }

ul, ol {
  list-style: none; }

span {
  font-style: inherit;
  font-family: inherit;
  font-weight: inherit; }

a {
  text-decoration: none;
  cursor: pointer; }

/* ##########
Сброс стилей для активных элементов, добавляем стандарнтый transition
########## */
a, input, button, select, textarea {
  transition: color 0.3s ease, border-color 0.3s ease; }

button {
  cursor: pointer; }

select, input, textarea, button {
  color: black;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  font-family: "TTSmalls", sans-serif; }

.button {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  box-sizing: border-box;
  color: black;
  background-color: transparent;
  transition: opacity 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.65;
  font-weight: 400; }
  .button:disabled, .button.is-disabled {
    opacity: .5;
    cursor: not-allowed; }
  .button:hover, .button.is-active {
    opacity: .5; }

.button {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  vertical-align: middle;
  box-sizing: border-box;
  color: black;
  background-color: transparent;
  transition: opacity 0.3s ease;
  cursor: pointer;
  font-size: 14px;
  line-height: 1.65;
  font-weight: 400; }
  .button:disabled, .button.is-disabled {
    opacity: .5;
    cursor: not-allowed; }
  .button:hover, .button.is-active {
    opacity: .5; }

.card {
  display: none;
  flex-direction: column;
  position: relative;
  width: 33.6rem !important;
  height: 44.8rem;
  margin-right: 1.6rem;
  padding-block-end: 1.6rem;
  flex-shrink: 0;
  transition: all 0.3s ease;
  border-radius: 0.8rem; }
  .card:last-child {
    margin-right: 0; }
  .card:hover {
    cursor: pointer; }
  .card_double:hover {
    box-shadow: none;
    transform: scale(1); }
    .card_double:hover > .card__more {
      display: none; }
  .card__title {
    width: 65%;
    margin: 1.7rem 0 0 2.4rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    color: #000000; }
  .card__details {
    display: flex; }
  .card__text {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    color: #000000; }
  .card__wrapper {
    display: flex;
    justify-content: space-between; }
  .card__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    top: 2.4rem;
    right: 2.4rem;
    background: #162158;
    border-radius: 50%; }
  .card__day, .card__month {
    font-size: 1rem;
    font-family: "NeoSansPro", sans-serif;
    text-align: center;
    color: #ffffff; }
  .card__day {
    margin-top: 0.7rem; }
  .card__month {
    margin-top: -0.3rem; }
  .card__more {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 104%;
    height: 104%;
    padding: 2.4rem 3.2rem 3.2rem 2.9rem;
    left: -2%;
    top: -2%;
    z-index: 22;
    background: #ffffff;
    border-radius: 0.8rem;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
    transition: opacity .2s ease;
    color: #000000;
    opacity: 0; }
    .card:hover .card__more {
      opacity: 1; }
    .card__more .card__subtitle, .card__more .card__descr {
      transition: transform 0.3s ease, opacity 0.3s ease;
      transform: translate3d(0, 8px, 0);
      opacity: 0; }
      .card:hover .card__more .card__subtitle, .card:hover .card__more .card__descr {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-delay: .1s; }
    .card:hover .card__more .card__descr {
      transition-delay: .15s; }
  .card__subtitle {
    margin: 0 0 1.2rem 0;
    width: 100%;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    letter-spacing: -0.5px; }
  .card__descr {
    font-size: 1.6rem;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.5); }
  .card__caption {
    width: 50%;
    margin-top: auto;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.5); }
  .card .product__item {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 2.4rem;
    top: 2.4rem; }
    .card .product__item_new {
      font-size: 0.8rem;
      background: #6699FF; }
  .card_image {
    overflow: hidden;
    border-radius: 0.8rem; }
    .card_image img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 0;
      min-width: 100%;
      min-height: 100%;
      border-radius: 0.8rem; }

.card-small {
  display: flex;
  padding: 1.6rem 0 0.8rem 2.4rem;
  position: relative;
  width: 40rem;
  height: 21.6rem;
  overflow: hidden;
  border-radius: 0.8rem;
  transition: all 0.3s ease; }
  .card-small:hover {
    cursor: pointer;
    box-shadow: 0 0.8rem 2.4rem 0 rgba(0, 0, 0, 0.24);
    z-index: 2; }
  .card-small_galaxy {
    background: url("../images/pink-square.svg") no-repeat right bottom, #C597C4; }
    .card-small_galaxy .card-small__title {
      color: #ffffff;
      margin-right: 2rem; }
    .card-small_galaxy .card-small__text {
      width: 50%;
      margin-top: 5.5rem;
      color: #ffffff; }
    .card-small_galaxy .card-small__image {
      position: relative;
      margin-top: -0.4rem;
      right: 2.3rem; }
  .card-small_nvidia {
    padding-top: 3.8rem;
    background: url("../images/green-square-small.svg") no-repeat right bottom, #000000; }
    .card-small_nvidia .card-small__details {
      margin-top: -2.1rem; }
    .card-small_nvidia .card-small__title {
      color: #ffffff; }
    .card-small_nvidia .card-small__text {
      width: 67%;
      margin-top: 5.5rem;
      color: #666666; }
    .card-small_nvidia .card-small__image {
      position: relative;
      margin-top: -0.4rem;
      top: -1.1rem;
      right: 0.7rem; }
  .card-small_apple {
    position: relative;
    background: #f6f6f6; }
    .card-small_apple .card-small__image {
      position: absolute;
      width: 100%;
      height: 100%; }
      .card-small_apple .card-small__image img {
        position: absolute;
        right: 12rem;
        bottom: -2.4rem; }
    .card-small_apple .card-small__details {
      height: 100%; }
    .card-small_apple .card-small__title {
      position: absolute;
      top: 4rem;
      color: #000000; }
    .card-small_apple span {
      position: absolute;
      width: 2.22rem;
      height: 2.4rem;
      bottom: 2.4rem;
      left: 2.4rem; }
      .card-small_apple span svg {
        width: 100%;
        height: 100%;
        fill: #C4C4C4; }
  .card-small_mono {
    position: relative;
    background: #f6f6f6;
    color: #000000; }
    .card-small_mono .card-small__title {
      margin-top: 15.1rem; }
    .card-small_mono .card-small__image {
      position: absolute;
      right: 12.2%;
      bottom: 19px; }
  .card-small__title {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .card-small__text {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    color: #666666; }
  .card-small__preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 0;
    min-width: 100%;
    min-height: 100%; }

@keyframes scale {
  0% {
    opacity: 0;
    transform: translateX(-5px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes scaleOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.card {
  display: none;
  flex-direction: column;
  position: relative;
  width: 33.6rem !important;
  height: 44.8rem;
  margin-right: 1.6rem;
  padding-block-end: 1.6rem;
  flex-shrink: 0;
  transition: all 0.3s ease;
  border-radius: 0.8rem; }
  .card:last-child {
    margin-right: 0; }
  .card:hover {
    cursor: pointer; }
  .card_double:hover {
    box-shadow: none;
    transform: scale(1); }
    .card_double:hover > .card__more {
      display: none; }
  .card__title {
    width: 65%;
    margin: 1.7rem 0 0 2.4rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    color: #000000; }
  .card__details {
    display: flex; }
  .card__text {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    color: #000000; }
  .card__wrapper {
    display: flex;
    justify-content: space-between; }
  .card__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 3.2rem;
    height: 3.2rem;
    top: 2.4rem;
    right: 2.4rem;
    background: #162158;
    border-radius: 50%; }
  .card__day, .card__month {
    font-size: 1rem;
    font-family: "NeoSansPro", sans-serif;
    text-align: center;
    color: #ffffff; }
  .card__day {
    margin-top: 0.7rem; }
  .card__month {
    margin-top: -0.3rem; }
  .card__more {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 104%;
    height: 104%;
    padding: 2.4rem 3.2rem 3.2rem 2.9rem;
    left: -2%;
    top: -2%;
    z-index: 22;
    background: #ffffff;
    border-radius: 0.8rem;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24);
    transition: opacity .2s ease;
    color: #000000;
    opacity: 0; }
    .card:hover .card__more {
      opacity: 1; }
    .card__more .card__subtitle, .card__more .card__descr {
      transition: transform 0.3s ease, opacity 0.3s ease;
      transform: translate3d(0, 8px, 0);
      opacity: 0; }
      .card:hover .card__more .card__subtitle, .card:hover .card__more .card__descr {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition-delay: .1s; }
    .card:hover .card__more .card__descr {
      transition-delay: .15s; }
  .card__subtitle {
    margin: 0 0 1.2rem 0;
    width: 100%;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    letter-spacing: -0.5px; }
  .card__descr {
    font-size: 1.6rem;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.5); }
  .card__caption {
    width: 50%;
    margin-top: auto;
    letter-spacing: -0.2px;
    color: rgba(0, 0, 0, 0.5); }
  .card .product__item {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 2.4rem;
    top: 2.4rem; }
    .card .product__item_new {
      font-size: 0.8rem;
      background: #6699FF; }
  .card_image {
    overflow: hidden;
    border-radius: 0.8rem; }
    .card_image img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      object-fit: cover;
      font-family: 'object-fit: cover;';
      width: 0;
      min-width: 100%;
      min-height: 100%;
      border-radius: 0.8rem; }

.card-small {
  display: flex;
  padding: 1.6rem 0 0.8rem 2.4rem;
  position: relative;
  width: 40rem;
  height: 21.6rem;
  overflow: hidden;
  border-radius: 0.8rem;
  transition: all 0.3s ease; }
  .card-small:hover {
    cursor: pointer;
    box-shadow: 0 0.8rem 2.4rem 0 rgba(0, 0, 0, 0.24);
    z-index: 2; }
  .card-small_galaxy {
    background: url("../images/pink-square.svg") no-repeat right bottom, #C597C4; }
    .card-small_galaxy .card-small__title {
      color: #ffffff;
      margin-right: 2rem; }
    .card-small_galaxy .card-small__text {
      width: 50%;
      margin-top: 5.5rem;
      color: #ffffff; }
    .card-small_galaxy .card-small__image {
      position: relative;
      margin-top: -0.4rem;
      right: 2.3rem; }
  .card-small_nvidia {
    padding-top: 3.8rem;
    background: url("../images/green-square-small.svg") no-repeat right bottom, #000000; }
    .card-small_nvidia .card-small__details {
      margin-top: -2.1rem; }
    .card-small_nvidia .card-small__title {
      color: #ffffff; }
    .card-small_nvidia .card-small__text {
      width: 67%;
      margin-top: 5.5rem;
      color: #666666; }
    .card-small_nvidia .card-small__image {
      position: relative;
      margin-top: -0.4rem;
      top: -1.1rem;
      right: 0.7rem; }
  .card-small_apple {
    position: relative;
    background: #f6f6f6; }
    .card-small_apple .card-small__image {
      position: absolute;
      width: 100%;
      height: 100%; }
      .card-small_apple .card-small__image img {
        position: absolute;
        right: 12rem;
        bottom: -2.4rem; }
    .card-small_apple .card-small__details {
      height: 100%; }
    .card-small_apple .card-small__title {
      position: absolute;
      top: 4rem;
      color: #000000; }
    .card-small_apple span {
      position: absolute;
      width: 2.22rem;
      height: 2.4rem;
      bottom: 2.4rem;
      left: 2.4rem; }
      .card-small_apple span svg {
        width: 100%;
        height: 100%;
        fill: #C4C4C4; }
  .card-small_mono {
    position: relative;
    background: #f6f6f6;
    color: #000000; }
    .card-small_mono .card-small__title {
      margin-top: 15.1rem; }
    .card-small_mono .card-small__image {
      position: absolute;
      right: 12.2%;
      bottom: 19px; }
  .card-small__title {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .card-small__text {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    color: #666666; }
  .card-small__preview {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 0;
    min-width: 100%;
    min-height: 100%; }

@keyframes scale {
  0% {
    opacity: 0;
    transform: translateX(-5px); }
  100% {
    opacity: 1;
    transform: translateX(0); } }

@keyframes scaleOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.input, .textarea, .select, .choices__inner,
.choices[data-type*='select-one'] .choices__inner {
  padding: 0 4rem 0 1.7rem;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1;
  border-radius: 0.8rem;
  min-height: 0;
  background-color: #f2f2f2; }

.inputbox {
  display: block; }

.textarea {
  padding-top: 13px;
  padding-bottom: 13px;
  min-height: 150px;
  resize: vertical; }

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px; }
  .choices:focus {
    outline: none; }
  .choices:last-child {
    margin-bottom: 0; }
  .choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
    background-color: #EAEAEA;
    cursor: not-allowed;
    user-select: none; }
  .choices.is-disabled .choices__item {
    cursor: not-allowed; }

.choices[data-type*="select-one"] {
  cursor: pointer; }
  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 7.5px; }
  .choices[data-type*="select-one"] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #DDDDDD;
    background-color: #FFFFFF;
    margin: 0; }
  .choices[data-type*="select-one"] .choices__button {
    background-image: url("../../icons/cross-inverse.svg");
    padding: 0;
    background-size: 8px;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: .5; }
    .choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
      opacity: 1; }
    .choices[data-type*="select-one"] .choices__button:focus {
      box-shadow: 0px 0px 0px 2px #00BCD4; }
  .choices[data-type*="select-one"]:after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #333333 transparent transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none; }
  .choices[data-type*="select-one"].is-open:after {
    border-color: transparent transparent #333333 transparent;
    margin-top: -7.5px; }
  .choices[data-type*="select-one"][dir="rtl"]:after {
    left: 11.5px;
    right: auto; }
  .choices[data-type*="select-one"][dir="rtl"] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0; }

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text; }

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url("../../icons/cross.svg");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: .75; }
  .choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus {
    opacity: 1; }

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden; }
  .is-focused .choices__inner, .is-open .choices__inner {
    border-color: #b7b7b7; }
  .is-open .choices__inner {
    border-radius: 2.5px 2.5px 0 0; }
  .is-flipped.is-open .choices__inner {
    border-radius: 0 0 2.5px 2.5px; }

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%; }
  [dir="rtl"] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px; }
  .choices__list--single .choices__item {
    width: 100%; }

.choices__list--multiple {
  display: inline; }
  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #00BCD4;
    border: 1px solid #00a5bb;
    color: #FFFFFF;
    word-break: break-all; }
    .choices__list--multiple .choices__item[data-deletable] {
      padding-right: 5px; }
    [dir="rtl"] .choices__list--multiple .choices__item {
      margin-right: 0;
      margin-left: 3.75px; }
    .choices__list--multiple .choices__item.is-highlighted {
      background-color: #00a5bb;
      border: 1px solid #008fa1; }
    .is-disabled .choices__list--multiple .choices__item {
      background-color: #aaaaaa;
      border: 1px solid #919191; }

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all; }
  .choices__list--dropdown.is-active {
    display: block; }
  .is-open .choices__list--dropdown {
    border-color: #b7b7b7; }
  .is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: .25rem .25rem 0 0; }
  .choices__list--dropdown .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position; }
  .choices__list--dropdown .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px; }
    [dir="rtl"] .choices__list--dropdown .choices__item {
      text-align: right; }
  @media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable {
      padding-right: 100px; }
      .choices__list--dropdown .choices__item--selectable:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px; }
        [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
          right: auto;
          left: 10px; } }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: #f2f2f2; }
    .choices__list--dropdown .choices__item--selectable.is-highlighted:after {
      opacity: .5; }

.choices__item {
  cursor: default; }

.choices__item--selectable {
  cursor: pointer; }

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: .5; }

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray; }

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }
  .choices__button:focus {
    outline: none; }

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px; }
  .choices__input:focus {
    outline: 0; }
  [dir="rtl"] .choices__input {
    padding-right: 2px;
    padding-left: 0; }

.choices__placeholder {
  opacity: .5; }

/*=====  End of Choices  ======*/
.select, .choices__inner,
.choices[data-type*='select-one'] .choices__inner {
  appearance: none; }

.choices {
  margin-bottom: 1.2rem; }
  .choices__placeholder {
    opacity: 1; }
  .choices.is-open .choices__placeholder {
    opacity: 0.5; }
  .choices__inner,
  .choices[data-type*='select-one'] .choices__inner {
    border: none;
    transition: border-color 0.3s ease; }
  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 0; }
  .choices[data-type*='select-one']::after {
    border-width: 6px 6px 0;
    top: 0;
    bottom: -1px;
    right: 16px;
    margin: auto;
    transform: rotate(0) translate3d(0, 0, 0);
    transition: transform 0.3s ease;
    border-color: #333 transparent transparent; }
  .choices[data-type*='select-one'].is-open::after {
    transform: rotate(180deg) translate3d(0, 0, 0);
    border-color: #333 transparent transparent;
    margin: auto; }
  .choices__list--single {
    display: table;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    padding-right: 20px; }
    .choices__list--single .choices__item {
      display: table-cell;
      vertical-align: middle; }
  .choices__list--dropdown .choices__item {
    padding: 0.5rem 1.7rem; }
    .choices__list--dropdown .choices__item--selectable {
      transition: opacity 0.3s ease; }
      .choices__list--dropdown .choices__item--selectable.is-highlighted {
        opacity: .5;
        background-color: white; }
  .choices.is-open .choices__inner {
    border-color: transparent; }
  .choices.is-open .choices__list--dropdown {
    margin-top: -5px;
    padding-bottom: 10px;
    border: none;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    background: #f2f2f2; }

.input, .textarea, .select, .choices__inner,
.choices[data-type*='select-one'] .choices__inner {
  padding: 0 4rem 0 1.7rem;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.6rem;
  line-height: 1;
  border-radius: 0.8rem;
  min-height: 0;
  background-color: #f2f2f2; }

.inputbox {
  display: block; }

.textarea {
  padding-top: 13px;
  padding-bottom: 13px;
  min-height: 150px;
  resize: vertical; }

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px; }
  .choices:focus {
    outline: none; }
  .choices:last-child {
    margin-bottom: 0; }
  .choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
    background-color: #EAEAEA;
    cursor: not-allowed;
    user-select: none; }
  .choices.is-disabled .choices__item {
    cursor: not-allowed; }

.choices[data-type*="select-one"] {
  cursor: pointer; }
  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 7.5px; }
  .choices[data-type*="select-one"] .choices__input {
    display: block;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #DDDDDD;
    background-color: #FFFFFF;
    margin: 0; }
  .choices[data-type*="select-one"] .choices__button {
    background-image: url("../../icons/cross-inverse.svg");
    padding: 0;
    background-size: 8px;
    height: 100%;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px;
    margin-right: 25px;
    height: 20px;
    width: 20px;
    border-radius: 10em;
    opacity: .5; }
    .choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
      opacity: 1; }
    .choices[data-type*="select-one"] .choices__button:focus {
      box-shadow: 0px 0px 0px 2px #00BCD4; }
  .choices[data-type*="select-one"]:after {
    content: "";
    height: 0;
    width: 0;
    border-style: solid;
    border-color: #333333 transparent transparent transparent;
    border-width: 5px;
    position: absolute;
    right: 11.5px;
    top: 50%;
    margin-top: -2.5px;
    pointer-events: none; }
  .choices[data-type*="select-one"].is-open:after {
    border-color: transparent transparent #333333 transparent;
    margin-top: -7.5px; }
  .choices[data-type*="select-one"][dir="rtl"]:after {
    left: 11.5px;
    right: auto; }
  .choices[data-type*="select-one"][dir="rtl"] .choices__button {
    right: auto;
    left: 0;
    margin-left: 25px;
    margin-right: 0; }

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text; }

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url("../../icons/cross.svg");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: .75; }
  .choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus {
    opacity: 1; }

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden; }
  .is-focused .choices__inner, .is-open .choices__inner {
    border-color: #b7b7b7; }
  .is-open .choices__inner {
    border-radius: 2.5px 2.5px 0 0; }
  .is-flipped.is-open .choices__inner {
    border-radius: 0 0 2.5px 2.5px; }

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none; }

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%; }
  [dir="rtl"] .choices__list--single {
    padding-right: 4px;
    padding-left: 16px; }
  .choices__list--single .choices__item {
    width: 100%; }

.choices__list--multiple {
  display: inline; }
  .choices__list--multiple .choices__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 20px;
    padding: 4px 10px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 3.75px;
    margin-bottom: 3.75px;
    background-color: #00BCD4;
    border: 1px solid #00a5bb;
    color: #FFFFFF;
    word-break: break-all; }
    .choices__list--multiple .choices__item[data-deletable] {
      padding-right: 5px; }
    [dir="rtl"] .choices__list--multiple .choices__item {
      margin-right: 0;
      margin-left: 3.75px; }
    .choices__list--multiple .choices__item.is-highlighted {
      background-color: #00a5bb;
      border: 1px solid #008fa1; }
    .is-disabled .choices__list--multiple .choices__item {
      background-color: #aaaaaa;
      border: 1px solid #919191; }

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all; }
  .choices__list--dropdown.is-active {
    display: block; }
  .is-open .choices__list--dropdown {
    border-color: #b7b7b7; }
  .is-flipped .choices__list--dropdown {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: -1px;
    border-radius: .25rem .25rem 0 0; }
  .choices__list--dropdown .choices__list {
    position: relative;
    max-height: 300px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position; }
  .choices__list--dropdown .choices__item {
    position: relative;
    padding: 10px;
    font-size: 14px; }
    [dir="rtl"] .choices__list--dropdown .choices__item {
      text-align: right; }
  @media (min-width: 640px) {
    .choices__list--dropdown .choices__item--selectable {
      padding-right: 100px; }
      .choices__list--dropdown .choices__item--selectable:after {
        content: attr(data-select-text);
        font-size: 12px;
        opacity: 0;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%); }
      [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
        text-align: right;
        padding-left: 100px;
        padding-right: 10px; }
        [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
          right: auto;
          left: 10px; } }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background-color: #f2f2f2; }
    .choices__list--dropdown .choices__item--selectable.is-highlighted:after {
      opacity: .5; }

.choices__item {
  cursor: default; }

.choices__item--selectable {
  cursor: pointer; }

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: .5; }

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray; }

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer; }
  .choices__button:focus {
    outline: none; }

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px; }
  .choices__input:focus {
    outline: 0; }
  [dir="rtl"] .choices__input {
    padding-right: 2px;
    padding-left: 0; }

.choices__placeholder {
  opacity: .5; }

/*=====  End of Choices  ======*/
.select, .choices__inner,
.choices[data-type*='select-one'] .choices__inner {
  appearance: none; }

.choices {
  margin-bottom: 1.2rem; }
  .choices__placeholder {
    opacity: 1; }
  .choices.is-open .choices__placeholder {
    opacity: 0.5; }
  .choices__inner,
  .choices[data-type*='select-one'] .choices__inner {
    border: none;
    transition: border-color 0.3s ease; }
  .choices[data-type*="select-one"] .choices__inner {
    padding-bottom: 0; }
  .choices[data-type*='select-one']::after {
    border-width: 6px 6px 0;
    top: 0;
    bottom: -1px;
    right: 16px;
    margin: auto;
    transform: rotate(0) translate3d(0, 0, 0);
    transition: transform 0.3s ease;
    border-color: #333 transparent transparent; }
  .choices[data-type*='select-one'].is-open::after {
    transform: rotate(180deg) translate3d(0, 0, 0);
    border-color: #333 transparent transparent;
    margin: auto; }
  .choices__list--single {
    display: table;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    padding-right: 20px; }
    .choices__list--single .choices__item {
      display: table-cell;
      vertical-align: middle; }
  .choices__list--dropdown .choices__item {
    padding: 0.5rem 1.7rem; }
    .choices__list--dropdown .choices__item--selectable {
      transition: opacity 0.3s ease; }
      .choices__list--dropdown .choices__item--selectable.is-highlighted {
        opacity: .5;
        background-color: white; }
  .choices.is-open .choices__inner {
    border-color: transparent; }
  .choices.is-open .choices__list--dropdown {
    margin-top: -5px;
    padding-bottom: 10px;
    border: none;
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    background: #f2f2f2; }

.link {
  font-size: 1.4rem;
  line-height: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease, color 0.3s ease, fill 0.3s ease, stroke 0.3s ease; }
  .link:hover {
    opacity: 0.5; }
  .link_icon {
    position: relative;
    padding-left: 25px; }
  .link__arrow {
    width: 6px;
    height: 6px;
    fill: inherit;
    vertical-align: middle;
    margin: 0 0 .1rem .5rem;
    transition: transform 0.3s ease; }
    .link.is-active .link__arrow {
      transform: rotate(180deg); }
  .link__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0; }
  .link.is-disabled {
    pointer-events: none;
    fill: #ccc;
    color: #ccc;
    border-color: transparent; }

.link {
  font-size: 1.4rem;
  line-height: normal;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease, color 0.3s ease, fill 0.3s ease, stroke 0.3s ease; }
  .link:hover {
    opacity: 0.5; }
  .link_icon {
    position: relative;
    padding-left: 25px; }
  .link__arrow {
    width: 6px;
    height: 6px;
    fill: inherit;
    vertical-align: middle;
    margin: 0 0 .1rem .5rem;
    transition: transform 0.3s ease; }
    .link.is-active .link__arrow {
      transform: rotate(180deg); }
  .link__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0; }
  .link.is-disabled {
    pointer-events: none;
    fill: #ccc;
    color: #ccc;
    border-color: transparent; }

.list {
  font-size: 14px;
  line-height: 1.65;
  font-weight: 400; }
  .list ul, .list ol {
    padding-top: 5px;
    padding-bottom: 5px; }
  .list li {
    padding-left: 20px;
    position: relative; }

ul.list li::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '•'; }

ol.list {
  counter-reset: list1; }
  ol.list li::before {
    position: absolute;
    left: 0;
    top: 0;
    counter-increment: list1;
    content: counter(list1) "."; }

.list {
  font-size: 14px;
  line-height: 1.65;
  font-weight: 400; }
  .list ul, .list ol {
    padding-top: 5px;
    padding-bottom: 5px; }
  .list li {
    padding-left: 20px;
    position: relative; }

ul.list li::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '•'; }

ol.list {
  counter-reset: list1; }
  ol.list li::before {
    position: absolute;
    left: 0;
    top: 0;
    counter-increment: list1;
    content: counter(list1) "."; }

.modal-city, .modal-support, .modal-entrance, .modal-feedback, .modal-product {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: visible !important;
  z-index: 2000000;
  transform: translate3d(0, 15px, 0); }
  .modal-city.is-active, .modal-support.is-active, .modal-entrance.is-active, .modal-feedback.is-active, .modal-product.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.modal-product {
  transform: translate3d(0, -15px, 0); }

.modal-support, .modal-city, .modal-time, .modal-entrance, .modal-feedback {
  top: 100%;
  margin-top: 1.7rem; }

.modal-product {
  bottom: 100%;
  margin-bottom: 1rem; }

.modal-support, .modal-feedback {
  left: 0; }

.modal-city, .modal-entrance, .modal-product {
  right: 0; }

.modal-time::before, .modal-city::before, .modal-support::before, .modal-entrance::before, .modal-feedback::before, .modal-product::before {
  content: "";
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.2rem;
  right: 50%;
  top: -0.5rem;
  background: #ffffff;
  transform: rotate(45deg); }

.modal-city {
  display: none;
  position: absolute;
  width: 63.2rem;
  padding: 1.6rem 1.6rem 2.3rem 1.6rem;
  border-radius: 0.8rem;
  background: #ffffff;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2); }
  .modal-city::before {
    right: 1.4rem; }
  .modal-city__alert-box {
    margin-top: 1rem;
    color: red; }
  .modal-city__btn {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 2.2rem;
    transition: opacity 0.3s ease; }
    .modal-city__btn svg {
      width: 100%;
      height: 100%;
      stroke: #000000;
      fill: none; }
    .modal-city__btn:hover {
      opacity: 0.5; }
  .modal-city__group {
    position: relative; }
  .modal-city__input {
    padding-right: 6rem; }
  .modal-city__title {
    margin: 2.8rem 0 2.2rem 0.8rem;
    font-family: "NeoSansPro", sans-serif;
    font-weight: 400; }
  .modal-city__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem; }
  .modal-city__city {
    width: 31%;
    margin-bottom: 0.1rem; }
  .modal-city__text {
    position: relative;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.6;
    transition: all ease .3s; }
    .modal-city__text:hover {
      cursor: pointer;
      opacity: 0.5; }
    .modal-city__text_selected {
      color: #FF0069; }
      .modal-city__text_selected::before {
        content: "";
        display: block;
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: -2.1rem;
        top: 55%;
        transform: translateY(-50%);
        background: url("../images/location-pin.svg") no-repeat;
        background-position: right center; }

.modal-time {
  pointer-events: none;
  position: absolute;
  width: 19.2rem;
  padding: 1.5rem 1.6rem 2.2rem 1.6rem;
  left: 50%;
  border-radius: 0.8rem;
  background: #ffffff;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 0;
  transform: translate3d(-50%, 15px, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: visible !important;
  z-index: 2000000; }
  .js-time:hover .modal-time {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); }
  .modal-time::before {
    transform: translateX(50%) rotate(45deg); }
  .modal-time__title {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5); }
  .modal-time__time {
    display: block;
    margin-bottom: 1.8rem;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400; }
  .modal-time__text {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400; }

.modal-product {
  display: none;
  position: absolute;
  width: 100%;
  padding: 1.4rem 1.1rem 1.5rem 1.1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2); }
  .modal-product button {
    padding-left: 3.5rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-product button:hover {
      opacity: 0.5; }
  .modal-product__compare {
    margin-bottom: 1px;
    background: url("../images/rings.svg") no-repeat;
    background-position: 0 center; }
  .modal-product__favourites {
    background: url("../images/heart.svg") no-repeat;
    background-position: 2px center; }
  .modal-product::before {
    right: 1rem;
    bottom: -0.5rem;
    top: unset; }

.modal-entrance {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.8rem 1.6rem 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  margin-right: -1rem;
  cursor: initial;
  color: black; }
  .modal-entrance::before {
    right: 1.4rem; }
  .modal-entrance_static {
    display: block;
    position: static;
    transform: none;
    opacity: 1;
    box-shadow: none;
    margin: 0;
    width: auto; }
    .modal-entrance_static::before {
      display: none; }
  .modal-entrance_blue {
    background: #162158;
    color: white; }
    .modal-entrance_blue .switcher__circle {
      background: #ffffff; }
    .modal-entrance_blue .switcher__mover {
      background: #ffffff; }
  .modal-entrance__message {
    margin-bottom: 2rem; }
  .modal-entrance__entrance-error {
    display: none;
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    font-size: 1.6rem; }
  .modal-entrance__title {
    margin: 0 0 2.7rem 1rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .modal-entrance_blue .modal-entrance__title {
      color: #ffffff; }
  .modal-entrance__text {
    margin-bottom: 0.5rem; }
  .modal-entrance__group {
    position: relative;
    margin-bottom: 1.2rem; }
    .modal-entrance__group_remember {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.4rem 1rem 1.6rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.6rem; }
      .modal-entrance_blue .modal-entrance__group_remember {
        border-color: rgba(255, 255, 255, 0.1); }
      .modal-entrance__group_remember .modal-entrance__text {
        margin-bottom: 0; }
  .modal-entrance__input {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    font-size: 1.6rem;
    color: #000000;
    padding: 0 4rem 0 1.7rem;
    border-radius: .6rem;
    border: 1px solid #f2f2f2;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; }
    .modal-entrance__input::placeholder {
      transition: color 0.3s ease; }
    .modal-entrance__input.error {
      border-color: #FF0069 !important;
      color: #FF0069; }
      .modal-entrance__input.error::placeholder {
        color: #FF0069; }
    .modal-entrance_blue .modal-entrance__input {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #162158;
      color: #ffffff; }
      .modal-entrance_blue .modal-entrance__input::placeholder {
        color: #ffffff; }
      .modal-entrance_blue .modal-entrance__input.error {
        background-color: transparent;
        color: white; }
        .modal-entrance_blue .modal-entrance__input.error::placeholder {
          color: #ffffff; }
  .modal-entrance__remember {
    background: transparent;
    font-size: 1.6rem;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .modal-entrance_blue .modal-entrance__remember {
      border-color: rgba(255, 255, 255, 0.1); }
  .modal-entrance__pass {
    position: absolute;
    width: 1.9rem;
    height: 1.1rem;
    top: 1.5rem;
    right: 1.2rem;
    transition: opacity 0.3s ease;
    cursor: pointer;
    font-size: 0; }
    .modal-entrance__pass:hover {
      opacity: 0.5; }
    .modal-entrance__pass svg {
      width: 100%;
      height: 100%;
      fill: none !important;
      stroke: #6699FF !important; }
      .modal-entrance_blue .modal-entrance__pass svg {
        stroke: #ffffff !important; }
  .modal-entrance__btn {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    min-width: 16.4rem;
    margin: 1.2rem 0 0 1rem;
    padding: 0.3rem 1.5rem;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    background: #6699FF;
    border-radius: 5rem;
    transition: opacity 0.3s ease; }
    .modal-entrance_blue .modal-entrance__btn {
      background: white;
      color: black; }
    .modal-entrance__btn:hover {
      opacity: 0.5; }
  .modal-entrance__details {
    margin: 3rem 0 0 1rem; }
  .modal-entrance__info {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    margin-bottom: 1.8rem; }
  .modal-entrance__link {
    color: #6699FF;
    transition: opacity 0.3s ease, color 0.3s ease; }
    .modal-entrance_blue .modal-entrance__link {
      color: #ffffff; }
    .modal-entrance__link_red {
      color: #FF0069 !important; }
    .modal-entrance__link:hover, .modal-entrance__link:active {
      opacity: 0.5; }
  .modal-entrance__registration, .modal-entrance__restore, .modal-entrance__confirmation, .modal-entrance__entrance {
    display: none;
    opacity: 0;
    transform: translate3d(0, 10px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease; }
    .modal-entrance__registration.is-active, .modal-entrance__restore.is-active, .modal-entrance__confirmation.is-active, .modal-entrance__entrance.is-active {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0); }

.modal-account {
  display: none;
  position: absolute;
  width: 43rem;
  min-height: 39.7rem;
  padding: 2.1rem 1.6rem 1.8rem 1.6rem;
  z-index: 112200;
  right: 5rem;
  top: 6rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff; }
  .modal-account::before {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.2rem;
    right: 1.4rem;
    top: -0.5rem;
    background: #ffffff;
    transform: rotate(45deg); }
  .modal-account__profile {
    margin-left: 0.8rem; }
  .modal-account__title {
    position: relative;
    font-size: 1.6rem;
    opacity: 0.7; }
    .modal-account__title::after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 0.6rem;
      right: -1.9rem;
      top: 0.8rem;
      background: url("../images/triangle.svg") no-repeat;
      opacity: 0.7; }
    .modal-account__title_active::after {
      right: -1.5rem;
      top: 0.7rem;
      transform: rotate(180deg); }
  .modal-account__accounts {
    margin-top: 0.3rem; }
  .modal-account__account-name {
    display: none;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-account__account-name:not(:last-child) {
      margin-bottom: 1.1rem; }
    .modal-account__account-name:hover {
      opacity: 0.5; }
    .modal-account__account-name_active {
      display: block; }
      .modal-account__account-name_active:hover {
        opacity: 1; }
  .modal-account__links {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    padding: 0 0.8rem; }
  .modal-account__list {
    width: 18rem; }
  .modal-account__item {
    margin-bottom: 0.8rem; }
  .modal-account__link {
    font-size: 1.8rem;
    line-height: normal;
    color: #6699FF;
    transition: all 0.3s ease; }
    .modal-account__link:hover {
      opacity: 0.5; }
  .modal-account__user-block {
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 0 4.3rem 0;
    padding: 0 0.8rem; }
  .modal-account__points {
    width: 18rem;
    font-size: 1.8rem;
    line-height: normal; }
  .modal-account__count {
    display: block;
    margin-top: 0.6rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.3px;
    color: #8ABE56; }
  .modal-account__exit {
    width: 18rem;
    font-size: 1.8rem;
    line-height: normal;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-account__exit:hover {
      color: #FF0069; }
  .modal-account__mailing {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem 1rem 1.6rem;
    margin-bottom: 1.2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem; }
  .modal-account__notification {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem 1rem 1.6rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem; }

.modal-support {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.7rem 1.6rem 0 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff; }
  .modal-support::before {
    left: 1.4rem; }
  .modal-support__title {
    margin: 0 0 2.6rem 0.9rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .modal-support__group {
    position: relative;
    margin-bottom: 1.2rem; }
    .modal-support__group input {
      padding: 0 4rem 0 1.7rem; }
    .modal-support__group textarea {
      min-height: 9.2rem;
      padding-left: 1.6rem;
      overflow: hidden; }
  .modal-support__btn {
    width: 16.4rem;
    margin: 0.9rem 0 0 0.8rem;
    padding: 0.3rem 0 0.3rem 0;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    color: #ffffff;
    background: #6699FF;
    border-radius: 1.6rem;
    transition: opacity 0.3s ease; }
    .modal-support__btn:hover {
      opacity: 0.5; }
  .modal-support__details {
    margin: 3rem 0 2rem 0.9rem; }
  .modal-support__text {
    width: 70%; }
  .modal-support__link {
    display: block;
    margin-top: 0.4rem;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-support__link:last-child {
      color: #FF0069; }
    .modal-support__link:first-child {
      margin-bottom: 1.8rem; }
    .modal-support__link:hover, .modal-support__link:active {
      opacity: 0.5; }
  .modal-support input {
    border: 1px solid #f2f2f2; }
  .modal-support textarea {
    border: 1px solid #f2f2f2; }

.modal-feedback {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.7rem 1.6rem 0 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff; }
  .modal-feedback::before {
    left: 1.4rem; }
  .modal-feedback__title {
    margin: 0 0 2.6rem 0.9rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .modal-feedback__group {
    position: relative;
    margin-bottom: 1.2rem; }
    .modal-feedback__group input {
      padding: 0 4rem 0 1.7rem; }
    .modal-feedback__group textarea {
      min-height: 9.2rem;
      padding-left: 1.6rem;
      overflow: hidden; }
  .modal-feedback__btn {
    width: 16.4rem;
    margin: 0.9rem 0 0 0.8rem;
    padding: 0.3rem 0 0.3rem 0;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    color: #ffffff;
    background: #6699FF;
    border-radius: 1.6rem;
    transition: all 0.3s ease; }
    .modal-feedback__btn:hover {
      opacity: 0.5; }
  .modal-feedback__details {
    margin: 3rem 0 2rem 0.9rem; }
  .modal-feedback__link {
    display: block;
    margin-top: 0.4rem;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-feedback__link:last-child {
      color: #FF0069; }
    .modal-feedback__link:first-child {
      margin-bottom: 1.8rem; }
    .modal-feedback__link:hover, .modal-feedback__link:active {
      opacity: 0.5; }
  .modal-feedback input {
    border: 1px solid #f2f2f2; }
  .modal-feedback textarea {
    border: 1px solid #f2f2f2; }

.modal-search,
.modal-history {
  display: none;
  position: absolute;
  min-height: 51.6rem;
  padding: 1.7rem 2.4rem 2.4rem 2.5rem;
  z-index: 2;
  left: calc(100% + 8rem - 69rem);
  right: -8rem;
  top: -0.8rem;
  overflow: hidden;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background: #ffffff;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translate3d(0, 5px, 0);
  opacity: 0; }
  .modal-search.is-active,
  .modal-history.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  @media only screen and (min-width: 1240px) {
    .modal-search,
    .modal-history {
      left: calc(100% + 8rem - 91rem); } }
  @media only screen and (min-width: 1440px) {
    .modal-search,
    .modal-history {
      left: calc(100% + 8rem - 113.6rem); } }
  @media only screen and (min-width: 1888px) {
    .modal-search,
    .modal-history {
      left: calc(100% + 8rem - 135.6rem); } }
  .modal-search .product::after,
  .modal-history .product::after {
    transition: opacity 0.3s ease; }
  @media (max-width: 1239px) {
    .modal-search .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible::after {
      opacity: 0; } }
  @media (min-width: 1240px) and (max-width: 1439px) {
    .modal-search .swiper-slide-visible + .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible + .swiper-slide-visible::after {
      opacity: 0; } }
  @media (min-width: 1440px) and (max-width: 1888px) {
    .modal-search .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after {
      opacity: 0; } }
  @media (min-width: 1888px) {
    .modal-search .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after {
      opacity: 0; } }
  .modal-search__header,
  .modal-history__header {
    font-size: 2rem;
    line-height: normal;
    color: #000000;
    width: 24rem; }
    @media only screen and (min-width: 1240px) {
      .modal-search__header,
      .modal-history__header {
        width: 40rem; } }
    @media only screen and (min-width: 1440px) {
      .modal-search__header,
      .modal-history__header {
        width: 50rem; } }
  .modal-search__count,
  .modal-history__count {
    color: #FF0069; }
  .modal-search__close,
  .modal-history__close {
    position: absolute;
    width: 2.6rem;
    height: 2.4rem;
    right: 2.6rem;
    top: 1.5rem;
    transition: opacity 0.3s ease; }
    .modal-search__close::before, .modal-search__close::after,
    .modal-history__close::before,
    .modal-history__close::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: #000000; }
    .modal-search__close::before,
    .modal-history__close::before {
      transform: rotate(-135deg); }
    .modal-search__close::after,
    .modal-history__close::after {
      transform: rotate(135deg); }
    .modal-search__close:hover,
    .modal-history__close:hover {
      opacity: .5; }
  .modal-search__wrapper,
  .modal-history__wrapper {
    display: flex;
    margin-top: 3.3rem; }
  .modal-search__container,
  .modal-history__container {
    margin-left: -1.4rem;
    margin-right: -1.4rem; }
  .modal-search__col-1, .modal-search__col-2,
  .modal-history__col-1,
  .modal-history__col-2 {
    width: 22.2rem;
    padding-right: 2rem;
    box-sixing: border-box; }
  .modal-search__col-3,
  .modal-history__col-3 {
    position: relative;
    width: calc(100% - 44.4rem); }
  .modal-search__title,
  .modal-history__title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #666666; }
  .modal-search__list,
  .modal-history__list {
    margin-top: 0.6rem; }
  .modal-search__item:not(:last-child),
  .modal-history__item:not(:last-child) {
    margin-bottom: 0.95rem; }
  .modal-search__link,
  .modal-history__link {
    font-size: 2rem;
    color: #6699FF;
    transition: opacity 0.3s ease; }
    .modal-search__link:hover,
    .modal-history__link:hover {
      opacity: .5; }
  .modal-search__btn,
  .modal-history__btn {
    width: 16.4rem;
    margin-top: 4.8rem;
    padding: 0.2rem 0.5rem 0.3rem 0.5rem;
    font-size: 1.6rem;
    color: #ffffff;
    background: #6699FF;
    text-align: center;
    border-radius: 1.6rem;
    transition: all 0.3s ease; }
    .modal-search__btn:hover,
    .modal-history__btn:hover {
      opacity: 0.5; }
  .modal-search__buttons,
  .modal-history__buttons {
    position: absolute;
    right: 0;
    top: 0.9rem;
    z-index: 0; }
  .modal-search__prev,
  .modal-history__prev {
    margin-right: 1.2rem; }
  .modal-search__inner,
  .modal-history__inner {
    margin: 1rem 0 0 0; }
  .modal-search__history,
  .modal-history__history {
    display: block;
    margin-top: 1.5rem;
    color: #6699FF;
    text-align: right;
    transition: opacity 0.3s ease; }
    .modal-search__history:hover,
    .modal-history__history:hover {
      opacity: .5; }
  .modal-search .product__promo,
  .modal-history .product__promo {
    display: none; }
  .modal-search .product__buttons,
  .modal-history .product__buttons {
    width: 107%; }

.modal-history {
  min-height: 46.8rem; }

.switcher {
  display: flex;
  align-items: center; }
  .switcher input {
    display: none; }
    .switcher input:checked ~ .switcher__box .switcher__circle {
      transform: translate(150%, -50%); }
    .switcher input:checked ~ .switcher__box .switcher__mover {
      background: #FF0069; }
  .switcher__box {
    width: 3.6rem;
    height: 1.6rem;
    cursor: pointer;
    transition: all .3s ease; }
  .switcher__mover {
    position: relative;
    width: 100%;
    height: 0.2rem;
    top: 50%;
    transform: translateY(-50%);
    background: #cccccc;
    border-radius: 2rem;
    transition: background 0.3s ease; }
  .switcher__circle {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    left: 0;
    top: 50%;
    background: #6699FF;
    border-radius: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    transition: transform 0.3s ease; }

.alert-box {
  color: #FF0069;
  margin: 0.5rem 0 0 0.5rem;
  display: none; }

.modal-city, .modal-support, .modal-entrance, .modal-feedback, .modal-product {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: visible !important;
  z-index: 2000000;
  transform: translate3d(0, 15px, 0); }
  .modal-city.is-active, .modal-support.is-active, .modal-entrance.is-active, .modal-feedback.is-active, .modal-product.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0); }

.modal-product {
  transform: translate3d(0, -15px, 0); }

.modal-support, .modal-city, .modal-time, .modal-entrance, .modal-feedback {
  top: 100%;
  margin-top: 1.7rem; }

.modal-product {
  bottom: 100%;
  margin-bottom: 1rem; }

.modal-support, .modal-feedback {
  left: 0; }

.modal-city, .modal-entrance, .modal-product {
  right: 0; }

.modal-time::before, .modal-city::before, .modal-support::before, .modal-entrance::before, .modal-feedback::before, .modal-product::before {
  content: "";
  display: block;
  position: absolute;
  width: 1.6rem;
  height: 1.2rem;
  right: 50%;
  top: -0.5rem;
  background: #ffffff;
  transform: rotate(45deg); }

.modal-city {
  display: none;
  position: absolute;
  width: 63.2rem;
  padding: 1.6rem 1.6rem 2.3rem 1.6rem;
  border-radius: 0.8rem;
  background: #ffffff;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2); }
  .modal-city::before {
    right: 1.4rem; }
  .modal-city__alert-box {
    margin-top: 1rem;
    color: red; }
  .modal-city__btn {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 2.2rem;
    transition: opacity 0.3s ease; }
    .modal-city__btn svg {
      width: 100%;
      height: 100%;
      stroke: #000000;
      fill: none; }
    .modal-city__btn:hover {
      opacity: 0.5; }
  .modal-city__group {
    position: relative; }
  .modal-city__input {
    padding-right: 6rem; }
  .modal-city__title {
    margin: 2.8rem 0 2.2rem 0.8rem;
    font-family: "NeoSansPro", sans-serif;
    font-weight: 400; }
  .modal-city__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem; }
  .modal-city__city {
    width: 31%;
    margin-bottom: 0.1rem; }
  .modal-city__text {
    position: relative;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.6;
    transition: all ease .3s; }
    .modal-city__text:hover {
      cursor: pointer;
      opacity: 0.5; }
    .modal-city__text_selected {
      color: #FF0069; }
      .modal-city__text_selected::before {
        content: "";
        display: block;
        position: absolute;
        width: 1rem;
        height: 1rem;
        right: -2.1rem;
        top: 55%;
        transform: translateY(-50%);
        background: url("../images/location-pin.svg") no-repeat;
        background-position: right center; }

.modal-time {
  pointer-events: none;
  position: absolute;
  width: 19.2rem;
  padding: 1.5rem 1.6rem 2.2rem 1.6rem;
  left: 50%;
  border-radius: 0.8rem;
  background: #ffffff;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 0;
  transform: translate3d(-50%, 15px, 0);
  transition: opacity 0.3s ease, transform 0.3s ease;
  overflow: visible !important;
  z-index: 2000000; }
  .js-time:hover .modal-time {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); }
  .modal-time::before {
    transform: translateX(50%) rotate(45deg); }
  .modal-time__title {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5); }
  .modal-time__time {
    display: block;
    margin-bottom: 1.8rem;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400; }
  .modal-time__text {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400; }

.modal-product {
  display: none;
  position: absolute;
  width: 100%;
  padding: 1.4rem 1.1rem 1.5rem 1.1rem;
  background: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2); }
  .modal-product button {
    padding-left: 3.5rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-product button:hover {
      opacity: 0.5; }
  .modal-product__compare {
    margin-bottom: 1px;
    background: url("../images/rings.svg") no-repeat;
    background-position: 0 center; }
  .modal-product__favourites {
    background: url("../images/heart.svg") no-repeat;
    background-position: 2px center; }
  .modal-product::before {
    right: 1rem;
    bottom: -0.5rem;
    top: unset; }

.modal-entrance {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.8rem 1.6rem 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff;
  margin-right: -1rem;
  cursor: initial;
  color: black; }
  .modal-entrance::before {
    right: 1.4rem; }
  .modal-entrance_static {
    display: block;
    position: static;
    transform: none;
    opacity: 1;
    box-shadow: none;
    margin: 0;
    width: auto; }
    .modal-entrance_static::before {
      display: none; }
  .modal-entrance_blue {
    background: #162158;
    color: white; }
    .modal-entrance_blue .switcher__circle {
      background: #ffffff; }
    .modal-entrance_blue .switcher__mover {
      background: #ffffff; }
  .modal-entrance__message {
    margin-bottom: 2rem; }
  .modal-entrance__entrance-error {
    display: none;
    margin-bottom: 2.4rem;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    font-size: 1.6rem; }
  .modal-entrance__title {
    margin: 0 0 2.7rem 1rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .modal-entrance_blue .modal-entrance__title {
      color: #ffffff; }
  .modal-entrance__text {
    margin-bottom: 0.5rem; }
  .modal-entrance__group {
    position: relative;
    margin-bottom: 1.2rem; }
    .modal-entrance__group_remember {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.4rem 1rem 1.6rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.6rem; }
      .modal-entrance_blue .modal-entrance__group_remember {
        border-color: rgba(255, 255, 255, 0.1); }
      .modal-entrance__group_remember .modal-entrance__text {
        margin-bottom: 0; }
  .modal-entrance__input {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    font-size: 1.6rem;
    color: #000000;
    padding: 0 4rem 0 1.7rem;
    border-radius: .6rem;
    border: 1px solid #f2f2f2;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; }
    .modal-entrance__input::placeholder {
      transition: color 0.3s ease; }
    .modal-entrance__input.error {
      border-color: #FF0069 !important;
      color: #FF0069; }
      .modal-entrance__input.error::placeholder {
        color: #FF0069; }
    .modal-entrance_blue .modal-entrance__input {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: #162158;
      color: #ffffff; }
      .modal-entrance_blue .modal-entrance__input::placeholder {
        color: #ffffff; }
      .modal-entrance_blue .modal-entrance__input.error {
        background-color: transparent;
        color: white; }
        .modal-entrance_blue .modal-entrance__input.error::placeholder {
          color: #ffffff; }
  .modal-entrance__remember {
    background: transparent;
    font-size: 1.6rem;
    border: 1px solid rgba(0, 0, 0, 0.1); }
    .modal-entrance_blue .modal-entrance__remember {
      border-color: rgba(255, 255, 255, 0.1); }
  .modal-entrance__pass {
    position: absolute;
    width: 1.9rem;
    height: 1.1rem;
    top: 1.5rem;
    right: 1.2rem;
    transition: opacity 0.3s ease;
    cursor: pointer;
    font-size: 0; }
    .modal-entrance__pass:hover {
      opacity: 0.5; }
    .modal-entrance__pass svg {
      width: 100%;
      height: 100%;
      fill: none !important;
      stroke: #6699FF !important; }
      .modal-entrance_blue .modal-entrance__pass svg {
        stroke: #ffffff !important; }
  .modal-entrance__btn {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    min-width: 16.4rem;
    margin: 1.2rem 0 0 1rem;
    padding: 0.3rem 1.5rem;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    background: #6699FF;
    border-radius: 5rem;
    transition: opacity 0.3s ease; }
    .modal-entrance_blue .modal-entrance__btn {
      background: white;
      color: black; }
    .modal-entrance__btn:hover {
      opacity: 0.5; }
  .modal-entrance__details {
    margin: 3rem 0 0 1rem; }
  .modal-entrance__info {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    margin-bottom: 1.8rem; }
  .modal-entrance__link {
    color: #6699FF;
    transition: opacity 0.3s ease, color 0.3s ease; }
    .modal-entrance_blue .modal-entrance__link {
      color: #ffffff; }
    .modal-entrance__link_red {
      color: #FF0069 !important; }
    .modal-entrance__link:hover, .modal-entrance__link:active {
      opacity: 0.5; }
  .modal-entrance__registration, .modal-entrance__restore, .modal-entrance__confirmation, .modal-entrance__entrance {
    display: none;
    opacity: 0;
    transform: translate3d(0, 10px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease; }
    .modal-entrance__registration.is-active, .modal-entrance__restore.is-active, .modal-entrance__confirmation.is-active, .modal-entrance__entrance.is-active {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0); }

.modal-account {
  display: none;
  position: absolute;
  width: 43rem;
  min-height: 39.7rem;
  padding: 2.1rem 1.6rem 1.8rem 1.6rem;
  z-index: 112200;
  right: 5rem;
  top: 6rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff; }
  .modal-account::before {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.2rem;
    right: 1.4rem;
    top: -0.5rem;
    background: #ffffff;
    transform: rotate(45deg); }
  .modal-account__profile {
    margin-left: 0.8rem; }
  .modal-account__title {
    position: relative;
    font-size: 1.6rem;
    opacity: 0.7; }
    .modal-account__title::after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 0.6rem;
      right: -1.9rem;
      top: 0.8rem;
      background: url("../images/triangle.svg") no-repeat;
      opacity: 0.7; }
    .modal-account__title_active::after {
      right: -1.5rem;
      top: 0.7rem;
      transform: rotate(180deg); }
  .modal-account__accounts {
    margin-top: 0.3rem; }
  .modal-account__account-name {
    display: none;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-account__account-name:not(:last-child) {
      margin-bottom: 1.1rem; }
    .modal-account__account-name:hover {
      opacity: 0.5; }
    .modal-account__account-name_active {
      display: block; }
      .modal-account__account-name_active:hover {
        opacity: 1; }
  .modal-account__links {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    padding: 0 0.8rem; }
  .modal-account__list {
    width: 18rem; }
  .modal-account__item {
    margin-bottom: 0.8rem; }
  .modal-account__link {
    font-size: 1.8rem;
    line-height: normal;
    color: #6699FF;
    transition: all 0.3s ease; }
    .modal-account__link:hover {
      opacity: 0.5; }
  .modal-account__user-block {
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 0 4.3rem 0;
    padding: 0 0.8rem; }
  .modal-account__points {
    width: 18rem;
    font-size: 1.8rem;
    line-height: normal; }
  .modal-account__count {
    display: block;
    margin-top: 0.6rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.3px;
    color: #8ABE56; }
  .modal-account__exit {
    width: 18rem;
    font-size: 1.8rem;
    line-height: normal;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-account__exit:hover {
      color: #FF0069; }
  .modal-account__mailing {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem 1rem 1.6rem;
    margin-bottom: 1.2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem; }
  .modal-account__notification {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem 1rem 1.6rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem; }

.modal-support {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.7rem 1.6rem 0 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff; }
  .modal-support::before {
    left: 1.4rem; }
  .modal-support__title {
    margin: 0 0 2.6rem 0.9rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .modal-support__group {
    position: relative;
    margin-bottom: 1.2rem; }
    .modal-support__group input {
      padding: 0 4rem 0 1.7rem; }
    .modal-support__group textarea {
      min-height: 9.2rem;
      padding-left: 1.6rem;
      overflow: hidden; }
  .modal-support__btn {
    width: 16.4rem;
    margin: 0.9rem 0 0 0.8rem;
    padding: 0.3rem 0 0.3rem 0;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    color: #ffffff;
    background: #6699FF;
    border-radius: 1.6rem;
    transition: opacity 0.3s ease; }
    .modal-support__btn:hover {
      opacity: 0.5; }
  .modal-support__details {
    margin: 3rem 0 2rem 0.9rem; }
  .modal-support__text {
    width: 70%; }
  .modal-support__link {
    display: block;
    margin-top: 0.4rem;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-support__link:last-child {
      color: #FF0069; }
    .modal-support__link:first-child {
      margin-bottom: 1.8rem; }
    .modal-support__link:hover, .modal-support__link:active {
      opacity: 0.5; }
  .modal-support input {
    border: 1px solid #f2f2f2; }
  .modal-support textarea {
    border: 1px solid #f2f2f2; }

.modal-feedback {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.7rem 1.6rem 0 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: #ffffff; }
  .modal-feedback::before {
    left: 1.4rem; }
  .modal-feedback__title {
    margin: 0 0 2.6rem 0.9rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .modal-feedback__group {
    position: relative;
    margin-bottom: 1.2rem; }
    .modal-feedback__group input {
      padding: 0 4rem 0 1.7rem; }
    .modal-feedback__group textarea {
      min-height: 9.2rem;
      padding-left: 1.6rem;
      overflow: hidden; }
  .modal-feedback__btn {
    width: 16.4rem;
    margin: 0.9rem 0 0 0.8rem;
    padding: 0.3rem 0 0.3rem 0;
    text-align: center;
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    color: #ffffff;
    background: #6699FF;
    border-radius: 1.6rem;
    transition: all 0.3s ease; }
    .modal-feedback__btn:hover {
      opacity: 0.5; }
  .modal-feedback__details {
    margin: 3rem 0 2rem 0.9rem; }
  .modal-feedback__link {
    display: block;
    margin-top: 0.4rem;
    color: #000000;
    transition: all 0.3s ease; }
    .modal-feedback__link:last-child {
      color: #FF0069; }
    .modal-feedback__link:first-child {
      margin-bottom: 1.8rem; }
    .modal-feedback__link:hover, .modal-feedback__link:active {
      opacity: 0.5; }
  .modal-feedback input {
    border: 1px solid #f2f2f2; }
  .modal-feedback textarea {
    border: 1px solid #f2f2f2; }

.modal-search,
.modal-history {
  display: none;
  position: absolute;
  min-height: 51.6rem;
  padding: 1.7rem 2.4rem 2.4rem 2.5rem;
  z-index: 2;
  left: calc(100% + 8rem - 69rem);
  right: -8rem;
  top: -0.8rem;
  overflow: hidden;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background: #ffffff;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: translate3d(0, 5px, 0);
  opacity: 0; }
  .modal-search.is-active,
  .modal-history.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  @media only screen and (min-width: 1240px) {
    .modal-search,
    .modal-history {
      left: calc(100% + 8rem - 91rem); } }
  @media only screen and (min-width: 1440px) {
    .modal-search,
    .modal-history {
      left: calc(100% + 8rem - 113.6rem); } }
  @media only screen and (min-width: 1888px) {
    .modal-search,
    .modal-history {
      left: calc(100% + 8rem - 135.6rem); } }
  .modal-search .product::after,
  .modal-history .product::after {
    transition: opacity 0.3s ease; }
  @media (max-width: 1239px) {
    .modal-search .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible::after {
      opacity: 0; } }
  @media (min-width: 1240px) and (max-width: 1439px) {
    .modal-search .swiper-slide-visible + .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible + .swiper-slide-visible::after {
      opacity: 0; } }
  @media (min-width: 1440px) and (max-width: 1888px) {
    .modal-search .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after {
      opacity: 0; } }
  @media (min-width: 1888px) {
    .modal-search .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after,
    .modal-history .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible::after {
      opacity: 0; } }
  .modal-search__header,
  .modal-history__header {
    font-size: 2rem;
    line-height: normal;
    color: #000000;
    width: 24rem; }
    @media only screen and (min-width: 1240px) {
      .modal-search__header,
      .modal-history__header {
        width: 40rem; } }
    @media only screen and (min-width: 1440px) {
      .modal-search__header,
      .modal-history__header {
        width: 50rem; } }
  .modal-search__count,
  .modal-history__count {
    color: #FF0069; }
  .modal-search__close,
  .modal-history__close {
    position: absolute;
    width: 2.6rem;
    height: 2.4rem;
    right: 2.6rem;
    top: 1.5rem;
    transition: opacity 0.3s ease; }
    .modal-search__close::before, .modal-search__close::after,
    .modal-history__close::before,
    .modal-history__close::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: #000000; }
    .modal-search__close::before,
    .modal-history__close::before {
      transform: rotate(-135deg); }
    .modal-search__close::after,
    .modal-history__close::after {
      transform: rotate(135deg); }
    .modal-search__close:hover,
    .modal-history__close:hover {
      opacity: .5; }
  .modal-search__wrapper,
  .modal-history__wrapper {
    display: flex;
    margin-top: 3.3rem; }
  .modal-search__container,
  .modal-history__container {
    margin-left: -1.4rem;
    margin-right: -1.4rem; }
  .modal-search__col-1, .modal-search__col-2,
  .modal-history__col-1,
  .modal-history__col-2 {
    width: 22.2rem;
    padding-right: 2rem;
    box-sixing: border-box; }
  .modal-search__col-3,
  .modal-history__col-3 {
    position: relative;
    width: calc(100% - 44.4rem); }
  .modal-search__title,
  .modal-history__title {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #666666; }
  .modal-search__list,
  .modal-history__list {
    margin-top: 0.6rem; }
  .modal-search__item:not(:last-child),
  .modal-history__item:not(:last-child) {
    margin-bottom: 0.95rem; }
  .modal-search__link,
  .modal-history__link {
    font-size: 2rem;
    color: #6699FF;
    transition: opacity 0.3s ease; }
    .modal-search__link:hover,
    .modal-history__link:hover {
      opacity: .5; }
  .modal-search__btn,
  .modal-history__btn {
    width: 16.4rem;
    margin-top: 4.8rem;
    padding: 0.2rem 0.5rem 0.3rem 0.5rem;
    font-size: 1.6rem;
    color: #ffffff;
    background: #6699FF;
    text-align: center;
    border-radius: 1.6rem;
    transition: all 0.3s ease; }
    .modal-search__btn:hover,
    .modal-history__btn:hover {
      opacity: 0.5; }
  .modal-search__buttons,
  .modal-history__buttons {
    position: absolute;
    right: 0;
    top: 0.9rem;
    z-index: 0; }
  .modal-search__prev,
  .modal-history__prev {
    margin-right: 1.2rem; }
  .modal-search__inner,
  .modal-history__inner {
    margin: 1rem 0 0 0; }
  .modal-search__history,
  .modal-history__history {
    display: block;
    margin-top: 1.5rem;
    color: #6699FF;
    text-align: right;
    transition: opacity 0.3s ease; }
    .modal-search__history:hover,
    .modal-history__history:hover {
      opacity: .5; }
  .modal-search .product__promo,
  .modal-history .product__promo {
    display: none; }
  .modal-search .product__buttons,
  .modal-history .product__buttons {
    width: 107%; }

.modal-history {
  min-height: 46.8rem; }

.switcher {
  display: flex;
  align-items: center; }
  .switcher input {
    display: none; }
    .switcher input:checked ~ .switcher__box .switcher__circle {
      transform: translate(150%, -50%); }
    .switcher input:checked ~ .switcher__box .switcher__mover {
      background: #FF0069; }
  .switcher__box {
    width: 3.6rem;
    height: 1.6rem;
    cursor: pointer;
    transition: all .3s ease; }
  .switcher__mover {
    position: relative;
    width: 100%;
    height: 0.2rem;
    top: 50%;
    transform: translateY(-50%);
    background: #cccccc;
    border-radius: 2rem;
    transition: background 0.3s ease; }
  .switcher__circle {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    left: 0;
    top: 50%;
    background: #6699FF;
    border-radius: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    transition: transform 0.3s ease; }

.alert-box {
  color: #FF0069;
  margin: 0.5rem 0 0 0.5rem;
  display: none; }

.navigation {
  height: 5.6rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center; }
  .navigation .js-menu-hidden {
    transition: opacity 0.3s ease; }
    .navigation .js-menu-hidden.is-hidden {
      opacity: 0;
      pointer-events: none; }
  .navigation__container {
    display: flex;
    transition: background 0.3s ease;
    align-items: center; }
    .header.is-fixed .navigation__container {
      z-index: 1; }
  .navigation__icon {
    transition: fill 0.3s ease, stroke 0.3s ease, opacity 0.3s ease;
    width: 2.8rem;
    height: 2.8rem; }
    .navigation__icon:hover {
      opacity: 0.5; }
  .navigation__left {
    width: 45.5%; }
    @media only screen and (min-width: 1240px) {
      .navigation__left {
        width: 55%; } }
    @media only screen and (min-width: 1440px) {
      .navigation__left {
        width: 52.5%; } }
    @media only screen and (min-width: 1888px) {
      .navigation__left {
        width: 37%; } }
  .navigation__right {
    display: flex;
    align-items: center;
    flex: 1; }
  .navigation__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center; }
  .navigation__arrow {
    width: 11px;
    height: 6px;
    fill: inherit;
    vertical-align: middle;
    display: inline-block;
    margin: 0 0 0.1rem 0.3rem; }
  .navigation__logo {
    display: block;
    font-size: 0;
    margin-right: 3rem;
    transition: opacity 0.3s ease;
    position: relative; }
    .navigation__logo:hover {
      opacity: .5; }
  .navigation__item {
    position: relative; }
    .navigation__item:not(:last-child) {
      margin-right: 2.5rem; }
    .navigation__item_hidden {
      display: none; }
      @media only screen and (min-width: 1240px) {
        .navigation__item_hidden {
          display: block; } }
    .navigation__item_ellipsis {
      margin: 0 0 0 -1rem;
      font-size: 1.8rem; }
      @media only screen and (min-width: 1240px) {
        .navigation__item_ellipsis {
          display: none; } }
    .navigation__item_key {
      margin: 0.2rem 3.1rem 0 0.8rem; }
  .navigation__link {
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: #000000;
    fill: #000000; }
    .navigation__link:hover {
      opacity: .5; }
  .navigation__caption {
    display: block;
    margin: 1px 0 0 1px;
    transition: opacity 0.3s ease;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    color: rgba(57, 49, 88, 0.5); }
  .navigation__icon-item {
    margin-left: 4rem;
    flex-shrink: 0;
    cursor: pointer;
    position: relative; }
    @media only screen and (min-width: 1440px) {
      .navigation__icon-item {
        margin-left: 3.5rem; } }
  .navigation__form {
    position: relative;
    flex-grow: 1;
    margin-right: -1rem;
    margin-left: auto;
    max-width: 32rem; }
    @media only screen and (min-width: 1240px) {
      .navigation__form {
        max-width: 38rem; } }
    @media only screen and (min-width: 1440px) {
      .navigation__form {
        max-width: 45.6rem; } }
    @media only screen and (min-width: 1888px) {
      .navigation__form {
        max-width: 85rem; } }
    .navigation__form input {
      background-color: rgba(0, 0, 0, 0.05);
      padding-right: 6rem;
      transition: background-color 0.3s ease; }
  .navigation__group {
    position: relative; }
    .navigation__group.is-active {
      z-index: 3; }
  .navigation__btn {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    bottom: 0;
    right: 2.2rem;
    margin: auto; }
    .navigation__btn svg {
      width: 100%;
      height: 100%;
      fill: transparent;
      stroke: rgba(0, 0, 0, 0.8);
      transition: stroke 0.3s ease, fill 0.3s ease; }
    .navigation__btn:hover svg {
      stroke: black; }
  .navigation__input {
    padding-right: 5rem;
    border-radius: 0.6rem; }
  .navigation__user svg {
    stroke: #000000;
    fill: none; }
  .navigation__user_login {
    display: none;
    width: 28px;
    height: 28px;
    border: 2px solid #FF0069;
    border-radius: 50%;
    color: #FF0069;
    font-size: 1.4rem;
    font-family: 'TTRounds', sans-serif;
    font-weight: bold; }
  .navigation__basket {
    position: relative; }
  .navigation__burger {
    display: flex;
    position: relative;
    align-items: center;
    width: 2.6rem;
    height: 2.4rem;
    transition: opacity 0.3s ease; }
    .navigation__burger:hover {
      opacity: 0.5; }
    .navigation__burger span, .navigation__burger::before, .navigation__burger::after {
      background-color: inherit;
      border-radius: 10rem;
      background: #000000;
      height: .2rem;
      width: 100%;
      transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease; }
    .navigation__burger::before, .navigation__burger::after {
      content: '';
      position: absolute;
      left: 0; }
    .navigation__burger::before {
      transform: translate3d(0, -1.2rem, 0); }
    .navigation__burger::after {
      transform: translate3d(0, 1.2rem, 0); }
    .navigation__burger.is-active span {
      opacity: 0; }
    .navigation__burger.is-active::before {
      transform: rotate(45deg); }
    .navigation__burger.is-active::after {
      transform: rotate(-45deg); }
  .header.is-fixed:not(.is-normal) .navigation {
    transform: translate3d(0, -36px, 0);
    background-color: #000000; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__caption {
      opacity: 0; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__item_catalog:after {
      fill: white; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__link {
      color: #ffffff;
      fill: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__burger::before, .header.is-fixed:not(.is-normal) .navigation .navigation__burger::after, .header.is-fixed:not(.is-normal) .navigation .navigation__burger span {
      background: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__group:not(.is-active) .js-site-search {
      background: rgba(255, 255, 255, 0.3); }
    .header.is-fixed:not(.is-normal) .navigation .navigation__group:not(.is-active) .navigation__btn svg {
      stroke: #ffffff;
      fill: transparent; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__input {
      color: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__user svg {
      fill: transparent;
      stroke: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navgitaion__basket svg {
      fill: transparent;
      stroke: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__icon {
      fill: white; }
  .navigation__count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    top: -0.5rem;
    right: -1.3rem;
    background: #FF0069;
    border-radius: 50%;
    color: #ffffff;
    font-size: 1.1rem;
    line-height: normal; }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.navigation {
  height: 5.6rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center; }
  .navigation .js-menu-hidden {
    transition: opacity 0.3s ease; }
    .navigation .js-menu-hidden.is-hidden {
      opacity: 0;
      pointer-events: none; }
  .navigation__container {
    display: flex;
    transition: background 0.3s ease;
    align-items: center; }
    .header.is-fixed .navigation__container {
      z-index: 1; }
  .navigation__icon {
    transition: fill 0.3s ease, stroke 0.3s ease, opacity 0.3s ease;
    width: 2.8rem;
    height: 2.8rem; }
    .navigation__icon:hover {
      opacity: 0.5; }
  .navigation__left {
    width: 45.5%; }
    @media only screen and (min-width: 1240px) {
      .navigation__left {
        width: 55%; } }
    @media only screen and (min-width: 1440px) {
      .navigation__left {
        width: 52.5%; } }
    @media only screen and (min-width: 1888px) {
      .navigation__left {
        width: 37%; } }
  .navigation__right {
    display: flex;
    align-items: center;
    flex: 1; }
  .navigation__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center; }
  .navigation__arrow {
    width: 11px;
    height: 6px;
    fill: inherit;
    vertical-align: middle;
    display: inline-block;
    margin: 0 0 0.1rem 0.3rem; }
  .navigation__logo {
    display: block;
    font-size: 0;
    margin-right: 3rem;
    transition: opacity 0.3s ease;
    position: relative; }
    .navigation__logo:hover {
      opacity: .5; }
  .navigation__item {
    position: relative; }
    .navigation__item:not(:last-child) {
      margin-right: 2.5rem; }
    .navigation__item_hidden {
      display: none; }
      @media only screen and (min-width: 1240px) {
        .navigation__item_hidden {
          display: block; } }
    .navigation__item_ellipsis {
      margin: 0 0 0 -1rem;
      font-size: 1.8rem; }
      @media only screen and (min-width: 1240px) {
        .navigation__item_ellipsis {
          display: none; } }
    .navigation__item_key {
      margin: 0.2rem 3.1rem 0 0.8rem; }
  .navigation__link {
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: #000000;
    fill: #000000; }
    .navigation__link:hover {
      opacity: .5; }
  .navigation__caption {
    display: block;
    margin: 1px 0 0 1px;
    transition: opacity 0.3s ease;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    color: rgba(57, 49, 88, 0.5); }
  .navigation__icon-item {
    margin-left: 4rem;
    flex-shrink: 0;
    cursor: pointer;
    position: relative; }
    @media only screen and (min-width: 1440px) {
      .navigation__icon-item {
        margin-left: 3.5rem; } }
  .navigation__form {
    position: relative;
    flex-grow: 1;
    margin-right: -1rem;
    margin-left: auto;
    max-width: 32rem; }
    @media only screen and (min-width: 1240px) {
      .navigation__form {
        max-width: 38rem; } }
    @media only screen and (min-width: 1440px) {
      .navigation__form {
        max-width: 45.6rem; } }
    @media only screen and (min-width: 1888px) {
      .navigation__form {
        max-width: 85rem; } }
    .navigation__form input {
      background-color: rgba(0, 0, 0, 0.05);
      padding-right: 6rem;
      transition: background-color 0.3s ease; }
  .navigation__group {
    position: relative; }
    .navigation__group.is-active {
      z-index: 3; }
  .navigation__btn {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    bottom: 0;
    right: 2.2rem;
    margin: auto; }
    .navigation__btn svg {
      width: 100%;
      height: 100%;
      fill: transparent;
      stroke: rgba(0, 0, 0, 0.8);
      transition: stroke 0.3s ease, fill 0.3s ease; }
    .navigation__btn:hover svg {
      stroke: black; }
  .navigation__input {
    padding-right: 5rem;
    border-radius: 0.6rem; }
  .navigation__user svg {
    stroke: #000000;
    fill: none; }
  .navigation__user_login {
    display: none;
    width: 28px;
    height: 28px;
    border: 2px solid #FF0069;
    border-radius: 50%;
    color: #FF0069;
    font-size: 1.4rem;
    font-family: 'TTRounds', sans-serif;
    font-weight: bold; }
  .navigation__basket {
    position: relative; }
  .navigation__burger {
    display: flex;
    position: relative;
    align-items: center;
    width: 2.6rem;
    height: 2.4rem;
    transition: opacity 0.3s ease; }
    .navigation__burger:hover {
      opacity: 0.5; }
    .navigation__burger span, .navigation__burger::before, .navigation__burger::after {
      background-color: inherit;
      border-radius: 10rem;
      background: #000000;
      height: .2rem;
      width: 100%;
      transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease; }
    .navigation__burger::before, .navigation__burger::after {
      content: '';
      position: absolute;
      left: 0; }
    .navigation__burger::before {
      transform: translate3d(0, -1.2rem, 0); }
    .navigation__burger::after {
      transform: translate3d(0, 1.2rem, 0); }
    .navigation__burger.is-active span {
      opacity: 0; }
    .navigation__burger.is-active::before {
      transform: rotate(45deg); }
    .navigation__burger.is-active::after {
      transform: rotate(-45deg); }
  .header.is-fixed:not(.is-normal) .navigation {
    transform: translate3d(0, -36px, 0);
    background-color: #000000; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__caption {
      opacity: 0; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__item_catalog:after {
      fill: white; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__link {
      color: #ffffff;
      fill: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__burger::before, .header.is-fixed:not(.is-normal) .navigation .navigation__burger::after, .header.is-fixed:not(.is-normal) .navigation .navigation__burger span {
      background: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__group:not(.is-active) .js-site-search {
      background: rgba(255, 255, 255, 0.3); }
    .header.is-fixed:not(.is-normal) .navigation .navigation__group:not(.is-active) .navigation__btn svg {
      stroke: #ffffff;
      fill: transparent; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__input {
      color: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__user svg {
      fill: transparent;
      stroke: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navgitaion__basket svg {
      fill: transparent;
      stroke: #ffffff; }
    .header.is-fixed:not(.is-normal) .navigation .navigation__icon {
      fill: white; }
  .navigation__count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    top: -0.5rem;
    right: -1.3rem;
    background: #FF0069;
    border-radius: 50%;
    color: #ffffff;
    font-size: 1.1rem;
    line-height: normal; }

@keyframes show {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.product {
  position: relative;
  padding: 0 1.6rem 1.6rem 1.6rem;
  z-index: 1;
  background-color: transparent;
  border-radius: 0.8rem;
  z-index: 221; }
  .product:hover {
    z-index: 222; }
    .product:hover::after {
      display: none; }
    .product:hover::before {
      opacity: 1;
      transform: scale(1);
      pointer-events: all; }
    .product:hover .product__promo {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .product:hover .product__buttons {
      opacity: 1; }
  .product::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 70%;
    right: 0;
    top: 1.2rem;
    background: #cccccc; }
  .product::before {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    width: 110%;
    height: 107%;
    top: -7%;
    left: -5%;
    z-index: 1;
    background: #ffffff;
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 0.8rem 2.4rem 0 rgba(0, 0, 0, 0.24);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scale(0.95);
    pointer-events: none; }
  @media only screen and (min-width: 1440px) {
    .product::after {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 70%;
      right: 0;
      top: 1.2rem;
      background: #cccccc; } }
  .product__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.2rem !important;
    height: 19.2rem;
    margin: 1rem auto 0 auto; }
    @media only screen and (min-width: 1240px) {
      .product__image {
        margin: 1.2rem auto 0 auto; } }
  .product__info {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 11.3rem;
    margin: 1.4rem 0 0 0;
    z-index: 1; }
    @media only screen and (min-width: 1240px) {
      .product__info {
        height: 10.8rem;
        margin-top: 1.5rem; } }
    @media only screen and (min-width: 1888px) {
      .product__info {
        margin-top: 1.5rem; } }
  .product__price {
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    color: #FF0069;
    font-family: "NeoSansPro", sans-serif; }
    .product__price span {
      font-family: "TTSmalls", sans-serif; }
  .product__descr {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: #000000;
    transition: all 0.3s ease; }
    .product__descr:hover {
      opacity: 0.5; }
  .product__details {
    position: relative;
    display: flex;
    margin-top: 0.7rem;
    z-index: 1; }
    @media only screen and (min-width: 1240px) {
      .product__details {
        margin-top: 0.3rem; } }
  .product__user-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: auto; }
  .product__more {
    position: relative;
    width: 3rem;
    height: 1.4rem;
    margin: 0.5rem 0 0 0.9rem;
    transition: all 0.3s ease; }
    .product__more:hover {
      opacity: 0.5; }
  .product__article {
    margin: 0.4rem 0 0 auto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.4); }
  .product__features {
    display: flex;
    position: absolute;
    width: 85%;
    height: 2.4rem;
    z-index: 22; }
  .product__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    font-family: 'TTRoundsBold', sans-serif;
    font-size: 1.4rem;
    line-height: 1.35;
    color: #ffffff; }
    .product__item:not(:first-child) {
      margin-left: -0.4rem; }
    .product__item_persent {
      background: #162158; }
      .product__item_persent svg {
        width: 50%;
        height: 50%;
        fill: #ffffff; }
    .product__item_plus {
      position: relative;
      background: #AAD77D; }
    .product__item_p {
      background: #6699FF; }
    .product__item_c {
      background: #FF0069; }
    .product__item_new {
      font-size: 0.8rem;
      background: #6699FF; }
    .product__item_present {
      position: relative;
      background: #FF0069; }
      .product__item_present svg {
        width: 50%;
        height: 50%;
        fill: none;
        stroke: #ffffff; }
    .product__item_important {
      position: relative;
      background: #EC875A; }
      .product__item_important svg {
        width: 50%;
        height: 70%;
        stroke: #ffffff; }
  .product__points {
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    color: #8ABE56;
    font-weight: 600; }
  .product__discounts {
    display: flex;
    margin-right: 0.5rem; }
  .product__persent {
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    color: #6699FF;
    font-weight: 600; }
  .product__minus {
    margin-right: 0.7rem;
    text-decoration: line-through;
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    font-weight: 500;
    color: #cccccc; }
  .product__buy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 2.4rem;
    background: #6699FF;
    border-radius: 1.6rem;
    transition: all 0.3s ease; }
    .product__buy:hover {
      opacity: 0.5; }
    .product__buy svg {
      width: 70%;
      height: 70%;
      fill: #ffffff; }
  .product__ellipsis {
    top: 0.5rem; }
    .product__ellipsis, .product__ellipsis::before, .product__ellipsis::after {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background: #cccccc; }
    .product__ellipsis::before {
      content: '';
      left: -0.8rem; }
    .product__ellipsis::after {
      content: '';
      right: -0.8rem; }
  .product__promo {
    position: relative;
    opacity: 0;
    z-index: 1;
    margin-top: 1.2rem;
    transform: translate3d(0, 10px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease; }
    .product__promo p {
      margin-bottom: 0.2rem;
      font-size: 1rem;
      line-height: normal;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      letter-spacing: -0.06px;
      line-height: 1.1; }
    .product__promo span {
      font-size: 1rem;
      line-height: normal;
      font-weight: 500;
      color: #FF0069;
      letter-spacing: -0.04px; }
  .product__buttons {
    display: flex;
    position: absolute;
    width: 104%;
    justify-content: space-between;
    left: -0.6rem;
    top: 25%;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease; }
    .product__buttons button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      transition: all 0.3s ease; }
      .product__buttons button svg {
        width: 1.2rem;
        height: 1.2rem; }
  .product .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center; }

.product {
  position: relative;
  padding: 0 1.6rem 1.6rem 1.6rem;
  z-index: 1;
  background-color: transparent;
  border-radius: 0.8rem;
  z-index: 221; }
  .product:hover {
    z-index: 222; }
    .product:hover::after {
      display: none; }
    .product:hover::before {
      opacity: 1;
      transform: scale(1);
      pointer-events: all; }
    .product:hover .product__promo {
      opacity: 1;
      transform: translate3d(0, 0, 0); }
    .product:hover .product__buttons {
      opacity: 1; }
  .product::after {
    content: "";
    display: block;
    position: absolute;
    width: 1px;
    height: 70%;
    right: 0;
    top: 1.2rem;
    background: #cccccc; }
  .product::before {
    content: "";
    display: block;
    position: absolute;
    opacity: 0;
    width: 110%;
    height: 107%;
    top: -7%;
    left: -5%;
    z-index: 1;
    background: #ffffff;
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 0.8rem 2.4rem 0 rgba(0, 0, 0, 0.24);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scale(0.95);
    pointer-events: none; }
  @media only screen and (min-width: 1440px) {
    .product::after {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 70%;
      right: 0;
      top: 1.2rem;
      background: #cccccc; } }
  .product__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19.2rem !important;
    height: 19.2rem;
    margin: 1rem auto 0 auto; }
    @media only screen and (min-width: 1240px) {
      .product__image {
        margin: 1.2rem auto 0 auto; } }
  .product__info {
    display: flex;
    position: relative;
    flex-direction: column;
    height: 11.3rem;
    margin: 1.4rem 0 0 0;
    z-index: 1; }
    @media only screen and (min-width: 1240px) {
      .product__info {
        height: 10.8rem;
        margin-top: 1.5rem; } }
    @media only screen and (min-width: 1888px) {
      .product__info {
        margin-top: 1.5rem; } }
  .product__price {
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    color: #FF0069;
    font-family: "NeoSansPro", sans-serif; }
    .product__price span {
      font-family: "TTSmalls", sans-serif; }
  .product__descr {
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: #000000;
    transition: all 0.3s ease; }
    .product__descr:hover {
      opacity: 0.5; }
  .product__details {
    position: relative;
    display: flex;
    margin-top: 0.7rem;
    z-index: 1; }
    @media only screen and (min-width: 1240px) {
      .product__details {
        margin-top: 0.3rem; } }
  .product__user-block {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: auto; }
  .product__more {
    position: relative;
    width: 3rem;
    height: 1.4rem;
    margin: 0.5rem 0 0 0.9rem;
    transition: all 0.3s ease; }
    .product__more:hover {
      opacity: 0.5; }
  .product__article {
    margin: 0.4rem 0 0 auto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6;
    color: rgba(0, 0, 0, 0.4); }
  .product__features {
    display: flex;
    position: absolute;
    width: 85%;
    height: 2.4rem;
    z-index: 22; }
  .product__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    font-family: 'TTRoundsBold', sans-serif;
    font-size: 1.4rem;
    line-height: 1.35;
    color: #ffffff; }
    .product__item:not(:first-child) {
      margin-left: -0.4rem; }
    .product__item_persent {
      background: #162158; }
      .product__item_persent svg {
        width: 50%;
        height: 50%;
        fill: #ffffff; }
    .product__item_plus {
      position: relative;
      background: #AAD77D; }
    .product__item_p {
      background: #6699FF; }
    .product__item_c {
      background: #FF0069; }
    .product__item_new {
      font-size: 0.8rem;
      background: #6699FF; }
    .product__item_present {
      position: relative;
      background: #FF0069; }
      .product__item_present svg {
        width: 50%;
        height: 50%;
        fill: none;
        stroke: #ffffff; }
    .product__item_important {
      position: relative;
      background: #EC875A; }
      .product__item_important svg {
        width: 50%;
        height: 70%;
        stroke: #ffffff; }
  .product__points {
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    color: #8ABE56;
    font-weight: 600; }
  .product__discounts {
    display: flex;
    margin-right: 0.5rem; }
  .product__persent {
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    color: #6699FF;
    font-weight: 600; }
  .product__minus {
    margin-right: 0.7rem;
    text-decoration: line-through;
    font-size: 1rem;
    line-height: normal;
    font-weight: 500;
    font-weight: 500;
    color: #cccccc; }
  .product__buy {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 2.4rem;
    background: #6699FF;
    border-radius: 1.6rem;
    transition: all 0.3s ease; }
    .product__buy:hover {
      opacity: 0.5; }
    .product__buy svg {
      width: 70%;
      height: 70%;
      fill: #ffffff; }
  .product__ellipsis {
    top: 0.5rem; }
    .product__ellipsis, .product__ellipsis::before, .product__ellipsis::after {
      position: absolute;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background: #cccccc; }
    .product__ellipsis::before {
      content: '';
      left: -0.8rem; }
    .product__ellipsis::after {
      content: '';
      right: -0.8rem; }
  .product__promo {
    position: relative;
    opacity: 0;
    z-index: 1;
    margin-top: 1.2rem;
    transform: translate3d(0, 10px, 0);
    transition: opacity 0.3s ease, transform 0.3s ease; }
    .product__promo p {
      margin-bottom: 0.2rem;
      font-size: 1rem;
      line-height: normal;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.5);
      letter-spacing: -0.06px;
      line-height: 1.1; }
    .product__promo span {
      font-size: 1rem;
      line-height: normal;
      font-weight: 500;
      color: #FF0069;
      letter-spacing: -0.04px; }
  .product__buttons {
    display: flex;
    position: absolute;
    width: 104%;
    justify-content: space-between;
    left: -0.6rem;
    top: 25%;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.3s ease; }
    .product__buttons button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      transition: all 0.3s ease; }
      .product__buttons button svg {
        width: 1.2rem;
        height: 1.2rem; }
  .product .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center; }

.promo {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 0.8rem; }
  @media only screen and (min-width: 1240px) {
    .promo {
      width: 80.8%; } }
  @media only screen and (min-width: 1440px) {
    .promo {
      width: 67.2%; } }
  @media only screen and (min-width: 1888px) {
    .promo {
      width: 117.6rem; } }
  .promo__item {
    display: block;
    position: relative;
    width: 5.6rem;
    height: 4.8rem;
    margin-bottom: 1.5rem;
    background: #ffffff;
    border-radius: 0.8rem;
    opacity: 0.6;
    overflow: hidden; }
    .promo__item:first-child {
      background: #E7D1F5; }
    .promo__item:nth-child(3) img, .promo__item:nth-child(7) img {
      width: 100%;
      height: 100%; }
    .promo__item img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
  .promo__block {
    width: 100%;
    height: 100%;
    padding: 3rem 1.5rem 3rem 1.5rem; }
    .promo__block_first {
      display: block;
      background: #abd87e; }
    .promo__block_second {
      display: none;
      background: #E7D1F5; }
    .promo__block_third {
      display: none;
      background: green; }
    .promo__block_fourth {
      display: none;
      background: skyblue; }
    .promo__block_fifth {
      display: none;
      background: pink; }
    .promo__block_six {
      display: none;
      background: yellow; }
  .promo__circle {
    position: absolute;
    width: 80.8rem;
    height: 80.8rem;
    z-index: 2;
    border-radius: 50%;
    background: #ffffff; }
    .promo__circle_top-left {
      top: -51.8rem;
      left: -21.6rem; }
    .promo__circle_bottom-left {
      bottom: -45rem;
      left: -19.2rem;
      z-index: 1; }
  .promo__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5.3rem;
    left: 12.2rem;
    right: 0;
    bottom: 0;
    margin: auto; }
    .promo__image_second {
      position: relative;
      top: auto;
      left: auto;
      width: auto; }
    .promo__image_third {
      position: relative;
      top: auto;
      left: auto;
      width: auto; }
    .promo__image_fourth {
      position: relative;
      top: auto;
      left: auto;
      width: auto; }
  .promo__info {
    display: flex;
    position: relative;
    width: 46%;
    z-index: 3; }
    @media only screen and (min-width: 1240px) {
      .promo__info {
        width: 47%; } }
    @media only screen and (min-width: 1440px) {
      .promo__info {
        width: 48%; } }
    @media only screen and (min-width: 1888px) {
      .promo__info {
        width: 39%; } }
    .promo__info_second {
      width: 41%;
      margin: 32.7rem 0 0 5.6rem; }
      @media only screen and (min-width: 1888px) {
        .promo__info_second {
          margin-left: 1.6rem; } }
  .promo__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 0;
    min-width: 100%;
    min-height: 100%; }
  .promo__details {
    position: relative;
    z-index: 3;
    margin-top: 1.1rem; }
  .promo__title {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .promo__text {
    width: 80%;
    margin-top: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    line-height: 1.2;
    color: #666666;
    opacity: 0.8; }
  .promo__company {
    position: absolute;
    bottom: 3.6rem;
    left: 3.2rem; }
    .promo__company_second {
      top: 4rem; }
  .promo__device {
    position: absolute;
    top: 4rem;
    left: 30.6%; }
    @media only screen and (min-width: 1240px) {
      .promo__device {
        left: 29.6%; } }
    @media only screen and (min-width: 1440px) {
      .promo__device {
        left: 27.6%; } }
    @media only screen and (min-width: 1888px) {
      .promo__device {
        left: 33.9%; } }
    .promo__device img:first-child {
      position: absolute;
      top: 28.4rem;
      left: 18.7rem; }
    .promo__device img:last-child {
      position: absolute;
      bottom: 2.2rem;
      left: 8.8%; }
    .promo__device_man {
      left: 46%;
      top: 0; }
      .promo__device_man img:first-child {
        top: 6.5%;
        left: 49%; }
      .promo__device_man img:last-child {
        bottom: 54%;
        left: 5.8%; }
  .promo__inner {
    position: absolute;
    width: 5.6rem;
    height: 20rem;
    right: 1rem;
    top: 20rem;
    z-index: 22; }
  .promo__container {
    height: 100%; }
  .promo__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.2rem;
    height: 43.5%;
    position: absolute;
    right: 3.9rem;
    top: 3.7rem;
    z-index: 22; }
    .promo__buttons .promo__prev,
    .promo__buttons .promo__next {
      width: 2.5rem;
      height: 2.5rem; }
      .promo__buttons .promo__prev svg,
      .promo__buttons .promo__next svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: #ffffff !important; }
  .promo__prev {
    margin-right: 10px;
    transform: rotate(90deg); }
  .promo__next {
    transform: rotate(90deg); }
  .promo .swiper-slide-active {
    opacity: 1; }

.promo {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 0.8rem; }
  @media only screen and (min-width: 1240px) {
    .promo {
      width: 80.8%; } }
  @media only screen and (min-width: 1440px) {
    .promo {
      width: 67.2%; } }
  @media only screen and (min-width: 1888px) {
    .promo {
      width: 117.6rem; } }
  .promo__item {
    display: block;
    position: relative;
    width: 5.6rem;
    height: 4.8rem;
    margin-bottom: 1.5rem;
    background: #ffffff;
    border-radius: 0.8rem;
    opacity: 0.6;
    overflow: hidden; }
    .promo__item:first-child {
      background: #E7D1F5; }
    .promo__item:nth-child(3) img, .promo__item:nth-child(7) img {
      width: 100%;
      height: 100%; }
    .promo__item img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); }
  .promo__block {
    width: 100%;
    height: 100%;
    padding: 3rem 1.5rem 3rem 1.5rem; }
    .promo__block_first {
      display: block;
      background: #abd87e; }
    .promo__block_second {
      display: none;
      background: #E7D1F5; }
    .promo__block_third {
      display: none;
      background: green; }
    .promo__block_fourth {
      display: none;
      background: skyblue; }
    .promo__block_fifth {
      display: none;
      background: pink; }
    .promo__block_six {
      display: none;
      background: yellow; }
  .promo__circle {
    position: absolute;
    width: 80.8rem;
    height: 80.8rem;
    z-index: 2;
    border-radius: 50%;
    background: #ffffff; }
    .promo__circle_top-left {
      top: -51.8rem;
      left: -21.6rem; }
    .promo__circle_bottom-left {
      bottom: -45rem;
      left: -19.2rem;
      z-index: 1; }
  .promo__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5.3rem;
    left: 12.2rem;
    right: 0;
    bottom: 0;
    margin: auto; }
    .promo__image_second {
      position: relative;
      top: auto;
      left: auto;
      width: auto; }
    .promo__image_third {
      position: relative;
      top: auto;
      left: auto;
      width: auto; }
    .promo__image_fourth {
      position: relative;
      top: auto;
      left: auto;
      width: auto; }
  .promo__info {
    display: flex;
    position: relative;
    width: 46%;
    z-index: 3; }
    @media only screen and (min-width: 1240px) {
      .promo__info {
        width: 47%; } }
    @media only screen and (min-width: 1440px) {
      .promo__info {
        width: 48%; } }
    @media only screen and (min-width: 1888px) {
      .promo__info {
        width: 39%; } }
    .promo__info_second {
      width: 41%;
      margin: 32.7rem 0 0 5.6rem; }
      @media only screen and (min-width: 1888px) {
        .promo__info_second {
          margin-left: 1.6rem; } }
  .promo__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 0;
    min-width: 100%;
    min-height: 100%; }
  .promo__details {
    position: relative;
    z-index: 3;
    margin-top: 1.1rem; }
  .promo__title {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .promo__text {
    width: 80%;
    margin-top: 1.4rem;
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    line-height: 1.2;
    color: #666666;
    opacity: 0.8; }
  .promo__company {
    position: absolute;
    bottom: 3.6rem;
    left: 3.2rem; }
    .promo__company_second {
      top: 4rem; }
  .promo__device {
    position: absolute;
    top: 4rem;
    left: 30.6%; }
    @media only screen and (min-width: 1240px) {
      .promo__device {
        left: 29.6%; } }
    @media only screen and (min-width: 1440px) {
      .promo__device {
        left: 27.6%; } }
    @media only screen and (min-width: 1888px) {
      .promo__device {
        left: 33.9%; } }
    .promo__device img:first-child {
      position: absolute;
      top: 28.4rem;
      left: 18.7rem; }
    .promo__device img:last-child {
      position: absolute;
      bottom: 2.2rem;
      left: 8.8%; }
    .promo__device_man {
      left: 46%;
      top: 0; }
      .promo__device_man img:first-child {
        top: 6.5%;
        left: 49%; }
      .promo__device_man img:last-child {
        bottom: 54%;
        left: 5.8%; }
  .promo__inner {
    position: absolute;
    width: 5.6rem;
    height: 20rem;
    right: 1rem;
    top: 20rem;
    z-index: 22; }
  .promo__container {
    height: 100%; }
  .promo__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 1.2rem;
    height: 43.5%;
    position: absolute;
    right: 3.9rem;
    top: 3.7rem;
    z-index: 22; }
    .promo__buttons .promo__prev,
    .promo__buttons .promo__next {
      width: 2.5rem;
      height: 2.5rem; }
      .promo__buttons .promo__prev svg,
      .promo__buttons .promo__next svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: #ffffff !important; }
  .promo__prev {
    margin-right: 10px;
    transform: rotate(90deg); }
  .promo__next {
    transform: rotate(90deg); }
  .promo .swiper-slide-active {
    opacity: 1; }

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important; }

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important; }

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-wrapper.scroll--rtl {
  direction: rtl; }

.scroll-element {
  box-sizing: content-box;
  display: none; }

.scroll-element div {
  box-sizing: content-box; }

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default; }

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block; }

.scroll-textarea {
  border: 1px solid #ccc;
  border-top-color: #999; }

.scroll-textarea > .scroll-content {
  overflow: hidden !important; }

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important; }

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-element,
.scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scroll-element.scroll-x {
  right: 10px;
  left: 10px;
  bottom: 10px;
  height: 4px; }

.scroll-element.scroll-y {
  right: 10px;
  top: 10px;
  bottom: 10px;
  width: 4px; }

.scroll-element .scroll-element_outer {
  overflow: hidden; }

.scroll-element .scroll-element_outer,
.scroll-element .scroll-element_track,
.scroll-element .scroll-bar {
  border-radius: 0; }

.scroll-element .scroll-element_track {
  background-color: rgba(0, 0, 0, 0.05); }

.scroll-element .scroll-bar {
  background-color: #ccc;
  transition: background-color 0.3s ease; }

.scroll-element:hover .scroll-bar {
  background-color: #2252d8;
  cursor: pointer; }

.scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

.scroll-element.scroll-x.scroll-scrolly_visible {
  right: 20px; }

.scroll-element.scroll-y.scroll-scrollx_visible {
  bottom: 20px; }

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: fixed !important; }

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important; }

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-wrapper.scroll--rtl {
  direction: rtl; }

.scroll-element {
  box-sizing: content-box;
  display: none; }

.scroll-element div {
  box-sizing: content-box; }

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default; }

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block; }

.scroll-textarea {
  border: 1px solid #ccc;
  border-top-color: #999; }

.scroll-textarea > .scroll-content {
  overflow: hidden !important; }

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important; }

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-element,
.scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scroll-element.scroll-x {
  right: 10px;
  left: 10px;
  bottom: 10px;
  height: 4px; }

.scroll-element.scroll-y {
  right: 10px;
  top: 10px;
  bottom: 10px;
  width: 4px; }

.scroll-element .scroll-element_outer {
  overflow: hidden; }

.scroll-element .scroll-element_outer,
.scroll-element .scroll-element_track,
.scroll-element .scroll-bar {
  border-radius: 0; }

.scroll-element .scroll-element_track {
  background-color: rgba(0, 0, 0, 0.05); }

.scroll-element .scroll-bar {
  background-color: #ccc;
  transition: background-color 0.3s ease; }

.scroll-element:hover .scroll-bar {
  background-color: #2252d8;
  cursor: pointer; }

.scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

.scroll-element.scroll-x.scroll-scrolly_visible {
  right: 20px; }

.scroll-element.scroll-y.scroll-scrollx_visible {
  bottom: 20px; }

.slider {
  position: relative;
  border: 1px solid #ccc; }
  .slider__slide {
    padding: 100px;
    text-align: center; }
  .slider__dots {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    text-align: center;
    z-index: 2;
    pointer-events: none; }
  .slider__dot {
    margin: 5px;
    width: 10px;
    height: 10px;
    border: 1px solid #ccc;
    border-radius: 100px;
    display: inline-block;
    position: relative;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    pointer-events: all;
    cursor: pointer; }
    .slider__dot.active {
      background-color: #2252d8;
      border-color: #2252d8; }
  .slider__button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    transition: opacity 0.3s ease;
    z-index: 2;
    cursor: pointer;
    background-color: white; }
    .slider__button::before {
      content: '';
      width: 10px;
      height: 10px;
      border-style: solid;
      border-color: black;
      border-width: 0;
      position: absolute;
      transform: rotate(45deg);
      transform-origin: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
    .slider__button:hover {
      opacity: .5; }
    .slider__button_next {
      right: 20px; }
      .slider__button_next::before {
        border-top-width: 2px;
        border-right-width: 2px;
        right: 3px; }
    .slider__button_prev {
      left: 20px; }
      .slider__button_prev::before {
        border-bottom-width: 2px;
        border-left-width: 2px;
        left: 3px; }

.slider {
  position: relative;
  border: 1px solid #ccc; }
  .slider__slide {
    padding: 100px;
    text-align: center; }
  .slider__dots {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    text-align: center;
    z-index: 2;
    pointer-events: none; }
  .slider__dot {
    margin: 5px;
    width: 10px;
    height: 10px;
    border: 1px solid #ccc;
    border-radius: 100px;
    display: inline-block;
    position: relative;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    pointer-events: all;
    cursor: pointer; }
    .slider__dot.active {
      background-color: #2252d8;
      border-color: #2252d8; }
  .slider__button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    transition: opacity 0.3s ease;
    z-index: 2;
    cursor: pointer;
    background-color: white; }
    .slider__button::before {
      content: '';
      width: 10px;
      height: 10px;
      border-style: solid;
      border-color: black;
      border-width: 0;
      position: absolute;
      transform: rotate(45deg);
      transform-origin: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
    .slider__button:hover {
      opacity: .5; }
    .slider__button_next {
      right: 20px; }
      .slider__button_next::before {
        border-top-width: 2px;
        border-right-width: 2px;
        right: 3px; }
    .slider__button_prev {
      left: 20px; }
      .slider__button_prev::before {
        border-bottom-width: 2px;
        border-left-width: 2px;
        left: 3px; }

.user-info {
  position: relative;
  height: 3.6rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  padding-bottom: 3px; }
  .header.is-fixed:not(.is-menu-visible) .user-info {
    transform: translate3d(0, -36px, 0); }
  .user-info__container {
    display: flex;
    justify-content: space-between;
    position: relative; }
  .user-info__list {
    display: flex;
    width: 50%;
    align-items: center; }
    .user-info__list_left {
      justify-content: flex-start; }
    .user-info__list_right {
      justify-content: flex-end; }
  .user-info__item {
    position: relative; }
    .user-info__item:not(:last-child) {
      margin-right: 1.7rem; }
    .user-info__item_phone {
      display: flex;
      align-items: center; }
  .user-info__link {
    color: rgba(0, 0, 0, 0.5);
    fill: rgba(0, 0, 0, 0.5); }
    .user-info__link:hover {
      opacity: 1;
      color: black;
      fill: black; }
    .header.is-menu-visible .user-info__link {
      color: rgba(255, 255, 255, 0.7);
      fill: rgba(255, 255, 255, 0.7); }
      .header.is-menu-visible .user-info__link:hover {
        color: white;
        fill: white; }
    .user-info__link_phone {
      margin-left: 0.9rem;
      color: black; }
      .header.is-menu-visible .user-info__link_phone {
        color: white; }
      .user-info__link_phone:hover {
        color: #FF0069; }
    .user-info__link_city {
      position: relative; }
    .user-info__link_support {
      position: relative; }
  .user-info__icon {
    width: 1.6rem;
    height: 1.6rem;
    position: relative; }
    .user-info__icon svg {
      width: 100%;
      height: 100%;
      stroke: #ccc;
      fill: none;
      transition: stroke 0.3s ease; }
      .header.is-fixed .user-info__icon svg {
        stroke: white; }

.big-height {
  height: 8.4rem;
  opacity: 0; }

.opened::after {
  right: -1.1rem;
  top: 30%;
  transform: rotate(-180deg); }

.user-info {
  position: relative;
  height: 3.6rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease;
  padding-bottom: 3px; }
  .header.is-fixed:not(.is-menu-visible) .user-info {
    transform: translate3d(0, -36px, 0); }
  .user-info__container {
    display: flex;
    justify-content: space-between;
    position: relative; }
  .user-info__list {
    display: flex;
    width: 50%;
    align-items: center; }
    .user-info__list_left {
      justify-content: flex-start; }
    .user-info__list_right {
      justify-content: flex-end; }
  .user-info__item {
    position: relative; }
    .user-info__item:not(:last-child) {
      margin-right: 1.7rem; }
    .user-info__item_phone {
      display: flex;
      align-items: center; }
  .user-info__link {
    color: rgba(0, 0, 0, 0.5);
    fill: rgba(0, 0, 0, 0.5); }
    .user-info__link:hover {
      opacity: 1;
      color: black;
      fill: black; }
    .header.is-menu-visible .user-info__link {
      color: rgba(255, 255, 255, 0.7);
      fill: rgba(255, 255, 255, 0.7); }
      .header.is-menu-visible .user-info__link:hover {
        color: white;
        fill: white; }
    .user-info__link_phone {
      margin-left: 0.9rem;
      color: black; }
      .header.is-menu-visible .user-info__link_phone {
        color: white; }
      .user-info__link_phone:hover {
        color: #FF0069; }
    .user-info__link_city {
      position: relative; }
    .user-info__link_support {
      position: relative; }
  .user-info__icon {
    width: 1.6rem;
    height: 1.6rem;
    position: relative; }
    .user-info__icon svg {
      width: 100%;
      height: 100%;
      stroke: #ccc;
      fill: none;
      transition: stroke 0.3s ease; }
      .header.is-fixed .user-info__icon svg {
        stroke: white; }

.big-height {
  height: 8.4rem;
  opacity: 0; }

.opened::after {
  right: -1.1rem;
  top: 30%;
  transform: rotate(-180deg); }

.catalog {
  display: none;
  position: fixed;
  height: 78vh;
  top: 3.6rem;
  left: 1.6rem;
  right: 1.6rem;
  border-radius: 0.8rem;
  background: white;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translate3d(0, 1.5rem, 0);
  box-sizing: border-box;
  padding-top: 5.3rem; }
  .catalog .js-scrollbar {
    height: calc(100% - 5.3rem);
    width: 100%; }
  .catalog.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .catalog__container {
    display: flex;
    padding: 0 0 4rem 4rem;
    margin-top: 4.4rem; }
    @media only screen and (min-width: 1920px) {
      .catalog__container {
        padding-left: 5.6rem; } }
  .catalog__brands, .catalog__notebooks {
    width: 100%; }
  .catalog__header {
    height: 6rem;
    margin-bottom: 3.8rem;
    padding-right: 4rem;
    width: 100%; }
  .catalog__col-left {
    width: 19.7rem;
    padding-right: 3.5rem;
    position: relative;
    transition: opacity 0.3s ease;
    opacity: 0; }
    .catalog.is-active .catalog__col-left {
      opacity: 1;
      transition-delay: .2s; }
  .catalog__col-right {
    flex: 1;
    display: flex;
    flex-flow: column wrap;
    overflow: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translate3d(0, 1.5rem, 0);
    opacity: 0; }
    .catalog.is-active .catalog__col-right {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition-delay: .25s; }
    .catalog__col-right > * {
      order: 0; }
  .catalog__wrapper {
    padding-top: .5rem; }
  .catalog__list:not(:last-child) {
    margin-bottom: 3.1rem; }
  .catalog__title {
    margin-bottom: 0.9rem;
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5); }
  .catalog__item:not(:last-child) {
    margin-bottom: 0.43rem; }
  .catalog__link {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    color: #6699FF;
    transition: color 0.3s ease; }
    .catalog__link:hover {
      color: #FF0069; }
  .catalog__brands .goods__catalog, .catalog__notebooks .goods__catalog {
    justify-content: space-between; }
  .catalog__brands .card-small, .catalog__notebooks .card-small {
    width: 40rem;
    height: 21.6rem;
    margin-right: 1.6rem;
    flex-shrink: 0; }
  .catalog__brands .goods__list, .catalog__notebooks .goods__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: 3rem;
    margin-top: -.2rem; }
  .catalog__brands .goods__item, .catalog__notebooks .goods__item {
    width: 33.3333%;
    box-sizing: border-box;
    padding: 1rem 2rem 0;
    margin-right: 0; }
    @media only screen and (min-width: 1240px) {
      .catalog__brands .goods__item, .catalog__notebooks .goods__item {
        width: 25%; } }
    @media only screen and (min-width: 1888px) {
      .catalog__brands .goods__item, .catalog__notebooks .goods__item {
        width: 20%; } }
  .catalog__brands .goods__link, .catalog__notebooks .goods__link {
    color: #000000;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .catalog__brands .goods__brands, .catalog__notebooks .goods__brands {
    display: flex;
    position: relative;
    padding-bottom: 5rem;
    padding-right: 4rem; }
    .catalog__brands .goods__brands::after, .catalog__notebooks .goods__brands::after {
      content: "";
      display: block;
      position: absolute;
      width: 98%;
      height: 1px;
      bottom: 0;
      background: #e6e6e6; }
  .catalog__notebooks .goods__brands {
    padding-right: 11.6rem; }
  .catalog__notebooks .goods__item {
    width: 50%; }
    @media only screen and (min-width: 1240px) {
      .catalog__notebooks .goods__item {
        width: 33.333%; } }
    @media only screen and (min-width: 1888px) {
      .catalog__notebooks .goods__item {
        width: 25%; } }
  .catalog__notebooks .goods__link {
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: #000000; }
  .catalog .goods:not(:last-child) {
    margin-bottom: 5rem; }
  .catalog__active {
    order: -1; }
  .catalog .goods__header {
    padding: 0 4rem 0 0;
    margin: 0;
    align-items: center; }
    @media only screen and (min-width: 1440px) {
      .catalog .goods__header {
        margin-bottom: 2.5rem; } }
  .catalog .goods__title {
    width: 50rem;
    flex-grow: 1;
    padding-right: 40px; }
  .catalog .goods__controlers {
    flex-shrink: 0;
    margin-top: .9rem; }
  .catalog .goods__content {
    padding-bottom: 2rem; }

.scroll-element.scroll-y {
  z-index: 3333; }

.catalog {
  display: none;
  position: fixed;
  height: 78vh;
  top: 3.6rem;
  left: 1.6rem;
  right: 1.6rem;
  border-radius: 0.8rem;
  background: white;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translate3d(0, 1.5rem, 0);
  box-sizing: border-box;
  padding-top: 5.3rem; }
  .catalog .js-scrollbar {
    height: calc(100% - 5.3rem);
    width: 100%; }
  .catalog.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .catalog__container {
    display: flex;
    padding: 0 0 4rem 4rem;
    margin-top: 4.4rem; }
    @media only screen and (min-width: 1920px) {
      .catalog__container {
        padding-left: 5.6rem; } }
  .catalog__brands, .catalog__notebooks {
    width: 100%; }
  .catalog__header {
    height: 6rem;
    margin-bottom: 3.8rem;
    padding-right: 4rem;
    width: 100%; }
  .catalog__col-left {
    width: 19.7rem;
    padding-right: 3.5rem;
    position: relative;
    transition: opacity 0.3s ease;
    opacity: 0; }
    .catalog.is-active .catalog__col-left {
      opacity: 1;
      transition-delay: .2s; }
  .catalog__col-right {
    flex: 1;
    display: flex;
    flex-flow: column wrap;
    overflow: hidden;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translate3d(0, 1.5rem, 0);
    opacity: 0; }
    .catalog.is-active .catalog__col-right {
      transform: translate3d(0, 0, 0);
      opacity: 1;
      transition-delay: .25s; }
    .catalog__col-right > * {
      order: 0; }
  .catalog__wrapper {
    padding-top: .5rem; }
  .catalog__list:not(:last-child) {
    margin-bottom: 3.1rem; }
  .catalog__title {
    margin-bottom: 0.9rem;
    font-size: 1.4rem;
    line-height: normal;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5); }
  .catalog__item:not(:last-child) {
    margin-bottom: 0.43rem; }
  .catalog__link {
    font-size: 1.6rem;
    line-height: 1.18;
    font-weight: 400;
    color: #6699FF;
    transition: color 0.3s ease; }
    .catalog__link:hover {
      color: #FF0069; }
  .catalog__brands .goods__catalog, .catalog__notebooks .goods__catalog {
    justify-content: space-between; }
  .catalog__brands .card-small, .catalog__notebooks .card-small {
    width: 40rem;
    height: 21.6rem;
    margin-right: 1.6rem;
    flex-shrink: 0; }
  .catalog__brands .goods__list, .catalog__notebooks .goods__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -2rem;
    margin-right: 3rem;
    margin-top: -.2rem; }
  .catalog__brands .goods__item, .catalog__notebooks .goods__item {
    width: 33.3333%;
    box-sizing: border-box;
    padding: 1rem 2rem 0;
    margin-right: 0; }
    @media only screen and (min-width: 1240px) {
      .catalog__brands .goods__item, .catalog__notebooks .goods__item {
        width: 25%; } }
    @media only screen and (min-width: 1888px) {
      .catalog__brands .goods__item, .catalog__notebooks .goods__item {
        width: 20%; } }
  .catalog__brands .goods__link, .catalog__notebooks .goods__link {
    color: #000000;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
  .catalog__brands .goods__brands, .catalog__notebooks .goods__brands {
    display: flex;
    position: relative;
    padding-bottom: 5rem;
    padding-right: 4rem; }
    .catalog__brands .goods__brands::after, .catalog__notebooks .goods__brands::after {
      content: "";
      display: block;
      position: absolute;
      width: 98%;
      height: 1px;
      bottom: 0;
      background: #e6e6e6; }
  .catalog__notebooks .goods__brands {
    padding-right: 11.6rem; }
  .catalog__notebooks .goods__item {
    width: 50%; }
    @media only screen and (min-width: 1240px) {
      .catalog__notebooks .goods__item {
        width: 33.333%; } }
    @media only screen and (min-width: 1888px) {
      .catalog__notebooks .goods__item {
        width: 25%; } }
  .catalog__notebooks .goods__link {
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: #000000; }
  .catalog .goods:not(:last-child) {
    margin-bottom: 5rem; }
  .catalog__active {
    order: -1; }
  .catalog .goods__header {
    padding: 0 4rem 0 0;
    margin: 0;
    align-items: center; }
    @media only screen and (min-width: 1440px) {
      .catalog .goods__header {
        margin-bottom: 2.5rem; } }
  .catalog .goods__title {
    width: 50rem;
    flex-grow: 1;
    padding-right: 40px; }
  .catalog .goods__controlers {
    flex-shrink: 0;
    margin-top: .9rem; }
  .catalog .goods__content {
    padding-bottom: 2rem; }

.scroll-element.scroll-y {
  z-index: 3333; }

.collection {
  display: none;
  position: relative;
  padding-top: 1.7rem;
  border-radius: 0.8rem;
  background: #ffffff; }
  .collection__title {
    opacity: 0;
    margin: 0 0 0.4rem 1.6rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .collection__title_white {
      opacity: 1;
      margin-left: -1px;
      color: #ffffff; }
    @media only screen and (min-width: 1440px) {
      .collection__title {
        opacity: 1; } }
  .collection__subtitle {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .collection__subtitle_white {
      color: #ffffff; }
  .collection__products {
    display: flex; }
    @media only screen and (min-width: 1440px) {
      .collection__products .product:nth-child(2) {
        display: block; } }
  .collection__wrapper {
    position: relative; }
    .collection__wrapper::after {
      content: "";
      display: block;
      position: absolute;
      height: 80%;
      width: 2px;
      top: 0;
      right: 0;
      z-index: 22;
      background: #ffffff; }
  .collection__navigation {
    position: relative;
    width: 100%;
    height: 20rem;
    margin-top: -5.3rem;
    padding: 1.7rem 1.6rem 1rem 1.6rem;
    border-radius: 0.8rem; }
    .collection__navigation_ladder::before {
      content: "";
      display: block;
      position: absolute;
      width: 89%;
      height: 2rem;
      bottom: -0.8rem;
      left: 1.2rem;
      border-radius: 0.8rem;
      z-index: 0;
      background: rgba(255, 0, 105, 0.5); }
    .collection__navigation_ladder::after {
      content: "";
      display: block;
      position: absolute;
      width: 80%;
      height: 2rem;
      bottom: -1.6rem;
      left: 2.2rem;
      border-radius: 0.8rem;
      z-index: 0;
      background: rgba(255, 0, 105, 0.2); }
    @media only screen and (min-width: 1440px) {
      .collection__navigation_ladder::before {
        left: 2.4rem; }
      .collection__navigation_ladder::after {
        left: 4.4rem; } }
    @media only screen and (min-width: 1888px) {
      .collection__navigation_ladder::before {
        width: 93%; }
      .collection__navigation_ladder::after {
        width: 86%; } }
  .collection__inner {
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem; }
  .collection__item {
    width: 19.2rem;
    margin-bottom: 0.54rem; }
  .collection__link {
    color: #ffffff;
    line-height: 1.2;
    transition: all 0.3s ease; }
    .collection__link:hover {
      opacity: 0.5; }
  .collection__controlers {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1.3rem;
    left: 50%; }
    .collection__controlers button {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.2rem;
      border-radius: 50%;
      background: #ffffff; }
  .collection__buttons {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 4.4rem;
    top: 2.3rem;
    right: 2.1rem;
    z-index: 1; }
  .collection__prev {
    width: 1.2rem;
    height: 1.2rem; }
    .collection__prev:hover svg, .collection__prev:active svg {
      fill: #6699FF; }
    .collection__prev svg {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      fill: rgba(0, 0, 0, 0.2); }
  .collection__next {
    width: 1.2rem;
    height: 1.2rem; }
    .collection__next:hover svg, .collection__next:active svg {
      fill: #6699FF; }
    .collection__next svg {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      fill: rgba(0, 0, 0, 0.2); }
  .collection__bottom {
    position: relative;
    border-radius: 0.8rem;
    background: #FF0069; }
  .collection__btn {
    display: inline-block;
    position: relative;
    z-index: 22;
    align-self: center;
    width: 16rem;
    margin-top: 1rem auto 0.3rem auto;
    padding: 0 4rem 0 4rem;
    color: #ffffff;
    border-radius: 1.6rem;
    font-size: 14px;
    line-height: 1.65;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 1.45;
    transition: all 0.3s ease; }
    .collection__btn:hover {
      opacity: 0.5; }

.swiper-pagination-bullet {
  width: 0.4rem;
  height: 0.4rem;
  background: #ffffff;
  opacity: 1; }

.swiper-pagination-bullet-active {
  width: 0.6rem;
  height: 0.6rem; }

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
  position: relative;
  background: none;
  fill: rgba(0, 0, 0, 0.2);
  transition: fill 0.3s ease, opacity 0.3s ease;
  width: 1.2rem;
  height: 1.2rem;
  left: auto;
  right: auto; }
  .swiper-button-next:hover, .swiper-button-next:active,
  .swiper-button-prev:hover,
  .swiper-button-prev:active {
    fill: #6699FF; }
  .swiper-button-next svg,
  .swiper-button-prev svg {
    width: 100%;
    height: 100%;
    fill: inherit; }

.swiper-wrapper {
  box-sizing: border-box; }

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1.4rem; }

.swiper-group-ten > .swiper-wrapper > .swiper-slide,
.swiper-group-eight > .swiper-wrapper > .swiper-slide,
.swiper-group-twelve > .swiper-wrapper > .swiper-slide,
.swiper-group-eleven > .swiper-wrapper > .swiper-slide,
.swiper-group-thirteen > .swiper-wrapper > .swiper-slide {
  transition: opacity 0.3s ease;
  opacity: 0; }

.swiper-group-ten > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-eight > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-twelve > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-eleven > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-thirteen > .swiper-wrapper > .swiper-slide-visible {
  opacity: 1; }

.collection {
  display: none;
  position: relative;
  padding-top: 1.7rem;
  border-radius: 0.8rem;
  background: #ffffff; }
  .collection__title {
    opacity: 0;
    margin: 0 0 0.4rem 1.6rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .collection__title_white {
      opacity: 1;
      margin-left: -1px;
      color: #ffffff; }
    @media only screen and (min-width: 1440px) {
      .collection__title {
        opacity: 1; } }
  .collection__subtitle {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .collection__subtitle_white {
      color: #ffffff; }
  .collection__products {
    display: flex; }
    @media only screen and (min-width: 1440px) {
      .collection__products .product:nth-child(2) {
        display: block; } }
  .collection__wrapper {
    position: relative; }
    .collection__wrapper::after {
      content: "";
      display: block;
      position: absolute;
      height: 80%;
      width: 2px;
      top: 0;
      right: 0;
      z-index: 22;
      background: #ffffff; }
  .collection__navigation {
    position: relative;
    width: 100%;
    height: 20rem;
    margin-top: -5.3rem;
    padding: 1.7rem 1.6rem 1rem 1.6rem;
    border-radius: 0.8rem; }
    .collection__navigation_ladder::before {
      content: "";
      display: block;
      position: absolute;
      width: 89%;
      height: 2rem;
      bottom: -0.8rem;
      left: 1.2rem;
      border-radius: 0.8rem;
      z-index: 0;
      background: rgba(255, 0, 105, 0.5); }
    .collection__navigation_ladder::after {
      content: "";
      display: block;
      position: absolute;
      width: 80%;
      height: 2rem;
      bottom: -1.6rem;
      left: 2.2rem;
      border-radius: 0.8rem;
      z-index: 0;
      background: rgba(255, 0, 105, 0.2); }
    @media only screen and (min-width: 1440px) {
      .collection__navigation_ladder::before {
        left: 2.4rem; }
      .collection__navigation_ladder::after {
        left: 4.4rem; } }
    @media only screen and (min-width: 1888px) {
      .collection__navigation_ladder::before {
        width: 93%; }
      .collection__navigation_ladder::after {
        width: 86%; } }
  .collection__inner {
    display: flex;
    justify-content: space-between;
    margin-top: 1.3rem; }
  .collection__item {
    width: 19.2rem;
    margin-bottom: 0.54rem; }
  .collection__link {
    color: #ffffff;
    line-height: 1.2;
    transition: all 0.3s ease; }
    .collection__link:hover {
      opacity: 0.5; }
  .collection__controlers {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1.3rem;
    left: 50%; }
    .collection__controlers button {
      width: 0.6rem;
      height: 0.6rem;
      margin-right: 0.2rem;
      border-radius: 50%;
      background: #ffffff; }
  .collection__buttons {
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 4.4rem;
    top: 2.3rem;
    right: 2.1rem;
    z-index: 1; }
  .collection__prev {
    width: 1.2rem;
    height: 1.2rem; }
    .collection__prev:hover svg, .collection__prev:active svg {
      fill: #6699FF; }
    .collection__prev svg {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      fill: rgba(0, 0, 0, 0.2); }
  .collection__next {
    width: 1.2rem;
    height: 1.2rem; }
    .collection__next:hover svg, .collection__next:active svg {
      fill: #6699FF; }
    .collection__next svg {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      fill: rgba(0, 0, 0, 0.2); }
  .collection__bottom {
    position: relative;
    border-radius: 0.8rem;
    background: #FF0069; }
  .collection__btn {
    display: inline-block;
    position: relative;
    z-index: 22;
    align-self: center;
    width: 16rem;
    margin-top: 1rem auto 0.3rem auto;
    padding: 0 4rem 0 4rem;
    color: #ffffff;
    border-radius: 1.6rem;
    font-size: 14px;
    line-height: 1.65;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.5);
    text-align: center;
    line-height: 1.45;
    transition: all 0.3s ease; }
    .collection__btn:hover {
      opacity: 0.5; }

.swiper-pagination-bullet {
  width: 0.4rem;
  height: 0.4rem;
  background: #ffffff;
  opacity: 1; }

.swiper-pagination-bullet-active {
  width: 0.6rem;
  height: 0.6rem; }

.swiper-button-next,
.swiper-button-prev {
  margin-top: 0;
  position: relative;
  background: none;
  fill: rgba(0, 0, 0, 0.2);
  transition: fill 0.3s ease, opacity 0.3s ease;
  width: 1.2rem;
  height: 1.2rem;
  left: auto;
  right: auto; }
  .swiper-button-next:hover, .swiper-button-next:active,
  .swiper-button-prev:hover,
  .swiper-button-prev:active {
    fill: #6699FF; }
  .swiper-button-next svg,
  .swiper-button-prev svg {
    width: 100%;
    height: 100%;
    fill: inherit; }

.swiper-wrapper {
  box-sizing: border-box; }

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1.4rem; }

.swiper-group-ten > .swiper-wrapper > .swiper-slide,
.swiper-group-eight > .swiper-wrapper > .swiper-slide,
.swiper-group-twelve > .swiper-wrapper > .swiper-slide,
.swiper-group-eleven > .swiper-wrapper > .swiper-slide,
.swiper-group-thirteen > .swiper-wrapper > .swiper-slide {
  transition: opacity 0.3s ease;
  opacity: 0; }

.swiper-group-ten > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-eight > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-twelve > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-eleven > .swiper-wrapper > .swiper-slide-visible,
.swiper-group-thirteen > .swiper-wrapper > .swiper-slide-visible {
  opacity: 1; }

.footer {
  position: relative;
  margin-top: 10.4rem;
  padding-bottom: 5.6rem;
  background: linear-gradient(to bottom, #eee 0, #fff 35rem); }
  @media only screen and (min-width: 1240px) {
    .footer {
      margin-top: 11.4rem; } }
  @media only screen and (min-width: 1888px) {
    .footer {
      margin-top: 11.4rem; } }
  .footer .user-info__list {
    width: auto;
    margin: -0.3rem 1.4rem 0 0; }
  .footer .user-info__item_phone {
    display: flex; }
  .footer .user-info__link_phone span {
    color: #6699FF; }
  .footer__content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.2rem; }
    @media only screen and (min-width: 1440px) {
      .footer__content {
        padding-right: 20rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__content {
        padding-right: 50rem; } }
  .footer__navigation {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1026px) {
      .footer__navigation {
        justify-content: space-between; } }
    @media only screen and (min-width: 1240px) {
      .footer__navigation {
        width: 66%;
        justify-content: flex-start; } }
    @media only screen and (min-width: 1888px) {
      .footer__navigation {
        width: 100%; } }
  .footer__user-block {
    display: flex;
    margin-top: -0.6rem; }
    @media only screen and (min-width: 1240px) {
      .footer__user-block {
        flex-direction: column;
        width: 34%;
        padding-top: 4.1rem; } }
    @media only screen and (min-width: 1440px) {
      .footer__user-block {
        width: 31.4%;
        padding-top: 4.4rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__user-block {
        width: 100%;
        flex-direction: row;
        padding-top: 0; } }
  .footer__col-left {
    width: 45.7%; }
    @media only screen and (min-width: 1240px) {
      .footer__col-left {
        width: 100%;
        margin-bottom: 7rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__col-left {
        width: 23.5%; } }
  .footer__col-right {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
    margin-left: auto; }
    .footer__col-right .footer__title {
      width: 100%;
      margin-right: 0;
      max-width: none;
      margin-bottom: 2.2rem; }
      @media only screen and (min-width: 1240px) {
        .footer__col-right .footer__title {
          margin-top: 12.5rem; } }
      @media only screen and (min-width: 1888px) {
        .footer__col-right .footer__title {
          margin-top: 0; } }
    @media only screen and (min-width: 1240px) {
      .footer__col-right {
        width: 100%;
        align-items: flex-start; } }
    @media only screen and (min-width: 1888px) {
      .footer__col-right {
        width: 50%;
        flex-grow: 0;
        margin-left: 10.7%; } }
    .footer__col-right .socials {
      display: none; }
      @media only screen and (min-width: 1240px) {
        .footer__col-right .socials {
          display: flex; } }
      @media only screen and (min-width: 1888px) {
        .footer__col-right .socials {
          display: none; } }
  .footer__subscribe {
    position: relative;
    margin-bottom: 1.4rem; }
    .footer__subscribe input {
      background: rgba(0, 0, 0, 0.05); }
  .footer__btn {
    position: absolute;
    width: 1.4rem;
    height: 1.2rem;
    margin: 0.2rem -0.7rem 0 0;
    right: 1.6rem;
    top: 1.2rem;
    background: url("../images/subscribe.svg") no-repeat;
    transition: all 0.3s ease;
    opacity: 0.5; }
  .footer__title {
    max-width: 25rem;
    margin-bottom: 2.3rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .footer__title_margin {
      margin-bottom: 2.5rem; }
  .footer__list {
    width: 19.5rem;
    margin-bottom: 6.8rem;
    margin-right: 11.9%; }
    @media (min-width: 1026px) {
      .footer__list {
        width: 30rem;
        margin-right: 0; } }
    .footer__list svg {
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke: #ccc; }
    @media only screen and (min-width: 1240px) {
      .footer__list {
        margin-right: 7%;
        width: 19.5rem; } }
    @media only screen and (min-width: 1440px) {
      .footer__list {
        margin-right: 12.4%; } }
    @media only screen and (min-width: 1888px) {
      .footer__list {
        margin-right: 6.1%; } }
    .footer__list:last-child {
      margin-right: 0; }
    @media only screen and (min-width: 1240px) {
      .footer__list_services {
        margin-right: 0; } }
    @media only screen and (min-width: 1888px) {
      .footer__list_services {
        margin-right: 6.2%; } }
    @media only screen and (min-width: 1888px) {
      .footer__list_business {
        margin-right: 8.9%; } }
  .footer__text {
    font-weight: 500;
    letter-spacing: -0.1px; }
  .footer__icon {
    margin-bottom: 2.3rem; }
  .footer__item {
    margin-bottom: 0.96rem; }
  .footer__link {
    font-size: 2rem;
    color: #6699FF;
    transition: all 0.3s ease; }
    .footer__link:hover {
      opacity: 0.5; }
    .footer__link_triangle:hover {
      opacity: 1; }
    .footer__link_triangle svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: #ccc;
      transition: all 0.3s ease; }
  .footer__info {
    max-width: 30rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
    .footer__info a {
      color: #6699FF;
      transition: all 0.3s ease; }
      .footer__info a:hover {
        opacity: 0.5; }
  .footer__about {
    width: 50%;
    margin-top: 9.5rem; }
    @media only screen and (min-width: 1240px) {
      .footer__about {
        width: 51%; } }
    @media only screen and (min-width: 1440px) {
      .footer__about {
        margin-top: 9.7rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__about {
        margin-top: 9.2rem; } }
    .footer__about .socials {
      display: flex;
      margin: 4.2rem 0 0 0; }
      @media only screen and (min-width: 1240px) {
        .footer__about .socials {
          display: none; } }
  .footer__city {
    margin-bottom: 2.3rem;
    margin-top: -2.3rem;
    position: relative; }
  .footer__city-text {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    position: relative;
    color: #6699FF;
    transition: opacity 0.3s ease; }
    .footer__city-text:hover {
      opacity: 0.5;
      cursor: pointer; }
    .footer__city-text::after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: -2.1rem;
      top: 55%;
      transform: translateY(-50%);
      background: url("../images/location-pin.svg") no-repeat;
      background-position: 100%; }
  .footer__company {
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
  .footer__descr {
    max-width: 43rem;
    line-height: 1.2;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
    @media only screen and (min-width: 1240px) {
      .footer__descr {
        max-width: 60rem; } }
  .footer__creator {
    display: flex;
    align-items: center;
    margin-left: 16.7%;
    margin-top: 11.5rem; }
    .footer__creator svg {
      width: 4rem;
      height: 3rem;
      margin-right: 1.3rem;
      fill: #6699FF; }
    @media only screen and (min-width: 1240px) {
      .footer__creator {
        margin-top: 11rem;
        margin-left: 15%; } }
    @media only screen and (min-width: 1440px) {
      .footer__creator {
        display: flex;
        margin-top: 11.2rem;
        margin-left: 17.6%; } }
    @media only screen and (min-width: 1888px) {
      .footer__creator {
        margin: 10.6rem 0 0 0.7rem; } }
    .footer__creator b {
      display: block;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6;
      color: #000000; }
    .footer__creator span {
      display: block;
      margin-top: -0.4rem;
      transition: color 0.3s ease;
      color: #6699FF; }
      .footer__creator span:hover {
        opacity: 0.5; }
  .footer__contacts {
    max-width: 31rem;
    margin-right: -0.6rem; }
  .footer__adress {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px; }
  .footer__phones {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-right: 0.9rem;
    width: 29.5rem; }
    @media only screen and (min-width: 1240px) {
      .footer__phones {
        width: 31.5rem; } }
  .footer__phone {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777;
    color: rgba(0, 0, 0, 0.4); }
  .footer__tel {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000000; }
  .footer__authors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.1rem; }
    @media (min-width: 1025px) {
      .footer__authors {
        justify-content: space-between; } }
    @media only screen and (min-width: 1240px) {
      .footer__authors {
        width: 100%;
        margin-top: -7rem;
        justify-content: flex-start; } }
    @media only screen and (min-width: 1888px) {
      .footer__authors {
        margin-top: 0; } }
    @media only screen and (min-width: 1240px) {
      .footer__authors .socials {
        display: none; } }
    @media only screen and (min-width: 1888px) {
      .footer__authors .socials {
        display: flex; } }
  .footer input:valid ~ button {
    opacity: 1; }
  .footer input:invalid {
    border: 1px solid red; }
  .footer .modal-city__input:invalid {
    border: transparent; }

.socials {
  display: flex;
  width: 100%; }
  .socials__item {
    width: 2.4rem;
    height: 2.4rem; }
    .socials__item:not(:last-child) {
      margin-right: 2.4rem; }
  .socials__link:hover svg {
    fill: #6699FF; }
  .socials__link svg {
    width: 100%;
    height: 100%;
    fill: #cccccc;
    transition: all 0.3s ease; }

.footer {
  position: relative;
  margin-top: 10.4rem;
  padding-bottom: 5.6rem;
  background: linear-gradient(to bottom, #eee 0, #fff 35rem); }
  @media only screen and (min-width: 1240px) {
    .footer {
      margin-top: 11.4rem; } }
  @media only screen and (min-width: 1888px) {
    .footer {
      margin-top: 11.4rem; } }
  .footer .user-info__list {
    width: auto;
    margin: -0.3rem 1.4rem 0 0; }
  .footer .user-info__item_phone {
    display: flex; }
  .footer .user-info__link_phone span {
    color: #6699FF; }
  .footer__content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6.2rem; }
    @media only screen and (min-width: 1440px) {
      .footer__content {
        padding-right: 20rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__content {
        padding-right: 50rem; } }
  .footer__navigation {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1026px) {
      .footer__navigation {
        justify-content: space-between; } }
    @media only screen and (min-width: 1240px) {
      .footer__navigation {
        width: 66%;
        justify-content: flex-start; } }
    @media only screen and (min-width: 1888px) {
      .footer__navigation {
        width: 100%; } }
  .footer__user-block {
    display: flex;
    margin-top: -0.6rem; }
    @media only screen and (min-width: 1240px) {
      .footer__user-block {
        flex-direction: column;
        width: 34%;
        padding-top: 4.1rem; } }
    @media only screen and (min-width: 1440px) {
      .footer__user-block {
        width: 31.4%;
        padding-top: 4.4rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__user-block {
        width: 100%;
        flex-direction: row;
        padding-top: 0; } }
  .footer__col-left {
    width: 45.7%; }
    @media only screen and (min-width: 1240px) {
      .footer__col-left {
        width: 100%;
        margin-bottom: 7rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__col-left {
        width: 23.5%; } }
  .footer__col-right {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    width: 30rem;
    margin-left: auto; }
    .footer__col-right .footer__title {
      width: 100%;
      margin-right: 0;
      max-width: none;
      margin-bottom: 2.2rem; }
      @media only screen and (min-width: 1240px) {
        .footer__col-right .footer__title {
          margin-top: 12.5rem; } }
      @media only screen and (min-width: 1888px) {
        .footer__col-right .footer__title {
          margin-top: 0; } }
    @media only screen and (min-width: 1240px) {
      .footer__col-right {
        width: 100%;
        align-items: flex-start; } }
    @media only screen and (min-width: 1888px) {
      .footer__col-right {
        width: 50%;
        flex-grow: 0;
        margin-left: 10.7%; } }
    .footer__col-right .socials {
      display: none; }
      @media only screen and (min-width: 1240px) {
        .footer__col-right .socials {
          display: flex; } }
      @media only screen and (min-width: 1888px) {
        .footer__col-right .socials {
          display: none; } }
  .footer__subscribe {
    position: relative;
    margin-bottom: 1.4rem; }
    .footer__subscribe input {
      background: rgba(0, 0, 0, 0.05); }
  .footer__btn {
    position: absolute;
    width: 1.4rem;
    height: 1.2rem;
    margin: 0.2rem -0.7rem 0 0;
    right: 1.6rem;
    top: 1.2rem;
    background: url("../images/subscribe.svg") no-repeat;
    transition: all 0.3s ease;
    opacity: 0.5; }
  .footer__title {
    max-width: 25rem;
    margin-bottom: 2.3rem;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .footer__title_margin {
      margin-bottom: 2.5rem; }
  .footer__list {
    width: 19.5rem;
    margin-bottom: 6.8rem;
    margin-right: 11.9%; }
    @media (min-width: 1026px) {
      .footer__list {
        width: 30rem;
        margin-right: 0; } }
    .footer__list svg {
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke: #ccc; }
    @media only screen and (min-width: 1240px) {
      .footer__list {
        margin-right: 7%;
        width: 19.5rem; } }
    @media only screen and (min-width: 1440px) {
      .footer__list {
        margin-right: 12.4%; } }
    @media only screen and (min-width: 1888px) {
      .footer__list {
        margin-right: 6.1%; } }
    .footer__list:last-child {
      margin-right: 0; }
    @media only screen and (min-width: 1240px) {
      .footer__list_services {
        margin-right: 0; } }
    @media only screen and (min-width: 1888px) {
      .footer__list_services {
        margin-right: 6.2%; } }
    @media only screen and (min-width: 1888px) {
      .footer__list_business {
        margin-right: 8.9%; } }
  .footer__text {
    font-weight: 500;
    letter-spacing: -0.1px; }
  .footer__icon {
    margin-bottom: 2.3rem; }
  .footer__item {
    margin-bottom: 0.96rem; }
  .footer__link {
    font-size: 2rem;
    color: #6699FF;
    transition: all 0.3s ease; }
    .footer__link:hover {
      opacity: 0.5; }
    .footer__link_triangle:hover {
      opacity: 1; }
    .footer__link_triangle svg {
      width: 1.2rem;
      height: 1.2rem;
      fill: #ccc;
      transition: all 0.3s ease; }
  .footer__info {
    max-width: 30rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
    .footer__info a {
      color: #6699FF;
      transition: all 0.3s ease; }
      .footer__info a:hover {
        opacity: 0.5; }
  .footer__about {
    width: 50%;
    margin-top: 9.5rem; }
    @media only screen and (min-width: 1240px) {
      .footer__about {
        width: 51%; } }
    @media only screen and (min-width: 1440px) {
      .footer__about {
        margin-top: 9.7rem; } }
    @media only screen and (min-width: 1888px) {
      .footer__about {
        margin-top: 9.2rem; } }
    .footer__about .socials {
      display: flex;
      margin: 4.2rem 0 0 0; }
      @media only screen and (min-width: 1240px) {
        .footer__about .socials {
          display: none; } }
  .footer__city {
    margin-bottom: 2.3rem;
    margin-top: -2.3rem;
    position: relative; }
  .footer__city-text {
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    position: relative;
    color: #6699FF;
    transition: opacity 0.3s ease; }
    .footer__city-text:hover {
      opacity: 0.5;
      cursor: pointer; }
    .footer__city-text::after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: -2.1rem;
      top: 55%;
      transform: translateY(-50%);
      background: url("../images/location-pin.svg") no-repeat;
      background-position: 100%; }
  .footer__company {
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
  .footer__descr {
    max-width: 43rem;
    line-height: 1.2;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
    @media only screen and (min-width: 1240px) {
      .footer__descr {
        max-width: 60rem; } }
  .footer__creator {
    display: flex;
    align-items: center;
    margin-left: 16.7%;
    margin-top: 11.5rem; }
    .footer__creator svg {
      width: 4rem;
      height: 3rem;
      margin-right: 1.3rem;
      fill: #6699FF; }
    @media only screen and (min-width: 1240px) {
      .footer__creator {
        margin-top: 11rem;
        margin-left: 15%; } }
    @media only screen and (min-width: 1440px) {
      .footer__creator {
        display: flex;
        margin-top: 11.2rem;
        margin-left: 17.6%; } }
    @media only screen and (min-width: 1888px) {
      .footer__creator {
        margin: 10.6rem 0 0 0.7rem; } }
    .footer__creator b {
      display: block;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.6;
      color: #000000; }
    .footer__creator span {
      display: block;
      margin-top: -0.4rem;
      transition: color 0.3s ease;
      color: #6699FF; }
      .footer__creator span:hover {
        opacity: 0.5; }
  .footer__contacts {
    max-width: 31rem;
    margin-right: -0.6rem; }
  .footer__adress {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px; }
  .footer__phones {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-right: 0.9rem;
    width: 29.5rem; }
    @media only screen and (min-width: 1240px) {
      .footer__phones {
        width: 31.5rem; } }
  .footer__phone {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777;
    color: rgba(0, 0, 0, 0.4); }
  .footer__tel {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000000; }
  .footer__authors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.1rem; }
    @media (min-width: 1025px) {
      .footer__authors {
        justify-content: space-between; } }
    @media only screen and (min-width: 1240px) {
      .footer__authors {
        width: 100%;
        margin-top: -7rem;
        justify-content: flex-start; } }
    @media only screen and (min-width: 1888px) {
      .footer__authors {
        margin-top: 0; } }
    @media only screen and (min-width: 1240px) {
      .footer__authors .socials {
        display: none; } }
    @media only screen and (min-width: 1888px) {
      .footer__authors .socials {
        display: flex; } }
  .footer input:valid ~ button {
    opacity: 1; }
  .footer input:invalid {
    border: 1px solid red; }
  .footer .modal-city__input:invalid {
    border: transparent; }

.socials {
  display: flex;
  width: 100%; }
  .socials__item {
    width: 2.4rem;
    height: 2.4rem; }
    .socials__item:not(:last-child) {
      margin-right: 2.4rem; }
  .socials__link:hover svg {
    fill: #6699FF; }
  .socials__link svg {
    width: 100%;
    height: 100%;
    fill: #cccccc;
    transition: all 0.3s ease; }

.goods {
  margin: 0 auto;
  max-width: 188rem; }
  .goods__header {
    display: flex;
    align-items: center;
    max-width: 188rem;
    padding: 0 5.5rem 0 1.6rem;
    margin: 0 auto; }
  .goods__content {
    display: flex;
    margin-top: 1.6rem;
    justify-content: space-between; }
  .goods__title {
    font-family: "NeoSansPro", sans-serif;
    font-size: 3.2rem;
    line-height: 1.28;
    font-weight: 400; }
  .goods__list {
    display: flex;
    margin-top: 0.5rem; }
  .goods__item {
    margin-right: 2.4rem; }
  .goods__link {
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: #6699FF;
    transition: opacity 0.3s ease, color 0.3s ease; }
    .goods__link:hover {
      opacity: 0.5; }
    .goods__link_isActive {
      color: #000000;
      pointer-events: none; }
  .goods__controlers {
    margin-top: 0.5rem; }
  .goods__btn {
    position: relative;
    width: 8rem;
    height: 1.6rem;
    border-radius: 1.6rem;
    background: #cccccc;
    transition: all 0.3s ease; }
    .goods__btn:hover, .goods__btn:active {
      background: #6699FF; }
      .goods__btn:hover svg, .goods__btn:active svg {
        fill: #ffffff; }
    .goods__btn svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      fill: #ffffff; }
  .goods__prev {
    margin-right: 0.6rem; }
  .goods__caption {
    margin-left: auto;
    margin-top: 0.4rem;
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5); }
  .goods__drone {
    background: url("../images/white-square.svg") no-repeat bottom, #C5CAD5; }
    .goods__drone::before {
      content: "";
      display: block;
      position: absolute;
      width: 1.2rem;
      height: 3.2rem;
      right: 3.4rem;
      top: 2.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__drone::after {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 1.2rem;
      right: 2.4rem;
      top: 3.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__drone .card__image {
      margin-top: 0.6rem; }
    .goods__drone .card__details {
      margin-left: 2.4rem; }
    .goods__drone .card__text {
      width: 28%;
      margin-top: -1.5rem; }
    .goods__drone .card__picture {
      margin: -10.2rem 0 0 8.1rem; }
  .goods__surface {
    padding: 0;
    background: #ffffff; }
    .goods__surface::before {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 3.2rem;
      top: 2.4rem;
      right: 2.4rem;
      background: #FF0069;
      border-radius: 50%; }
    .goods__surface .card__title {
      width: 52%; }
    .goods__surface .card__details {
      flex-direction: column;
      margin-top: 4.5rem;
      overflow: hidden;
      border-radius: 0.6rem; }
    .goods__surface .card__picture {
      width: 100%;
      margin-top: 2.6rem;
      overflow: hidden;
      border-radius: 0.8rem; }
    .goods__surface .card__text {
      width: 46%;
      margin-left: 2.4rem;
      line-height: 1.2; }
  .goods__galaxy {
    display: none;
    width: 40rem !important;
    padding: 0;
    justify-content: space-between;
    overflow: visible; }
    .goods__galaxy:hover {
      cursor: default; }
      .goods__galaxy:hover .card__more {
        display: none; }
  .goods__lenovo {
    padding: 0;
    background: #ffffff; }
    .goods__lenovo::before {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 3.2rem;
      top: 2.4rem;
      right: 2.4rem;
      background: #162158;
      border-radius: 50%; }
    .goods__lenovo .card__title {
      width: 55%; }
    .goods__lenovo .card__image {
      display: flex;
      height: 100%;
      margin-top: 1.8rem;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 0.8rem; }
  .goods__flagmans {
    display: block;
    width: 49.1%; }
    @media only screen and (min-width: 1240px) {
      .goods__flagmans {
        width: 59%; } }
    @media only screen and (min-width: 1440px) {
      .goods__flagmans {
        width: 65.8%; } }
    @media only screen and (min-width: 1888px) {
      .goods__flagmans {
        width: 112rem; } }
    .goods__flagmans .collection__title {
      opacity: 1; }
    .goods__flagmans .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    @media only screen and (min-width: 1888px) {
      .goods__flagmans .collection__controlers {
        bottom: 1.3rem; } }
    .goods__flagmans .collection__lists {
      justify-content: space-between; }
    .goods__flagmans .collection__list:last-child {
      display: none; }
      @media only screen and (min-width: 1440px) {
        .goods__flagmans .collection__list:last-child {
          display: block; } }
    .goods__flagmans .collection__list:nth-child(3) {
      display: none; }
      @media only screen and (min-width: 1240px) {
        .goods__flagmans .collection__list:nth-child(3) {
          display: block; } }
  .goods__cam {
    display: block;
    width: 49.2%; }
    @media only screen and (min-width: 1240px) {
      .goods__cam {
        width: 39.6%; } }
    @media only screen and (min-width: 1440px) {
      .goods__cam {
        width: 33%; } }
    @media only screen and (min-width: 1888px) {
      .goods__cam {
        width: 67.2rem; } }
    .goods__cam .collection__title {
      opacity: 1; }
    .goods__cam .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative; }
      .goods__cam .collection__navigation::before {
        width: 89.2%;
        left: 2.3rem;
        background: rgba(22, 33, 88, 0.75); }
        @media only screen and (min-width: 1888px) {
          .goods__cam .collection__navigation::before {
            width: 93.2%; } }
      .goods__cam .collection__navigation::after {
        left: 4.6rem;
        background: rgba(22, 33, 88, 0.5); }
    @media only screen and (min-width: 1888px) {
      .goods__cam .collection__controlers {
        bottom: 1.3rem; } }
    .goods__cam .collection__bottom {
      background: #162158; }
      .goods__cam .collection__bottom .swiper-container {
        overflow: hidden; }
      .goods__cam .collection__bottom .swiper-slide {
        opacity: 1; }
    .goods__cam .collection__list:last-child {
      display: none; }
      @media only screen and (min-width: 1888px) {
        .goods__cam .collection__list:last-child {
          display: block; } }
  .goods__notebook {
    display: block;
    width: 39.6%; }
    @media only screen and (min-width: 1440px) {
      .goods__notebook {
        width: 49.4%; } }
    .goods__notebook .collection__title {
      opacity: 1;
      color: #000000; }
    .goods__notebook .collection__link {
      color: #6699FF; }
    .goods__notebook .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(#ececec 0%, rgba(255, 255, 255, 0) 100%); }
    .goods__notebook .collection__controlers button {
      background: #6699FF; }
    .goods__notebook .swiper-pagination-bullet {
      background: #6699FF; }
    .goods__notebook .collection__bottom {
      background: linear-gradient(#ececec, rgba(255, 255, 255, 0)); }
    .goods__notebook .swiper-container {
      z-index: unset; }
    .goods__notebook .collection__list:last-child {
      display: none; }
      @media only screen and (min-width: 1440px) {
        .goods__notebook .collection__list:last-child {
          display: block; } }
    .goods__notebook .collection__btn {
      color: #ffffff;
      background: #cccccc; }
  .goods__mouses {
    display: block;
    width: 59%; }
    @media only screen and (min-width: 1440px) {
      .goods__mouses {
        width: 49.4%; } }
    .goods__mouses .collection__title {
      opacity: 1; }
    .goods__mouses .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(#ececec 0%, rgba(255, 255, 255, 0) 100%); }
    .goods__mouses .swiper-pagination-bullet {
      background: #6699FF; }
    .goods__mouses .collection__link {
      color: #6699FF; }
    .goods__mouses .collection__bottom {
      background: linear-gradient(#ececec, rgba(255, 255, 255, 0)); }
    .goods__mouses .swiper-container {
      z-index: unset; }
    .goods__mouses .collection__btn {
      color: #ffffff;
      background: #cccccc; }
  .goods__internet, .goods__souvenirs, .goods__car {
    display: block;
    position: relative;
    width: 49%;
    height: 436px;
    padding: 1.7rem 0 3rem 0; }
    @media only screen and (min-width: 1240px) {
      .goods__internet, .goods__souvenirs, .goods__car {
        width: 49.3%; } }
    @media only screen and (min-width: 1440px) {
      .goods__internet, .goods__souvenirs, .goods__car {
        width: 33%; } }
    @media only screen and (min-width: 1888px) {
      .goods__internet, .goods__souvenirs, .goods__car {
        width: 44.8rem; } }
    .goods__internet .collection__btn, .goods__souvenirs .collection__btn, .goods__car .collection__btn {
      position: absolute;
      top: 92%;
      left: 50%;
      transform: translateX(-50%);
      background: #cccccc; }
    .goods__internet .collection__wrapper, .goods__souvenirs .collection__wrapper, .goods__car .collection__wrapper {
      display: flex;
      position: relative;
      margin-top: 1.5rem;
      padding-top: 0.6rem;
      justify-content: space-between; }
      @media only screen and (min-width: 1240px) {
        .goods__internet .collection__wrapper, .goods__souvenirs .collection__wrapper, .goods__car .collection__wrapper {
          padding-top: 0.7rem; } }
      .goods__internet .collection__wrapper::before, .goods__souvenirs .collection__wrapper::before, .goods__car .collection__wrapper::before {
        content: "";
        display: block;
        position: absolute;
        width: 0.1rem;
        height: 70%;
        top: 0;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        background: #cccccc; }
    .goods__internet .collection__controlers, .goods__souvenirs .collection__controlers, .goods__car .collection__controlers {
      bottom: 1.4rem;
      left: 47%; }
      .goods__internet .collection__controlers button, .goods__souvenirs .collection__controlers button, .goods__car .collection__controlers button {
        margin-right: 0.5rem;
        background: #6699FF; }
    .goods__internet .collection__title, .goods__souvenirs .collection__title, .goods__car .collection__title {
      opacity: 1; }
    .goods__internet .swiper-pagination-bullet, .goods__souvenirs .swiper-pagination-bullet, .goods__car .swiper-pagination-bullet {
      background: #6699FF; }
    .goods__internet .collection__link, .goods__souvenirs .collection__link, .goods__car .collection__link {
      color: #6699FF; }
    .goods__internet .collection__list, .goods__souvenirs .collection__list, .goods__car .collection__list {
      width: 23.2rem;
      padding-left: 1.6rem; }
      @media only screen and (min-width: 1240px) {
        .goods__internet .collection__list, .goods__souvenirs .collection__list, .goods__car .collection__list {
          width: 28.6rem; } }
      @media only screen and (min-width: 1440px) {
        .goods__internet .collection__list, .goods__souvenirs .collection__list, .goods__car .collection__list {
          width: 22.4rem; } }
    .goods__internet .collection__item, .goods__souvenirs .collection__item, .goods__car .collection__item {
      margin-bottom: 0.56rem; }
    .goods__internet .collection__inner, .goods__souvenirs .collection__inner, .goods__car .collection__inner {
      margin-top: 0; }
    .goods__internet .product, .goods__souvenirs .product, .goods__car .product {
      width: 23.2rem; }
      @media only screen and (min-width: 1240px) {
        .goods__internet .product, .goods__souvenirs .product, .goods__car .product {
          width: 28.6rem; } }
      @media only screen and (min-width: 1440px) {
        .goods__internet .product, .goods__souvenirs .product, .goods__car .product {
          width: 22.4rem;
          padding: 0 1.6rem 2.4rem 1.6rem; } }
      .goods__internet .product::after, .goods__souvenirs .product::after, .goods__car .product::after {
        background: transparent; }
      .goods__internet .product:hover::before, .goods__souvenirs .product:hover::before, .goods__car .product:hover::before {
        box-shadow: 0 1.6rem 2.4rem 0 rgba(0, 0, 0, 0.24); }
    .goods__internet .product__info, .goods__souvenirs .product__info, .goods__car .product__info {
      margin-top: 1.3rem; }
    .goods__internet .product__image, .goods__souvenirs .product__image, .goods__car .product__image {
      margin: 0 auto; }
    .goods__internet .swiper-container, .goods__souvenirs .swiper-container, .goods__car .swiper-container {
      width: 22.4rem; }
    .goods__internet .swiper-pagination-bullet, .goods__souvenirs .swiper-pagination-bullet, .goods__car .swiper-pagination-bullet {
      display: none;
      margin-right: 0.5rem; }
    .goods__internet .product__buttons, .goods__souvenirs .product__buttons, .goods__car .product__buttons {
      transition: none; }
  .goods__car {
    display: none; }
    @media only screen and (min-width: 1888px) {
      .goods__car {
        display: block; } }
    @media only screen and (min-width: 1440px) {
      .goods__car:last-child {
        display: block; } }
  .goods__playstation {
    background: url("../images/white-square-second.svg") no-repeat bottom, #6699FF; }
    .goods__playstation::before {
      content: "";
      display: block;
      position: absolute;
      width: 1.2rem;
      height: 3.2rem;
      right: 3.4rem;
      top: 2.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__playstation::after {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 1.2rem;
      right: 2.4rem;
      top: 3.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__playstation .card__title {
      margin-left: 2.4rem;
      color: #ffffff; }
    .goods__playstation .card__image {
      margin-top: 4.5rem; }
    .goods__playstation .card__details {
      margin-left: 2.4rem; }
    .goods__playstation .card__text {
      width: 44%;
      margin-top: 6.8rem;
      color: rgba(0, 0, 0, 0.5); }
    .goods__playstation .card__picture {
      margin: -7rem -1.5rem 0 0; }
  .goods__ctulhu {
    padding: 0;
    border-radius: 0.8rem;
    background: #ffffff; }
    .goods__ctulhu .card__title {
      margin-left: 2.4rem;
      margin-bottom: 3.7rem; }
    .goods__ctulhu .card__image {
      border-radius: 0.8rem;
      overflow: hidden; }
      .goods__ctulhu .card__image img {
        border-radius: 0.8rem; }
  .goods__gamepad {
    width: 44.8rem !important;
    height: 40.4rem;
    background: #ffffff; }
    .goods__gamepad .card__title {
      margin-left: 1.6rem; }
    .goods__gamepad .card__wrapper {
      display: flex;
      margin-top: 0.4rem; }
    .goods__gamepad .product {
      width: 26.4rem; }
      .goods__gamepad .product:hover {
        box-shadow: none; }
        .goods__gamepad .product:hover .product__promo {
          opacity: 0; }
        .goods__gamepad .product:hover::after {
          display: block; }
      .goods__gamepad .product:nth-child(2)::after {
        background: transparent; }
    .goods__gamepad .swiper-container {
      overflow: hidden !important; }
  .goods__acoustics {
    display: block;
    width: 49.1%; }
    @media only screen and (min-width: 1240px) {
      .goods__acoustics {
        width: 39.6%; } }
    @media only screen and (min-width: 1440px) {
      .goods__acoustics {
        width: 33%;
        height: 60.5rem; } }
    @media only screen and (min-width: 1888px) {
      .goods__acoustics {
        width: 67.2rem; } }
    .goods__acoustics .collection__title {
      opacity: 1; }
    .goods__acoustics .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #8ABE56; }
      .goods__acoustics .collection__navigation::before {
        left: 2.6rem;
        background: rgba(138, 190, 86, 0.5); }
      .goods__acoustics .collection__navigation::after {
        left: 4.6rem;
        bottom: -1.6rem;
        background: rgba(138, 190, 86, 0.2); }
    @media only screen and (min-width: 1888px) {
      .goods__acoustics .collection__inner {
        justify-content: flex-start; } }
    .goods__acoustics .collection__products {
      justify-content: center; }
    .goods__acoustics .collection__link {
      color: #000000; }
    .goods__acoustics .product {
      width: 23.2rem; }
      @media only screen and (min-width: 1240px) {
        .goods__acoustics .product {
          width: 23rem; } }
      @media only screen and (min-width: 1440px) {
        .goods__acoustics .product {
          width: 22.4rem; } }
      .goods__acoustics .product:nth-child(2)::after {
        background: transparent; }
        @media only screen and (min-width: 1888px) {
          .goods__acoustics .product:nth-child(2)::after {
            background: #cccccc; } }
      .goods__acoustics .product:nth-child(3) {
        display: none; }
        @media only screen and (min-width: 1888px) {
          .goods__acoustics .product:nth-child(3) {
            display: block; } }
        .goods__acoustics .product:nth-child(3)::after {
          background: transparent; }
    .goods__acoustics .swiper-container {
      overflow: hidden; }
    .goods__acoustics .swiper-slide {
      opacity: 1; }
  .goods__buyers {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49.2% !important;
    height: 60.3rem;
    margin-right: 0;
    padding: 0;
    flex-grow: 0; }
    .goods__buyers:hover {
      transform: scale(1);
      box-shadow: none; }
    @media only screen and (min-width: 1240px) {
      .goods__buyers {
        width: 59.7% !important; } }
    @media only screen and (min-width: 1440px) {
      .goods__buyers {
        width: 66.5% !important; } }
    @media only screen and (min-width: 1888px) {
      .goods__buyers {
        width: 112.8rem !important; } }
    .goods__buyers .card:hover {
      cursor: default; }
    .goods__buyers .card__plus-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      height: 37.6rem;
      padding-bottom: 3.1rem;
      border-radius: 0.8rem;
      background: url("../images/green-square-big.svg") no-repeat bottom center, #8ABE56;
      background-size: 100%;
      transition: box-shadow 0.3s ease; }
      .goods__buyers .card__plus-one:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__plus-one {
          background: url("../images/white-square-buyers.svg") no-repeat right top, #8ABE56; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__plus-one {
          background: url("../images/big-plus.svg") no-repeat 9%, url("../images/green-square-big.svg") no-repeat bottom center, #8ABE56; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__plus-one {
          background: url("../images/white-square-buyers-desktop.svg") no-repeat right top, #8ABE56; } }
      .goods__buyers .card__plus-one::before {
        content: "";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 3.2rem;
        right: 3.3rem;
        top: 2.4rem;
        background: #ffffff;
        border-radius: .6rem; }
      .goods__buyers .card__plus-one::after {
        content: "";
        display: block;
        position: absolute;
        width: 3.2rem;
        height: 1.2rem;
        right: 2.3rem;
        top: 3.4rem;
        background: #ffffff;
        border-radius: .6rem; }
      .goods__buyers .card__plus-one .card__text {
        display: none;
        font-family: "NeoSansPro", sans-serif;
        font-size: 3.2rem;
        line-height: 1.28;
        font-weight: 400;
        color: #000000;
        line-height: 1.3; }
        @media only screen and (min-width: 1240px) {
          .goods__buyers .card__plus-one .card__text {
            display: block;
            width: 48%;
            margin-left: 2.4rem; } }
        @media only screen and (min-width: 1440px) {
          .goods__buyers .card__plus-one .card__text {
            position: absolute;
            width: 30%;
            top: 14rem;
            left: 6.4rem;
            font-size: 2.4rem;
            text-align: center; } }
        @media only screen and (min-width: 1888px) {
          .goods__buyers .card__plus-one .card__text {
            position: relative;
            top: auto;
            left: auto;
            font-size: 3.2rem;
            text-align: left; } }
    .goods__buyers .card__gift-card,
    .goods__buyers .card__loan {
      display: flex;
      height: 21.6rem;
      padding: 1.6rem 2rem 2.2rem 2.4rem;
      flex-grow: 0;
      background: #ffffff;
      border-radius: 0.8rem;
      transition: box-shadow 0.3s ease; }
      .goods__buyers .card__gift-card:hover,
      .goods__buyers .card__loan:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      .goods__buyers .card__gift-card .card__title,
      .goods__buyers .card__loan .card__title {
        margin: 0;
        color: #000000; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__gift-card,
        .goods__buyers .card__loan {
          width: 49% !important; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__gift-card,
        .goods__buyers .card__loan {
          width: 49.6% !important; } }
    .goods__buyers .card__loan {
      display: none;
      margin-right: 0;
      background: url("../images/persent.svg") no-repeat 87% 50%, #ffffff; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__loan {
          display: block; }
          .goods__buyers .card__loan .card__details {
            padding: 0.2rem 0 0 1.1rem; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__loan {
          background-position: 94% 50%; } }
      .goods__buyers .card__loan .card__text {
        width: 60%; }
        @media only screen and (min-width: 1440px) {
          .goods__buyers .card__loan .card__text {
            width: 45%; } }
        @media only screen and (min-width: 1888px) {
          .goods__buyers .card__loan .card__text {
            width: 30%; } }
    .goods__buyers .card__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    .goods__buyers .card__picture {
      display: block;
      margin-top: 1.6rem; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__picture {
          display: none; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__picture {
          display: block;
          margin-right: -0.8rem; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__picture {
          margin-right: 2rem; } }
    .goods__buyers .card__title {
      margin: 1.6rem 0 0 2.4rem;
      color: #ffffff; }
    .goods__buyers .card__text {
      width: 90%;
      color: rgba(0, 0, 0, 0.5); }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__text {
          width: 65%; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__text {
          width: 92%; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__text {
          width: 65%; } }
    .goods__buyers .card__image {
      position: absolute;
      top: 0;
      left: 32%; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__image {
          left: 53%; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__image {
          left: 60%; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__image {
          left: 65%; } }
  .goods__garanty {
    display: flex;
    width: 40rem !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    overflow: visible; }
    .goods__garanty:hover {
      cursor: default; }
    .goods__garanty .card__setting {
      height: 21.6rem;
      background: url("../images/red-square-big.svg") no-repeat bottom right, #FF0069; }
      .goods__garanty .card__setting .card-small {
        padding: 1.6rem 0 1.5rem 2.4rem; }
      .goods__garanty .card__setting .card-small__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .goods__garanty .card__setting .card-small__title {
        width: 60%;
        color: #ffffff; }
      .goods__garanty .card__setting .card-small__text {
        width: 60%;
        margin-bottom: 1.5rem;
        color: #ffffff; }
    .goods__garanty .card__insurance {
      height: 21.6rem;
      background: url("../images/white-square-small.svg") no-repeat bottom right, #6699FF; }
      .goods__garanty .card__insurance.card-small {
        padding: 2.4rem; }
      .goods__garanty .card__insurance .card-small__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .goods__garanty .card__insurance .card-small__title {
        width: 60%;
        margin-top: -0.8rem;
        color: #ffffff; }
      .goods__garanty .card__insurance .card-small__text {
        width: 50%;
        color: #ffffff; }
  .goods__games {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url("../images/illustration.svg") no-repeat center, #ffffff; }
    .goods__games .card__list {
      margin-left: 2.4rem; }
    .goods__games .card__item {
      margin-bottom: 0.8rem; }
    .goods__games .card__link {
      color: #666666; }
  .goods__gifts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #162158; }
    .goods__gifts .card__title {
      color: #ffffff; }
    .goods__gifts .card__list {
      margin-left: 2.4rem; }
    .goods__gifts .card__item {
      margin-bottom: 0.8rem; }
    .goods__gifts .card__link {
      color: #ffffff; }
  .goods__accessories {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff; }
    .goods__accessories .card__list {
      margin-left: 2.4rem; }
    .goods__accessories .card__item {
      margin-bottom: 0.8rem; }
    .goods__accessories .card__link {
      color: #666666; }
  .goods__popular {
    display: block;
    height: 60.5rem;
    width: 39.6%; }
    @media only screen and (min-width: 1440px) {
      .goods__popular {
        width: 33%; } }
    @media only screen and (min-width: 1888px) {
      .goods__popular {
        width: 37.2%; } }
    .goods__popular .collection__navigation {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      background: #C0BABA; }
      .goods__popular .collection__navigation::before {
        width: 87.3%;
        left: 2.3rem;
        bottom: -0.7rem;
        background: rgba(192, 186, 186, 0.5); }
        @media only screen and (min-width: 1440px) {
          .goods__popular .collection__navigation::before {
            width: 90%; } }
      .goods__popular .collection__navigation::after {
        width: 78%;
        left: 4.4rem;
        background: rgba(192, 186, 186, 0.2); }
        @media only screen and (min-width: 1440px) {
          .goods__popular .collection__navigation::after {
            width: 80%; } }
    .goods__popular .collection__link {
      color: #000000; }
    .goods__popular .collection__products {
      justify-content: center; }
    .goods__popular .product:nth-child(2)::after {
      background: transparent; }
    .goods__popular .product:last-child {
      display: none; }
      @media only screen and (min-width: 1888px) {
        .goods__popular .product:last-child {
          display: block; } }
  .goods__business {
    display: none;
    margin-top: 2.4rem; }
    @media only screen and (min-width: 1240px) {
      .goods__business {
        display: block; } }
    .goods__business .collection__content {
      display: flex;
      justify-content: space-between; }
    .goods__business .collection__title {
      opacity: 1; }
    .goods__business .goods__header {
      justify-content: space-between; }
      @media only screen and (min-width: 1440px) {
        .goods__business .goods__header {
          padding-right: 1.6rem; } }
    .goods__business .goods__link {
      margin-top: 1.2rem; }
  .goods__bonus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 59.6% !important;
    height: 60rem;
    margin-right: 0;
    padding: 0;
    flex-grow: 0; }
    .goods__bonus:hover {
      cursor: default; }
    @media only screen and (min-width: 1440px) {
      .goods__bonus {
        width: 66.4% !important; } }
    @media only screen and (min-width: 1888px) {
      .goods__bonus {
        width: 62.3% !important;
        height: 60.5rem; } }
    .goods__bonus .card__plus {
      position: relative;
      height: 37.6rem;
      overflow: hidden;
      background: url("../images/white-square-third.svg") no-repeat right bottom, #C0BABA;
      border-radius: 0.8rem;
      transition: box-shadow 0.3s ease; }
      .goods__bonus .card__plus:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      .goods__bonus .card__plus .card__text {
        width: 50%;
        margin-left: 2.4rem;
        margin-top: 26.5rem;
        font-family: "NeoSansPro", sans-serif;
        font-size: 3.2rem;
        line-height: 1.28;
        font-weight: 400; }
        @media only screen and (min-width: 1440px) {
          .goods__bonus .card__plus .card__text {
            width: 40%; } }
      .goods__bonus .card__plus .card__background {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        width: 0;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%; }
    .goods__bonus .card__corporate {
      width: 49.4%;
      height: 21.6rem;
      padding: 0 0 2.1rem 0;
      border-radius: 0.8rem;
      background: #162158;
      transition: box-shadow 0.3s ease; }
      .goods__bonus .card__corporate:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      .goods__bonus .card__corporate .card__title {
        color: #ffffff; }
        @media only screen and (min-width: 1440px) {
          .goods__bonus .card__corporate .card__title {
            width: 50%; } }
      .goods__bonus .card__corporate .card__text {
        width: 50%;
        margin-left: 2.4rem;
        color: rgba(255, 255, 255, 0.5); }
    .goods__bonus .card__delivery {
      width: 49.3%;
      height: 21.6rem;
      padding: 0 0 2.1rem 0;
      border-radius: 0.8rem;
      background: #ffffff;
      transition: box-shadow 0.3s ease; }
      .goods__bonus .card__delivery:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      @media only screen and (min-width: 1440px) {
        .goods__bonus .card__delivery .card__title {
          width: 50%; } }
      .goods__bonus .card__delivery .card__text {
        margin-left: 2.4rem;
        color: rgba(0, 0, 0, 0.5); }
    .goods__bonus .card__image {
      position: absolute;
      top: 0;
      left: 56%; }
      @media only screen and (min-width: 1440px) {
        .goods__bonus .card__image {
          left: 58.4%; } }
    .goods__bonus .card__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    .goods__bonus .card__text {
      width: 60%; }
      @media only screen and (min-width: 1440px) {
        .goods__bonus .card__text {
          width: 35%; } }
  .goods__redemption {
    width: 44.8rem !important;
    background: #ffffff; }
    .goods__redemption .card__title {
      width: 70%; }
    .goods__redemption .card__image {
      margin-top: 2.4rem; }
      .goods__redemption .card__image img {
        border-radius: 0.8rem; }
  .goods__fortnite {
    padding-bottom: 0;
    background: #ffffff; }
    .goods__fortnite .card__image {
      height: 100%;
      padding-top: 2.4rem;
      padding-left: 1.4rem;
      overflow: hidden; }
  .goods__watch {
    padding-bottom: 0;
    background: #ffffff; }
    .goods__watch .card__image {
      height: 100%;
      padding-top: 2.4rem;
      overflow: hidden;
      border-radius: 0.8rem; }
      .goods__watch .card__image img {
        border-radius: 0.8rem; }

.swiper-container {
  overflow: hidden; }

.visible {
  display: flex;
  transition: opacity 0.3s ease; }

.goods {
  margin: 0 auto;
  max-width: 188rem; }
  .goods__header {
    display: flex;
    align-items: center;
    max-width: 188rem;
    padding: 0 5.5rem 0 1.6rem;
    margin: 0 auto; }
  .goods__content {
    display: flex;
    margin-top: 1.6rem;
    justify-content: space-between; }
  .goods__title {
    font-family: "NeoSansPro", sans-serif;
    font-size: 3.2rem;
    line-height: 1.28;
    font-weight: 400; }
  .goods__list {
    display: flex;
    margin-top: 0.5rem; }
  .goods__item {
    margin-right: 2.4rem; }
  .goods__link {
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: #6699FF;
    transition: opacity 0.3s ease, color 0.3s ease; }
    .goods__link:hover {
      opacity: 0.5; }
    .goods__link_isActive {
      color: #000000;
      pointer-events: none; }
  .goods__controlers {
    margin-top: 0.5rem; }
  .goods__btn {
    position: relative;
    width: 8rem;
    height: 1.6rem;
    border-radius: 1.6rem;
    background: #cccccc;
    transition: all 0.3s ease; }
    .goods__btn:hover, .goods__btn:active {
      background: #6699FF; }
      .goods__btn:hover svg, .goods__btn:active svg {
        fill: #ffffff; }
    .goods__btn svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      fill: #ffffff; }
  .goods__prev {
    margin-right: 0.6rem; }
  .goods__caption {
    margin-left: auto;
    margin-top: 0.4rem;
    font-size: 1.8rem;
    line-height: 1.16;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5); }
  .goods__drone {
    background: url("../images/white-square.svg") no-repeat bottom, #C5CAD5; }
    .goods__drone::before {
      content: "";
      display: block;
      position: absolute;
      width: 1.2rem;
      height: 3.2rem;
      right: 3.4rem;
      top: 2.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__drone::after {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 1.2rem;
      right: 2.4rem;
      top: 3.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__drone .card__image {
      margin-top: 0.6rem; }
    .goods__drone .card__details {
      margin-left: 2.4rem; }
    .goods__drone .card__text {
      width: 28%;
      margin-top: -1.5rem; }
    .goods__drone .card__picture {
      margin: -10.2rem 0 0 8.1rem; }
  .goods__surface {
    padding: 0;
    background: #ffffff; }
    .goods__surface::before {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 3.2rem;
      top: 2.4rem;
      right: 2.4rem;
      background: #FF0069;
      border-radius: 50%; }
    .goods__surface .card__title {
      width: 52%; }
    .goods__surface .card__details {
      flex-direction: column;
      margin-top: 4.5rem;
      overflow: hidden;
      border-radius: 0.6rem; }
    .goods__surface .card__picture {
      width: 100%;
      margin-top: 2.6rem;
      overflow: hidden;
      border-radius: 0.8rem; }
    .goods__surface .card__text {
      width: 46%;
      margin-left: 2.4rem;
      line-height: 1.2; }
  .goods__galaxy {
    display: none;
    width: 40rem !important;
    padding: 0;
    justify-content: space-between;
    overflow: visible; }
    .goods__galaxy:hover {
      cursor: default; }
      .goods__galaxy:hover .card__more {
        display: none; }
  .goods__lenovo {
    padding: 0;
    background: #ffffff; }
    .goods__lenovo::before {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 3.2rem;
      top: 2.4rem;
      right: 2.4rem;
      background: #162158;
      border-radius: 50%; }
    .goods__lenovo .card__title {
      width: 55%; }
    .goods__lenovo .card__image {
      display: flex;
      height: 100%;
      margin-top: 1.8rem;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 0.8rem; }
  .goods__flagmans {
    display: block;
    width: 49.1%; }
    @media only screen and (min-width: 1240px) {
      .goods__flagmans {
        width: 59%; } }
    @media only screen and (min-width: 1440px) {
      .goods__flagmans {
        width: 65.8%; } }
    @media only screen and (min-width: 1888px) {
      .goods__flagmans {
        width: 112rem; } }
    .goods__flagmans .collection__title {
      opacity: 1; }
    .goods__flagmans .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    @media only screen and (min-width: 1888px) {
      .goods__flagmans .collection__controlers {
        bottom: 1.3rem; } }
    .goods__flagmans .collection__lists {
      justify-content: space-between; }
    .goods__flagmans .collection__list:last-child {
      display: none; }
      @media only screen and (min-width: 1440px) {
        .goods__flagmans .collection__list:last-child {
          display: block; } }
    .goods__flagmans .collection__list:nth-child(3) {
      display: none; }
      @media only screen and (min-width: 1240px) {
        .goods__flagmans .collection__list:nth-child(3) {
          display: block; } }
  .goods__cam {
    display: block;
    width: 49.2%; }
    @media only screen and (min-width: 1240px) {
      .goods__cam {
        width: 39.6%; } }
    @media only screen and (min-width: 1440px) {
      .goods__cam {
        width: 33%; } }
    @media only screen and (min-width: 1888px) {
      .goods__cam {
        width: 67.2rem; } }
    .goods__cam .collection__title {
      opacity: 1; }
    .goods__cam .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative; }
      .goods__cam .collection__navigation::before {
        width: 89.2%;
        left: 2.3rem;
        background: rgba(22, 33, 88, 0.75); }
        @media only screen and (min-width: 1888px) {
          .goods__cam .collection__navigation::before {
            width: 93.2%; } }
      .goods__cam .collection__navigation::after {
        left: 4.6rem;
        background: rgba(22, 33, 88, 0.5); }
    @media only screen and (min-width: 1888px) {
      .goods__cam .collection__controlers {
        bottom: 1.3rem; } }
    .goods__cam .collection__bottom {
      background: #162158; }
      .goods__cam .collection__bottom .swiper-container {
        overflow: hidden; }
      .goods__cam .collection__bottom .swiper-slide {
        opacity: 1; }
    .goods__cam .collection__list:last-child {
      display: none; }
      @media only screen and (min-width: 1888px) {
        .goods__cam .collection__list:last-child {
          display: block; } }
  .goods__notebook {
    display: block;
    width: 39.6%; }
    @media only screen and (min-width: 1440px) {
      .goods__notebook {
        width: 49.4%; } }
    .goods__notebook .collection__title {
      opacity: 1;
      color: #000000; }
    .goods__notebook .collection__link {
      color: #6699FF; }
    .goods__notebook .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(#ececec 0%, rgba(255, 255, 255, 0) 100%); }
    .goods__notebook .collection__controlers button {
      background: #6699FF; }
    .goods__notebook .swiper-pagination-bullet {
      background: #6699FF; }
    .goods__notebook .collection__bottom {
      background: linear-gradient(#ececec, rgba(255, 255, 255, 0)); }
    .goods__notebook .swiper-container {
      z-index: unset; }
    .goods__notebook .collection__list:last-child {
      display: none; }
      @media only screen and (min-width: 1440px) {
        .goods__notebook .collection__list:last-child {
          display: block; } }
    .goods__notebook .collection__btn {
      color: #ffffff;
      background: #cccccc; }
  .goods__mouses {
    display: block;
    width: 59%; }
    @media only screen and (min-width: 1440px) {
      .goods__mouses {
        width: 49.4%; } }
    .goods__mouses .collection__title {
      opacity: 1; }
    .goods__mouses .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(#ececec 0%, rgba(255, 255, 255, 0) 100%); }
    .goods__mouses .swiper-pagination-bullet {
      background: #6699FF; }
    .goods__mouses .collection__link {
      color: #6699FF; }
    .goods__mouses .collection__bottom {
      background: linear-gradient(#ececec, rgba(255, 255, 255, 0)); }
    .goods__mouses .swiper-container {
      z-index: unset; }
    .goods__mouses .collection__btn {
      color: #ffffff;
      background: #cccccc; }
  .goods__internet, .goods__souvenirs, .goods__car {
    display: block;
    position: relative;
    width: 49%;
    height: 436px;
    padding: 1.7rem 0 3rem 0; }
    @media only screen and (min-width: 1240px) {
      .goods__internet, .goods__souvenirs, .goods__car {
        width: 49.3%; } }
    @media only screen and (min-width: 1440px) {
      .goods__internet, .goods__souvenirs, .goods__car {
        width: 33%; } }
    @media only screen and (min-width: 1888px) {
      .goods__internet, .goods__souvenirs, .goods__car {
        width: 44.8rem; } }
    .goods__internet .collection__btn, .goods__souvenirs .collection__btn, .goods__car .collection__btn {
      position: absolute;
      top: 92%;
      left: 50%;
      transform: translateX(-50%);
      background: #cccccc; }
    .goods__internet .collection__wrapper, .goods__souvenirs .collection__wrapper, .goods__car .collection__wrapper {
      display: flex;
      position: relative;
      margin-top: 1.5rem;
      padding-top: 0.6rem;
      justify-content: space-between; }
      @media only screen and (min-width: 1240px) {
        .goods__internet .collection__wrapper, .goods__souvenirs .collection__wrapper, .goods__car .collection__wrapper {
          padding-top: 0.7rem; } }
      .goods__internet .collection__wrapper::before, .goods__souvenirs .collection__wrapper::before, .goods__car .collection__wrapper::before {
        content: "";
        display: block;
        position: absolute;
        width: 0.1rem;
        height: 70%;
        top: 0;
        left: 50%;
        z-index: 2;
        transform: translateX(-50%);
        background: #cccccc; }
    .goods__internet .collection__controlers, .goods__souvenirs .collection__controlers, .goods__car .collection__controlers {
      bottom: 1.4rem;
      left: 47%; }
      .goods__internet .collection__controlers button, .goods__souvenirs .collection__controlers button, .goods__car .collection__controlers button {
        margin-right: 0.5rem;
        background: #6699FF; }
    .goods__internet .collection__title, .goods__souvenirs .collection__title, .goods__car .collection__title {
      opacity: 1; }
    .goods__internet .swiper-pagination-bullet, .goods__souvenirs .swiper-pagination-bullet, .goods__car .swiper-pagination-bullet {
      background: #6699FF; }
    .goods__internet .collection__link, .goods__souvenirs .collection__link, .goods__car .collection__link {
      color: #6699FF; }
    .goods__internet .collection__list, .goods__souvenirs .collection__list, .goods__car .collection__list {
      width: 23.2rem;
      padding-left: 1.6rem; }
      @media only screen and (min-width: 1240px) {
        .goods__internet .collection__list, .goods__souvenirs .collection__list, .goods__car .collection__list {
          width: 28.6rem; } }
      @media only screen and (min-width: 1440px) {
        .goods__internet .collection__list, .goods__souvenirs .collection__list, .goods__car .collection__list {
          width: 22.4rem; } }
    .goods__internet .collection__item, .goods__souvenirs .collection__item, .goods__car .collection__item {
      margin-bottom: 0.56rem; }
    .goods__internet .collection__inner, .goods__souvenirs .collection__inner, .goods__car .collection__inner {
      margin-top: 0; }
    .goods__internet .product, .goods__souvenirs .product, .goods__car .product {
      width: 23.2rem; }
      @media only screen and (min-width: 1240px) {
        .goods__internet .product, .goods__souvenirs .product, .goods__car .product {
          width: 28.6rem; } }
      @media only screen and (min-width: 1440px) {
        .goods__internet .product, .goods__souvenirs .product, .goods__car .product {
          width: 22.4rem;
          padding: 0 1.6rem 2.4rem 1.6rem; } }
      .goods__internet .product::after, .goods__souvenirs .product::after, .goods__car .product::after {
        background: transparent; }
      .goods__internet .product:hover::before, .goods__souvenirs .product:hover::before, .goods__car .product:hover::before {
        box-shadow: 0 1.6rem 2.4rem 0 rgba(0, 0, 0, 0.24); }
    .goods__internet .product__info, .goods__souvenirs .product__info, .goods__car .product__info {
      margin-top: 1.3rem; }
    .goods__internet .product__image, .goods__souvenirs .product__image, .goods__car .product__image {
      margin: 0 auto; }
    .goods__internet .swiper-container, .goods__souvenirs .swiper-container, .goods__car .swiper-container {
      width: 22.4rem; }
    .goods__internet .swiper-pagination-bullet, .goods__souvenirs .swiper-pagination-bullet, .goods__car .swiper-pagination-bullet {
      display: none;
      margin-right: 0.5rem; }
    .goods__internet .product__buttons, .goods__souvenirs .product__buttons, .goods__car .product__buttons {
      transition: none; }
  .goods__car {
    display: none; }
    @media only screen and (min-width: 1888px) {
      .goods__car {
        display: block; } }
    @media only screen and (min-width: 1440px) {
      .goods__car:last-child {
        display: block; } }
  .goods__playstation {
    background: url("../images/white-square-second.svg") no-repeat bottom, #6699FF; }
    .goods__playstation::before {
      content: "";
      display: block;
      position: absolute;
      width: 1.2rem;
      height: 3.2rem;
      right: 3.4rem;
      top: 2.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__playstation::after {
      content: "";
      display: block;
      position: absolute;
      width: 3.2rem;
      height: 1.2rem;
      right: 2.4rem;
      top: 3.4rem;
      background: #ffffff;
      border-radius: 0.6rem; }
    .goods__playstation .card__title {
      margin-left: 2.4rem;
      color: #ffffff; }
    .goods__playstation .card__image {
      margin-top: 4.5rem; }
    .goods__playstation .card__details {
      margin-left: 2.4rem; }
    .goods__playstation .card__text {
      width: 44%;
      margin-top: 6.8rem;
      color: rgba(0, 0, 0, 0.5); }
    .goods__playstation .card__picture {
      margin: -7rem -1.5rem 0 0; }
  .goods__ctulhu {
    padding: 0;
    border-radius: 0.8rem;
    background: #ffffff; }
    .goods__ctulhu .card__title {
      margin-left: 2.4rem;
      margin-bottom: 3.7rem; }
    .goods__ctulhu .card__image {
      border-radius: 0.8rem;
      overflow: hidden; }
      .goods__ctulhu .card__image img {
        border-radius: 0.8rem; }
  .goods__gamepad {
    width: 44.8rem !important;
    height: 40.4rem;
    background: #ffffff; }
    .goods__gamepad .card__title {
      margin-left: 1.6rem; }
    .goods__gamepad .card__wrapper {
      display: flex;
      margin-top: 0.4rem; }
    .goods__gamepad .product {
      width: 26.4rem; }
      .goods__gamepad .product:hover {
        box-shadow: none; }
        .goods__gamepad .product:hover .product__promo {
          opacity: 0; }
        .goods__gamepad .product:hover::after {
          display: block; }
      .goods__gamepad .product:nth-child(2)::after {
        background: transparent; }
    .goods__gamepad .swiper-container {
      overflow: hidden !important; }
  .goods__acoustics {
    display: block;
    width: 49.1%; }
    @media only screen and (min-width: 1240px) {
      .goods__acoustics {
        width: 39.6%; } }
    @media only screen and (min-width: 1440px) {
      .goods__acoustics {
        width: 33%;
        height: 60.5rem; } }
    @media only screen and (min-width: 1888px) {
      .goods__acoustics {
        width: 67.2rem; } }
    .goods__acoustics .collection__title {
      opacity: 1; }
    .goods__acoustics .collection__navigation {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #8ABE56; }
      .goods__acoustics .collection__navigation::before {
        left: 2.6rem;
        background: rgba(138, 190, 86, 0.5); }
      .goods__acoustics .collection__navigation::after {
        left: 4.6rem;
        bottom: -1.6rem;
        background: rgba(138, 190, 86, 0.2); }
    @media only screen and (min-width: 1888px) {
      .goods__acoustics .collection__inner {
        justify-content: flex-start; } }
    .goods__acoustics .collection__products {
      justify-content: center; }
    .goods__acoustics .collection__link {
      color: #000000; }
    .goods__acoustics .product {
      width: 23.2rem; }
      @media only screen and (min-width: 1240px) {
        .goods__acoustics .product {
          width: 23rem; } }
      @media only screen and (min-width: 1440px) {
        .goods__acoustics .product {
          width: 22.4rem; } }
      .goods__acoustics .product:nth-child(2)::after {
        background: transparent; }
        @media only screen and (min-width: 1888px) {
          .goods__acoustics .product:nth-child(2)::after {
            background: #cccccc; } }
      .goods__acoustics .product:nth-child(3) {
        display: none; }
        @media only screen and (min-width: 1888px) {
          .goods__acoustics .product:nth-child(3) {
            display: block; } }
        .goods__acoustics .product:nth-child(3)::after {
          background: transparent; }
    .goods__acoustics .swiper-container {
      overflow: hidden; }
    .goods__acoustics .swiper-slide {
      opacity: 1; }
  .goods__buyers {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 49.2% !important;
    height: 60.3rem;
    margin-right: 0;
    padding: 0;
    flex-grow: 0; }
    .goods__buyers:hover {
      transform: scale(1);
      box-shadow: none; }
    @media only screen and (min-width: 1240px) {
      .goods__buyers {
        width: 59.7% !important; } }
    @media only screen and (min-width: 1440px) {
      .goods__buyers {
        width: 66.5% !important; } }
    @media only screen and (min-width: 1888px) {
      .goods__buyers {
        width: 112.8rem !important; } }
    .goods__buyers .card:hover {
      cursor: default; }
    .goods__buyers .card__plus-one {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      height: 37.6rem;
      padding-bottom: 3.1rem;
      border-radius: 0.8rem;
      background: url("../images/green-square-big.svg") no-repeat bottom center, #8ABE56;
      background-size: 100%;
      transition: box-shadow 0.3s ease; }
      .goods__buyers .card__plus-one:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__plus-one {
          background: url("../images/white-square-buyers.svg") no-repeat right top, #8ABE56; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__plus-one {
          background: url("../images/big-plus.svg") no-repeat 9%, url("../images/green-square-big.svg") no-repeat bottom center, #8ABE56; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__plus-one {
          background: url("../images/white-square-buyers-desktop.svg") no-repeat right top, #8ABE56; } }
      .goods__buyers .card__plus-one::before {
        content: "";
        display: block;
        position: absolute;
        width: 1.2rem;
        height: 3.2rem;
        right: 3.3rem;
        top: 2.4rem;
        background: #ffffff;
        border-radius: .6rem; }
      .goods__buyers .card__plus-one::after {
        content: "";
        display: block;
        position: absolute;
        width: 3.2rem;
        height: 1.2rem;
        right: 2.3rem;
        top: 3.4rem;
        background: #ffffff;
        border-radius: .6rem; }
      .goods__buyers .card__plus-one .card__text {
        display: none;
        font-family: "NeoSansPro", sans-serif;
        font-size: 3.2rem;
        line-height: 1.28;
        font-weight: 400;
        color: #000000;
        line-height: 1.3; }
        @media only screen and (min-width: 1240px) {
          .goods__buyers .card__plus-one .card__text {
            display: block;
            width: 48%;
            margin-left: 2.4rem; } }
        @media only screen and (min-width: 1440px) {
          .goods__buyers .card__plus-one .card__text {
            position: absolute;
            width: 30%;
            top: 14rem;
            left: 6.4rem;
            font-size: 2.4rem;
            text-align: center; } }
        @media only screen and (min-width: 1888px) {
          .goods__buyers .card__plus-one .card__text {
            position: relative;
            top: auto;
            left: auto;
            font-size: 3.2rem;
            text-align: left; } }
    .goods__buyers .card__gift-card,
    .goods__buyers .card__loan {
      display: flex;
      height: 21.6rem;
      padding: 1.6rem 2rem 2.2rem 2.4rem;
      flex-grow: 0;
      background: #ffffff;
      border-radius: 0.8rem;
      transition: box-shadow 0.3s ease; }
      .goods__buyers .card__gift-card:hover,
      .goods__buyers .card__loan:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      .goods__buyers .card__gift-card .card__title,
      .goods__buyers .card__loan .card__title {
        margin: 0;
        color: #000000; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__gift-card,
        .goods__buyers .card__loan {
          width: 49% !important; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__gift-card,
        .goods__buyers .card__loan {
          width: 49.6% !important; } }
    .goods__buyers .card__loan {
      display: none;
      margin-right: 0;
      background: url("../images/persent.svg") no-repeat 87% 50%, #ffffff; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__loan {
          display: block; }
          .goods__buyers .card__loan .card__details {
            padding: 0.2rem 0 0 1.1rem; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__loan {
          background-position: 94% 50%; } }
      .goods__buyers .card__loan .card__text {
        width: 60%; }
        @media only screen and (min-width: 1440px) {
          .goods__buyers .card__loan .card__text {
            width: 45%; } }
        @media only screen and (min-width: 1888px) {
          .goods__buyers .card__loan .card__text {
            width: 30%; } }
    .goods__buyers .card__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    .goods__buyers .card__picture {
      display: block;
      margin-top: 1.6rem; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__picture {
          display: none; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__picture {
          display: block;
          margin-right: -0.8rem; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__picture {
          margin-right: 2rem; } }
    .goods__buyers .card__title {
      margin: 1.6rem 0 0 2.4rem;
      color: #ffffff; }
    .goods__buyers .card__text {
      width: 90%;
      color: rgba(0, 0, 0, 0.5); }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__text {
          width: 65%; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__text {
          width: 92%; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__text {
          width: 65%; } }
    .goods__buyers .card__image {
      position: absolute;
      top: 0;
      left: 32%; }
      @media only screen and (min-width: 1240px) {
        .goods__buyers .card__image {
          left: 53%; } }
      @media only screen and (min-width: 1440px) {
        .goods__buyers .card__image {
          left: 60%; } }
      @media only screen and (min-width: 1888px) {
        .goods__buyers .card__image {
          left: 65%; } }
  .goods__garanty {
    display: flex;
    width: 40rem !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    overflow: visible; }
    .goods__garanty:hover {
      cursor: default; }
    .goods__garanty .card__setting {
      height: 21.6rem;
      background: url("../images/red-square-big.svg") no-repeat bottom right, #FF0069; }
      .goods__garanty .card__setting .card-small {
        padding: 1.6rem 0 1.5rem 2.4rem; }
      .goods__garanty .card__setting .card-small__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .goods__garanty .card__setting .card-small__title {
        width: 60%;
        color: #ffffff; }
      .goods__garanty .card__setting .card-small__text {
        width: 60%;
        margin-bottom: 1.5rem;
        color: #ffffff; }
    .goods__garanty .card__insurance {
      height: 21.6rem;
      background: url("../images/white-square-small.svg") no-repeat bottom right, #6699FF; }
      .goods__garanty .card__insurance.card-small {
        padding: 2.4rem; }
      .goods__garanty .card__insurance .card-small__details {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .goods__garanty .card__insurance .card-small__title {
        width: 60%;
        margin-top: -0.8rem;
        color: #ffffff; }
      .goods__garanty .card__insurance .card-small__text {
        width: 50%;
        color: #ffffff; }
  .goods__games {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url("../images/illustration.svg") no-repeat center, #ffffff; }
    .goods__games .card__list {
      margin-left: 2.4rem; }
    .goods__games .card__item {
      margin-bottom: 0.8rem; }
    .goods__games .card__link {
      color: #666666; }
  .goods__gifts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #162158; }
    .goods__gifts .card__title {
      color: #ffffff; }
    .goods__gifts .card__list {
      margin-left: 2.4rem; }
    .goods__gifts .card__item {
      margin-bottom: 0.8rem; }
    .goods__gifts .card__link {
      color: #ffffff; }
  .goods__accessories {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff; }
    .goods__accessories .card__list {
      margin-left: 2.4rem; }
    .goods__accessories .card__item {
      margin-bottom: 0.8rem; }
    .goods__accessories .card__link {
      color: #666666; }
  .goods__popular {
    display: block;
    height: 60.5rem;
    width: 39.6%; }
    @media only screen and (min-width: 1440px) {
      .goods__popular {
        width: 33%; } }
    @media only screen and (min-width: 1888px) {
      .goods__popular {
        width: 37.2%; } }
    .goods__popular .collection__navigation {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      background: #C0BABA; }
      .goods__popular .collection__navigation::before {
        width: 87.3%;
        left: 2.3rem;
        bottom: -0.7rem;
        background: rgba(192, 186, 186, 0.5); }
        @media only screen and (min-width: 1440px) {
          .goods__popular .collection__navigation::before {
            width: 90%; } }
      .goods__popular .collection__navigation::after {
        width: 78%;
        left: 4.4rem;
        background: rgba(192, 186, 186, 0.2); }
        @media only screen and (min-width: 1440px) {
          .goods__popular .collection__navigation::after {
            width: 80%; } }
    .goods__popular .collection__link {
      color: #000000; }
    .goods__popular .collection__products {
      justify-content: center; }
    .goods__popular .product:nth-child(2)::after {
      background: transparent; }
    .goods__popular .product:last-child {
      display: none; }
      @media only screen and (min-width: 1888px) {
        .goods__popular .product:last-child {
          display: block; } }
  .goods__business {
    display: none;
    margin-top: 2.4rem; }
    @media only screen and (min-width: 1240px) {
      .goods__business {
        display: block; } }
    .goods__business .collection__content {
      display: flex;
      justify-content: space-between; }
    .goods__business .collection__title {
      opacity: 1; }
    .goods__business .goods__header {
      justify-content: space-between; }
      @media only screen and (min-width: 1440px) {
        .goods__business .goods__header {
          padding-right: 1.6rem; } }
    .goods__business .goods__link {
      margin-top: 1.2rem; }
  .goods__bonus {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 59.6% !important;
    height: 60rem;
    margin-right: 0;
    padding: 0;
    flex-grow: 0; }
    .goods__bonus:hover {
      cursor: default; }
    @media only screen and (min-width: 1440px) {
      .goods__bonus {
        width: 66.4% !important; } }
    @media only screen and (min-width: 1888px) {
      .goods__bonus {
        width: 62.3% !important;
        height: 60.5rem; } }
    .goods__bonus .card__plus {
      position: relative;
      height: 37.6rem;
      overflow: hidden;
      background: url("../images/white-square-third.svg") no-repeat right bottom, #C0BABA;
      border-radius: 0.8rem;
      transition: box-shadow 0.3s ease; }
      .goods__bonus .card__plus:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      .goods__bonus .card__plus .card__text {
        width: 50%;
        margin-left: 2.4rem;
        margin-top: 26.5rem;
        font-family: "NeoSansPro", sans-serif;
        font-size: 3.2rem;
        line-height: 1.28;
        font-weight: 400; }
        @media only screen and (min-width: 1440px) {
          .goods__bonus .card__plus .card__text {
            width: 40%; } }
      .goods__bonus .card__plus .card__background {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        width: 0;
        min-width: 100%;
        min-height: 100%;
        width: 100%;
        height: 100%; }
    .goods__bonus .card__corporate {
      width: 49.4%;
      height: 21.6rem;
      padding: 0 0 2.1rem 0;
      border-radius: 0.8rem;
      background: #162158;
      transition: box-shadow 0.3s ease; }
      .goods__bonus .card__corporate:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      .goods__bonus .card__corporate .card__title {
        color: #ffffff; }
        @media only screen and (min-width: 1440px) {
          .goods__bonus .card__corporate .card__title {
            width: 50%; } }
      .goods__bonus .card__corporate .card__text {
        width: 50%;
        margin-left: 2.4rem;
        color: rgba(255, 255, 255, 0.5); }
    .goods__bonus .card__delivery {
      width: 49.3%;
      height: 21.6rem;
      padding: 0 0 2.1rem 0;
      border-radius: 0.8rem;
      background: #ffffff;
      transition: box-shadow 0.3s ease; }
      .goods__bonus .card__delivery:hover {
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.24); }
      @media only screen and (min-width: 1440px) {
        .goods__bonus .card__delivery .card__title {
          width: 50%; } }
      .goods__bonus .card__delivery .card__text {
        margin-left: 2.4rem;
        color: rgba(0, 0, 0, 0.5); }
    .goods__bonus .card__image {
      position: absolute;
      top: 0;
      left: 56%; }
      @media only screen and (min-width: 1440px) {
        .goods__bonus .card__image {
          left: 58.4%; } }
    .goods__bonus .card__details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    .goods__bonus .card__text {
      width: 60%; }
      @media only screen and (min-width: 1440px) {
        .goods__bonus .card__text {
          width: 35%; } }
  .goods__redemption {
    width: 44.8rem !important;
    background: #ffffff; }
    .goods__redemption .card__title {
      width: 70%; }
    .goods__redemption .card__image {
      margin-top: 2.4rem; }
      .goods__redemption .card__image img {
        border-radius: 0.8rem; }
  .goods__fortnite {
    padding-bottom: 0;
    background: #ffffff; }
    .goods__fortnite .card__image {
      height: 100%;
      padding-top: 2.4rem;
      padding-left: 1.4rem;
      overflow: hidden; }
  .goods__watch {
    padding-bottom: 0;
    background: #ffffff; }
    .goods__watch .card__image {
      height: 100%;
      padding-top: 2.4rem;
      overflow: hidden;
      border-radius: 0.8rem; }
      .goods__watch .card__image img {
        border-radius: 0.8rem; }

.swiper-container {
  overflow: hidden; }

.visible {
  display: flex;
  transition: opacity 0.3s ease; }

.header {
  width: 100%;
  position: relative;
  z-index: 999; }
  .header .container {
    width: 100%; }
  .header.is-fixed {
    position: fixed;
    top: 0;
    left: 0; }
  .header__wrapper {
    display: flex;
    height: 60.4rem;
    margin-top: 0.9rem;
    justify-content: space-between; }
  @media only screen and (min-width: 1240px) {
    .header__hits {
      display: block;
      width: 18.6%; } }
  @media only screen and (min-width: 1440px) {
    .header__hits {
      width: 31.7%; } }
  @media only screen and (min-width: 1888px) {
    .header__hits {
      width: 67.2rem; }
      .header__hits .product:nth-child(2)::after {
        background: #cccccc; } }
  .header__hits .product:first-child::after {
    background: transparent; }
    @media only screen and (min-width: 1440px) {
      .header__hits .product:first-child::after {
        background: #cccccc; } }
  .header__hits .product:nth-child(2) {
    display: none; }
    @media only screen and (min-width: 1440px) {
      .header__hits .product:nth-child(2) {
        display: block; } }
    .header__hits .product:nth-child(2)::after {
      background: transparent; }
      @media only screen and (min-width: 1888px) {
        .header__hits .product:nth-child(2)::after {
          background: #cccccc; } }
  .header__hits .product:nth-child(3) {
    display: none; }
    @media only screen and (min-width: 1888px) {
      .header__hits .product:nth-child(3) {
        display: block; } }
    .header__hits .product:nth-child(3)::after {
      background: transparent; }
  .header__hits .collection__navigation {
    display: flex;
    position: relative;
    z-index: 22;
    flex-direction: column;
    justify-content: space-between;
    background: #FF0069;
    border-radius: 0.8rem; }
  .header__hits .collection__list:nth-child(2) {
    display: none; }
    @media only screen and (min-width: 1440px) {
      .header__hits .collection__list:nth-child(2) {
        display: block; } }
  .header__hits .collection__list:last-child {
    display: none; }
    @media only screen and (min-width: 1888px) {
      .header__hits .collection__list:last-child {
        display: block; } }
  .header__hits .collection__products {
    justify-content: center; }
  .header__hits .collection__lists {
    display: flex;
    justify-content: space-between; }

.header {
  width: 100%;
  position: relative;
  z-index: 999; }
  .header .container {
    width: 100%; }
  .header.is-fixed {
    position: fixed;
    top: 0;
    left: 0; }
  .header__wrapper {
    display: flex;
    height: 60.4rem;
    margin-top: 0.9rem;
    justify-content: space-between; }
  @media only screen and (min-width: 1240px) {
    .header__hits {
      display: block;
      width: 18.6%; } }
  @media only screen and (min-width: 1440px) {
    .header__hits {
      width: 31.7%; } }
  @media only screen and (min-width: 1888px) {
    .header__hits {
      width: 67.2rem; }
      .header__hits .product:nth-child(2)::after {
        background: #cccccc; } }
  .header__hits .product:first-child::after {
    background: transparent; }
    @media only screen and (min-width: 1440px) {
      .header__hits .product:first-child::after {
        background: #cccccc; } }
  .header__hits .product:nth-child(2) {
    display: none; }
    @media only screen and (min-width: 1440px) {
      .header__hits .product:nth-child(2) {
        display: block; } }
    .header__hits .product:nth-child(2)::after {
      background: transparent; }
      @media only screen and (min-width: 1888px) {
        .header__hits .product:nth-child(2)::after {
          background: #cccccc; } }
  .header__hits .product:nth-child(3) {
    display: none; }
    @media only screen and (min-width: 1888px) {
      .header__hits .product:nth-child(3) {
        display: block; } }
    .header__hits .product:nth-child(3)::after {
      background: transparent; }
  .header__hits .collection__navigation {
    display: flex;
    position: relative;
    z-index: 22;
    flex-direction: column;
    justify-content: space-between;
    background: #FF0069;
    border-radius: 0.8rem; }
  .header__hits .collection__list:nth-child(2) {
    display: none; }
    @media only screen and (min-width: 1440px) {
      .header__hits .collection__list:nth-child(2) {
        display: block; } }
  .header__hits .collection__list:last-child {
    display: none; }
    @media only screen and (min-width: 1888px) {
      .header__hits .collection__list:last-child {
        display: block; } }
  .header__hits .collection__products {
    justify-content: center; }
  .header__hits .collection__lists {
    display: flex;
    justify-content: space-between; }

.main {
  margin-top: 2.5rem; }
  @media only screen and (min-width: 1240px) {
    .main {
      margin-top: 2rem; } }
  .main__stock {
    margin-left: 4rem; }
    @media only screen and (min-width: 1888px) {
      .main__stock {
        padding-left: 0;
        margin-left: 4.7rem; } }
    @media (min-width: 2000px) {
      .main__stock {
        margin: 0 auto; } }
    @media only screen and (min-width: 1888px) {
      .main__stock .goods__header {
        margin-right: 0.7rem; } }
    .main__stock .goods__list {
      margin-left: 13rem; }
      @media only screen and (min-width: 1240px) {
        .main__stock .goods__list {
          margin-left: 23.7rem;
          margin-top: 0.7rem; } }
      @media only screen and (min-width: 1440px) {
        .main__stock .goods__list {
          margin-left: 33.7rem; } }
      @media only screen and (min-width: 1888px) {
        .main__stock .goods__list {
          margin-left: 56.2rem; } }
    .main__stock .goods__controlers {
      margin-left: auto; }
    .main__stock .swiper-slide {
      opacity: 1; }
    .main__stock .swiper-container {
      overflow: visible; }
  .main__set {
    margin-top: 2.4rem; }
    .main__set .goods__header {
      padding-right: 1.6rem; }
    .main__set .goods__list {
      margin-left: 7rem; }
      @media only screen and (min-width: 1240px) {
        .main__set .goods__list {
          margin: 0.1rem 0 0 18.6rem; } }
      @media only screen and (min-width: 1440px) {
        .main__set .goods__list {
          margin-left: 27.8rem; } }
      @media only screen and (min-width: 1888px) {
        .main__set .goods__list {
          margin-left: 50.2rem; } }
  .main__notebook {
    display: none;
    justify-content: space-between;
    margin-top: 3.4rem; }
    @media only screen and (min-width: 1240px) {
      .main__notebook {
        display: flex;
        margin-top: 3rem; } }
    @media only screen and (min-width: 1888px) {
      .main__notebook {
        display: none; } }
  .main__internet {
    display: flex;
    justify-content: space-between;
    margin-top: 2.4rem; }
    @media only screen and (min-width: 1240px) {
      .main__internet {
        margin-top: 1.8rem; } }
    @media only screen and (min-width: 1888px) {
      .main__internet {
        justify-content: space-between;
        margin: 3.2rem auto 0 auto; } }
    @media only screen and (min-width: 1888px) {
      .main__internet .collection {
        margin-right: 0.8rem; } }
  .main__games {
    margin-top: 2.5rem;
    padding-left: 4rem; }
    .main__games .goods__list {
      margin: 0.5rem 0 0 14.5rem; }
      @media only screen and (min-width: 1240px) {
        .main__games .goods__list {
          margin-left: 25.3rem; } }
      @media only screen and (min-width: 1440px) {
        .main__games .goods__list {
          margin-left: 35.3rem; } }
      @media only screen and (min-width: 1888px) {
        .main__games .goods__list {
          margin-left: 50.2rem; } }
    .main__games .goods__controlers {
      margin-left: auto; }
      @media only screen and (min-width: 1240px) {
        .main__games .goods__controlers {
          margin-left: 13.7rem; } }
      @media only screen and (min-width: 1440px) {
        .main__games .goods__controlers {
          margin-left: 23.7rem; } }
      @media only screen and (min-width: 1888px) {
        .main__games .goods__controlers {
          margin-left: auto; } }
    .main__games .swiper-container {
      overflow: visible; }
  .main__buyers {
    margin-top: 2.5rem; }
    @media only screen and (min-width: 1240px) {
      .main__buyers {
        margin-top: 5.7rem; } }
    @media only screen and (min-width: 1888px) {
      .main__buyers {
        margin-top: 2.4rem; } }
    .main__buyers .goods__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1.6rem; }
    .main__buyers .goods__link {
      margin-top: 1.2rem; }
    .main__buyers .goods__content {
      justify-content: space-between; }
  .main__services {
    display: none;
    margin-top: 2.4rem;
    padding-left: 4rem; }
    @media only screen and (min-width: 1240px) {
      .main__services {
        display: block; } }
    .main__services .goods__list {
      margin-left: 26.1rem; }
      @media only screen and (min-width: 1440px) {
        .main__services .goods__list {
          margin-left: 36.1rem; } }
      @media only screen and (min-width: 1888px) {
        .main__services .goods__list {
          margin-left: 50.2rem; } }
    .main__services .goods__controlers {
      margin-left: auto; }
    .main__services .swiper-container {
      overflow: visible; }
    .main__services .card {
      display: none; }
      .main__services .card.visible {
        display: flex; }
  .main__review {
    margin: 4.1rem 0 0 4rem; }
    @media only screen and (min-width: 1888px) {
      .main__review {
        margin-left: 4.8rem; } }
    @media (min-width: 2000px) {
      .main__review {
        margin: 0 auto; } }
    .main__review .goods__header {
      justify-content: space-between; }
      @media only screen and (min-width: 1888px) {
        .main__review .goods__header {
          padding-right: 6.3rem; } }
    .main__review .goods__title {
      width: 20rem; }
    .main__review .swiper-container {
      overflow: visible; }
    .main__review .swiper-slide {
      opacity: 1; }

.main {
  margin-top: 2.5rem; }
  @media only screen and (min-width: 1240px) {
    .main {
      margin-top: 2rem; } }
  .main__stock {
    margin-left: 4rem; }
    @media only screen and (min-width: 1888px) {
      .main__stock {
        padding-left: 0;
        margin-left: 4.7rem; } }
    @media (min-width: 2000px) {
      .main__stock {
        margin: 0 auto; } }
    @media only screen and (min-width: 1888px) {
      .main__stock .goods__header {
        margin-right: 0.7rem; } }
    .main__stock .goods__list {
      margin-left: 13rem; }
      @media only screen and (min-width: 1240px) {
        .main__stock .goods__list {
          margin-left: 23.7rem;
          margin-top: 0.7rem; } }
      @media only screen and (min-width: 1440px) {
        .main__stock .goods__list {
          margin-left: 33.7rem; } }
      @media only screen and (min-width: 1888px) {
        .main__stock .goods__list {
          margin-left: 56.2rem; } }
    .main__stock .goods__controlers {
      margin-left: auto; }
    .main__stock .swiper-slide {
      opacity: 1; }
    .main__stock .swiper-container {
      overflow: visible; }
  .main__set {
    margin-top: 2.4rem; }
    .main__set .goods__header {
      padding-right: 1.6rem; }
    .main__set .goods__list {
      margin-left: 7rem; }
      @media only screen and (min-width: 1240px) {
        .main__set .goods__list {
          margin: 0.1rem 0 0 18.6rem; } }
      @media only screen and (min-width: 1440px) {
        .main__set .goods__list {
          margin-left: 27.8rem; } }
      @media only screen and (min-width: 1888px) {
        .main__set .goods__list {
          margin-left: 50.2rem; } }
  .main__notebook {
    display: none;
    justify-content: space-between;
    margin-top: 3.4rem; }
    @media only screen and (min-width: 1240px) {
      .main__notebook {
        display: flex;
        margin-top: 3rem; } }
    @media only screen and (min-width: 1888px) {
      .main__notebook {
        display: none; } }
  .main__internet {
    display: flex;
    justify-content: space-between;
    margin-top: 2.4rem; }
    @media only screen and (min-width: 1240px) {
      .main__internet {
        margin-top: 1.8rem; } }
    @media only screen and (min-width: 1888px) {
      .main__internet {
        justify-content: space-between;
        margin: 3.2rem auto 0 auto; } }
    @media only screen and (min-width: 1888px) {
      .main__internet .collection {
        margin-right: 0.8rem; } }
  .main__games {
    margin-top: 2.5rem;
    padding-left: 4rem; }
    .main__games .goods__list {
      margin: 0.5rem 0 0 14.5rem; }
      @media only screen and (min-width: 1240px) {
        .main__games .goods__list {
          margin-left: 25.3rem; } }
      @media only screen and (min-width: 1440px) {
        .main__games .goods__list {
          margin-left: 35.3rem; } }
      @media only screen and (min-width: 1888px) {
        .main__games .goods__list {
          margin-left: 50.2rem; } }
    .main__games .goods__controlers {
      margin-left: auto; }
      @media only screen and (min-width: 1240px) {
        .main__games .goods__controlers {
          margin-left: 13.7rem; } }
      @media only screen and (min-width: 1440px) {
        .main__games .goods__controlers {
          margin-left: 23.7rem; } }
      @media only screen and (min-width: 1888px) {
        .main__games .goods__controlers {
          margin-left: auto; } }
    .main__games .swiper-container {
      overflow: visible; }
  .main__buyers {
    margin-top: 2.5rem; }
    @media only screen and (min-width: 1240px) {
      .main__buyers {
        margin-top: 5.7rem; } }
    @media only screen and (min-width: 1888px) {
      .main__buyers {
        margin-top: 2.4rem; } }
    .main__buyers .goods__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1.6rem; }
    .main__buyers .goods__link {
      margin-top: 1.2rem; }
    .main__buyers .goods__content {
      justify-content: space-between; }
  .main__services {
    display: none;
    margin-top: 2.4rem;
    padding-left: 4rem; }
    @media only screen and (min-width: 1240px) {
      .main__services {
        display: block; } }
    .main__services .goods__list {
      margin-left: 26.1rem; }
      @media only screen and (min-width: 1440px) {
        .main__services .goods__list {
          margin-left: 36.1rem; } }
      @media only screen and (min-width: 1888px) {
        .main__services .goods__list {
          margin-left: 50.2rem; } }
    .main__services .goods__controlers {
      margin-left: auto; }
    .main__services .swiper-container {
      overflow: visible; }
    .main__services .card {
      display: none; }
      .main__services .card.visible {
        display: flex; }
  .main__review {
    margin: 4.1rem 0 0 4rem; }
    @media only screen and (min-width: 1888px) {
      .main__review {
        margin-left: 4.8rem; } }
    @media (min-width: 2000px) {
      .main__review {
        margin: 0 auto; } }
    .main__review .goods__header {
      justify-content: space-between; }
      @media only screen and (min-width: 1888px) {
        .main__review .goods__header {
          padding-right: 6.3rem; } }
    .main__review .goods__title {
      width: 20rem; }
    .main__review .swiper-container {
      overflow: visible; }
    .main__review .swiper-slide {
      opacity: 1; }

.menu {
  display: none;
  position: fixed;
  height: 78vh;
  left: 1.6rem;
  right: 1.6rem;
  top: 3.6rem;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  background: #ffffff;
  border-radius: 0.8rem;
  opacity: 0;
  transform: translate3d(0, 15px, 0);
  padding-top: 5.6rem; }
  .menu .js-scrollbar {
    height: calc(100% - 5.6rem);
    width: 100%; }
  .menu.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: .1s; }
  .menu__wrapper {
    display: flex;
    align-items: flex-start; }
  .menu__container {
    padding: 2.5rem 4rem 4.5rem 4rem; }
  .menu__content {
    flex-grow: 1;
    margin-right: 2.6rem; }
  .menu__navigation, .menu__adress-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem 0; }
  .menu__navigation {
    margin-top: -6rem; }
  .menu__adress-list {
    margin-top: -2.2rem; }
  .menu__inner {
    width: 43rem;
    display: block; }
  .menu__title {
    min-width: 22rem;
    letter-spacing: 0.1px;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .menu__title a {
      display: block;
      color: #6699FF;
      transition: all 0.3s ease; }
      .menu__title a:hover {
        opacity: 0.5;
        cursor: pointer; }
    .menu__title_margin {
      margin-bottom: 2.5rem; }
  .menu__list, .menu__adress-item {
    width: 33.333%;
    padding: 0 2rem 0;
    box-sizing: border-box; }
  .menu__adress-item {
    padding-top: 2.2rem; }
  .menu__list {
    padding-top: 7.6rem; }
    .menu__list svg {
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke: #ccc; }
    .menu__list .menu__title {
      margin-bottom: 2.3rem; }
  .menu__item:not(:last-child) {
    margin-bottom: 0.96rem; }
  .menu__link {
    font-size: 2rem;
    color: #6699FF;
    transition: all 0.3s ease; }
    .menu__link:hover {
      opacity: 0.5; }
    .menu__link_triangle {
      display: block;
      padding-top: 3px; }
      .menu__link_triangle svg {
        width: 1rem;
        height: 1rem;
        fill: #cccccc;
        transition: opacity 0.3s ease; }
      .menu__link_triangle:hover {
        opacity: 1; }
  .menu__icon {
    height: 2rem;
    margin-bottom: 1.8rem; }
  .menu__card {
    display: block;
    position: relative;
    width: 43.1rem;
    height: 37.6rem;
    margin-top: 1.6rem;
    background: url("../images/menu-card.jpg") no-repeat;
    border-radius: 0.8rem;
    overflow: hidden;
    transition: transform 0.3s ease; }
    .menu__card:hover {
      transform: translateY(-2px); }
  .menu__circle {
    position: absolute;
    width: 56rem;
    height: 56rem;
    z-index: 2;
    border-radius: 50%;
    background: #fbfbfb;
    bottom: -30.4rem;
    left: -27rem; }
  .menu__device {
    position: relative;
    margin: -2.7rem 0 0 16rem; }
    .menu__device img:first-child {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      top: 1.6rem;
      right: 12.6rem; }
    .menu__device img:last-child {
      position: absolute;
      width: 3rem;
      height: 3.8rem;
      bottom: 54%;
      left: 12px; }
  .menu__caption {
    margin: 1.9rem 0 0 2.4rem;
    font-size: 2.4rem;
    font-family: "NeoSansPro", sans-serif;
    color: #ffffff; }
  .menu__details {
    margin: -16.4rem 0 0 2.4rem;
    position: relative;
    width: 18.3rem;
    z-index: 7; }
  .menu__promo {
    margin-bottom: 0.8rem;
    font-size: 2.4rem;
    font-family: "NeoSansPro", sans-serif;
    line-height: 1.3; }
  .menu__footer {
    margin-top: 11.6rem; }
  .menu__activation {
    font-size: 1.6rem;
    color: #666666;
    opacity: 0.8; }
  .menu__user-block {
    position: relative;
    padding-top: 5.8rem; }
  .menu__authors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.1rem; }
  .menu__about {
    width: 50%;
    margin-top: 9.1rem; }
    .menu__about .socials {
      display: flex;
      margin: 4.2rem 0 0 0; }
      @media only screen and (min-width: 1240px) {
        .menu__about .socials {
          display: none; } }
  .menu__city {
    margin-bottom: 2.5rem;
    position: relative; }
  .menu__city-text {
    position: relative;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    color: #6699FF;
    transition: opacity 0.3s ease; }
    .menu__city-text:hover {
      opacity: 0.5;
      cursor: pointer; }
    .menu__city-text::after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: -2.1rem;
      top: 55%;
      transform: translateY(-50%);
      background: url("../images/location-pin.svg") no-repeat;
      background-position: 100%; }
  .menu__company {
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
  .menu__descr {
    max-width: 43rem;
    line-height: 1.2;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
    @media only screen and (min-width: 1240px) {
      .menu__descr {
        max-width: 60rem; } }
  .menu__contacts {
    max-width: 55rem;
    margin-right: -0.6rem;
    margin-top: -1px; }
  .menu__adress-box {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px; }
    .menu__adress-box .menu__title {
      margin-bottom: 2.8rem; }
  .menu__adress-item {
    font-size: 0; }
  .menu__adress {
    font-size: 2rem; }
  .menu__phones {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-right: 0.9rem;
    width: 29.5rem; }
    @media only screen and (min-width: 1240px) {
      .menu__phones {
        width: 31.5rem; } }
  .menu__phone {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777;
    color: rgba(0, 0, 0, 0.4); }
  .menu__tel {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000000; }
  .menu__metro {
    position: relative;
    padding-left: 1.7rem;
    line-height: 1;
    font-size: 1.2rem;
    color: #666;
    font-weight: 400; }
    .menu__metro::before {
      position: absolute;
      content: 'М';
      text-align: center;
      width: 1.2rem;
      height: 1.2rem;
      top: 0;
      left: 0;
      line-height: 1.2rem;
      background: #6699FF;
      border-radius: 50%;
      color: #ffffff;
      font-family: "NeoSansPro", sans-serif;
      font-size: 0.8rem; }
    .menu__metro_red::before {
      background: #FF0069; }
    .menu__metro_green::before {
      background: #8ABE56; }
  .menu__street {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: -0.1px;
    line-height: 1.3; }
  .menu__time {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1;
    margin-top: .7rem;
    display: block; }
  .menu__more {
    margin-top: 1.4rem;
    font-size: 2rem;
    color: #6699FF;
    transition: all 0.3s ease; }
    .menu__more:hover {
      opacity: 0.5; }
    .menu__more svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-left: 1.3rem;
      fill: #ccc; }
  .menu_isActive {
    padding-bottom: 5rem;
    overflow: hidden; }
    .menu_isActive .user-info__link_phone .link__text {
      color: #000000; }
    .menu_isActive .user-info__icon svg {
      stroke: rgba(0, 0, 0, 0.5); }
  .menu .modal-city {
    left: 0rem; }
    .menu .modal-city::before {
      left: 1.4rem; }

.menu {
  display: none;
  position: fixed;
  height: 78vh;
  left: 1.6rem;
  right: 1.6rem;
  top: 3.6rem;
  overflow: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  background: #ffffff;
  border-radius: 0.8rem;
  opacity: 0;
  transform: translate3d(0, 15px, 0);
  padding-top: 5.6rem; }
  .menu .js-scrollbar {
    height: calc(100% - 5.6rem);
    width: 100%; }
  .menu.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition-delay: .1s; }
  .menu__wrapper {
    display: flex;
    align-items: flex-start; }
  .menu__container {
    padding: 2.5rem 4rem 4.5rem 4rem; }
  .menu__content {
    flex-grow: 1;
    margin-right: 2.6rem; }
  .menu__navigation, .menu__adress-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem 0; }
  .menu__navigation {
    margin-top: -6rem; }
  .menu__adress-list {
    margin-top: -2.2rem; }
  .menu__inner {
    width: 43rem;
    display: block; }
  .menu__title {
    min-width: 22rem;
    letter-spacing: 0.1px;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400; }
    .menu__title a {
      display: block;
      color: #6699FF;
      transition: all 0.3s ease; }
      .menu__title a:hover {
        opacity: 0.5;
        cursor: pointer; }
    .menu__title_margin {
      margin-bottom: 2.5rem; }
  .menu__list, .menu__adress-item {
    width: 33.333%;
    padding: 0 2rem 0;
    box-sizing: border-box; }
  .menu__adress-item {
    padding-top: 2.2rem; }
  .menu__list {
    padding-top: 7.6rem; }
    .menu__list svg {
      width: 2rem;
      height: 2rem;
      fill: none;
      stroke: #ccc; }
    .menu__list .menu__title {
      margin-bottom: 2.3rem; }
  .menu__item:not(:last-child) {
    margin-bottom: 0.96rem; }
  .menu__link {
    font-size: 2rem;
    color: #6699FF;
    transition: all 0.3s ease; }
    .menu__link:hover {
      opacity: 0.5; }
    .menu__link_triangle {
      display: block;
      padding-top: 3px; }
      .menu__link_triangle svg {
        width: 1rem;
        height: 1rem;
        fill: #cccccc;
        transition: opacity 0.3s ease; }
      .menu__link_triangle:hover {
        opacity: 1; }
  .menu__icon {
    height: 2rem;
    margin-bottom: 1.8rem; }
  .menu__card {
    display: block;
    position: relative;
    width: 43.1rem;
    height: 37.6rem;
    margin-top: 1.6rem;
    background: url("../images/menu-card.jpg") no-repeat;
    border-radius: 0.8rem;
    overflow: hidden;
    transition: transform 0.3s ease; }
    .menu__card:hover {
      transform: translateY(-2px); }
  .menu__circle {
    position: absolute;
    width: 56rem;
    height: 56rem;
    z-index: 2;
    border-radius: 50%;
    background: #fbfbfb;
    bottom: -30.4rem;
    left: -27rem; }
  .menu__device {
    position: relative;
    margin: -2.7rem 0 0 16rem; }
    .menu__device img:first-child {
      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      top: 1.6rem;
      right: 12.6rem; }
    .menu__device img:last-child {
      position: absolute;
      width: 3rem;
      height: 3.8rem;
      bottom: 54%;
      left: 12px; }
  .menu__caption {
    margin: 1.9rem 0 0 2.4rem;
    font-size: 2.4rem;
    font-family: "NeoSansPro", sans-serif;
    color: #ffffff; }
  .menu__details {
    margin: -16.4rem 0 0 2.4rem;
    position: relative;
    width: 18.3rem;
    z-index: 7; }
  .menu__promo {
    margin-bottom: 0.8rem;
    font-size: 2.4rem;
    font-family: "NeoSansPro", sans-serif;
    line-height: 1.3; }
  .menu__footer {
    margin-top: 11.6rem; }
  .menu__activation {
    font-size: 1.6rem;
    color: #666666;
    opacity: 0.8; }
  .menu__user-block {
    position: relative;
    padding-top: 5.8rem; }
  .menu__authors {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5.1rem; }
  .menu__about {
    width: 50%;
    margin-top: 9.1rem; }
    .menu__about .socials {
      display: flex;
      margin: 4.2rem 0 0 0; }
      @media only screen and (min-width: 1240px) {
        .menu__about .socials {
          display: none; } }
  .menu__city {
    margin-bottom: 2.5rem;
    position: relative; }
  .menu__city-text {
    position: relative;
    font-family: "NeoSansPro", sans-serif;
    font-size: 2.4rem;
    line-height: normal;
    font-weight: 400;
    color: #6699FF;
    transition: opacity 0.3s ease; }
    .menu__city-text:hover {
      opacity: 0.5;
      cursor: pointer; }
    .menu__city-text::after {
      content: "";
      display: block;
      position: absolute;
      width: 1rem;
      height: 1rem;
      right: -2.1rem;
      top: 55%;
      transform: translateY(-50%);
      background: url("../images/location-pin.svg") no-repeat;
      background-position: 100%; }
  .menu__company {
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
  .menu__descr {
    max-width: 43rem;
    line-height: 1.2;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777; }
    @media only screen and (min-width: 1240px) {
      .menu__descr {
        max-width: 60rem; } }
  .menu__contacts {
    max-width: 55rem;
    margin-right: -0.6rem;
    margin-top: -1px; }
  .menu__adress-box {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: -0.2px; }
    .menu__adress-box .menu__title {
      margin-bottom: 2.8rem; }
  .menu__adress-item {
    font-size: 0; }
  .menu__adress {
    font-size: 2rem; }
  .menu__phones {
    display: flex;
    justify-content: space-between;
    margin-top: 1.6rem;
    margin-right: 0.9rem;
    width: 29.5rem; }
    @media only screen and (min-width: 1240px) {
      .menu__phones {
        width: 31.5rem; } }
  .menu__phone {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
    color: #777777;
    color: rgba(0, 0, 0, 0.4); }
  .menu__tel {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    color: #000000; }
  .menu__metro {
    position: relative;
    padding-left: 1.7rem;
    line-height: 1;
    font-size: 1.2rem;
    color: #666;
    font-weight: 400; }
    .menu__metro::before {
      position: absolute;
      content: 'М';
      text-align: center;
      width: 1.2rem;
      height: 1.2rem;
      top: 0;
      left: 0;
      line-height: 1.2rem;
      background: #6699FF;
      border-radius: 50%;
      color: #ffffff;
      font-family: "NeoSansPro", sans-serif;
      font-size: 0.8rem; }
    .menu__metro_red::before {
      background: #FF0069; }
    .menu__metro_green::before {
      background: #8ABE56; }
  .menu__street {
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: -0.1px;
    line-height: 1.3; }
  .menu__time {
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.1px;
    line-height: 1;
    margin-top: .7rem;
    display: block; }
  .menu__more {
    margin-top: 1.4rem;
    font-size: 2rem;
    color: #6699FF;
    transition: all 0.3s ease; }
    .menu__more:hover {
      opacity: 0.5; }
    .menu__more svg {
      width: 1.2rem;
      height: 1.2rem;
      margin-left: 1.3rem;
      fill: #ccc; }
  .menu_isActive {
    padding-bottom: 5rem;
    overflow: hidden; }
    .menu_isActive .user-info__link_phone .link__text {
      color: #000000; }
    .menu_isActive .user-info__icon svg {
      stroke: rgba(0, 0, 0, 0.5); }
  .menu .modal-city {
    left: 0rem; }
    .menu .modal-city::before {
      left: 1.4rem; }

.ui-kit {
  display: block;
  color: #222; }
  .ui-kit__navigation {
    position: fixed;
    right: 100%;
    top: 0;
    height: 100vh;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    z-index: 10;
    padding: 20px;
    background-color: white;
    transform: translate3d(15%, 0, 0);
    width: 240px;
    opacity: .5;
    transition: opacity 0.3s ease, transform 0.3s ease;
    overflow: auto;
    box-sizing: border-box; }
    .ui-kit__navigation-burger {
      position: absolute;
      width: 18px;
      height: 14px;
      box-sizing: border-box;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      top: 9px;
      right: 9px;
      opacity: 1;
      transition: opacity 0.3s ease; }
      .ui-kit__navigation-burger::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background-color: black; }
    .ui-kit__navigation:hover {
      transform: translate3d(100%, 0, 0);
      opacity: 1; }
      .ui-kit__navigation:hover .ui-kit__navigation-burger {
        opacity: 0; }
    .ui-kit__navigation li {
      font-size: 14px;
      line-height: 1.65;
      font-weight: 400; }
      .ui-kit__navigation li a {
        color: #2252d8;
        border: 0;
        transition: fill 0.3s ease, color 0.3s ease, opacity 0.3s ease, border-color 0.3s ease;
        fill: #2252d8; }
        .ui-kit__navigation li a__text {
          border-bottom: 1px solid;
          border-color: inherit; }
        .ui-kit__navigation li a:hover {
          opacity: .5; }
      .ui-kit__navigation li:not(:last-child) {
        margin-bottom: 5px; }
  .ui-kit__head {
    padding-top: 40px;
    padding-bottom: 20px; }
  .ui-kit__name {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.4; }
  .ui-kit__description {
    font-size: 13px;
    line-height: 1.4; }
    .ui-kit__description:not(:first-child) {
      margin-top: 30px; }
    .ui-kit__description:not(:last-child) {
      margin-bottom: 10px; }
  .ui-kit__special {
    padding: 20px;
    border: 1px solid #ccc; }
  .ui-kit__logo {
    max-height: 80px;
    font-size: 20px;
    text-align: left; }
    .ui-kit__logo svg {
      height: 100%; }
    .ui-kit__logo + .ui-kit__name {
      margin-top: 20px; }
  .ui-kit__elements-list {
    margin-top: -20px;
    font-size: 0; }
    .ui-kit__elements-list > * {
      margin-right: 20px;
      margin-top: 20px; }
  .ui-kit__section {
    padding-top: 50px;
    padding-bottom: 50px; }
    .ui-kit__section_dark {
      background-color: #111;
      color: white; }
    .ui-kit__section:not(:last-child) {
      border-bottom: 1px solid #ccc; }
    .ui-kit__section-head {
      margin-bottom: 30px;
      text-align: left; }
      .ui-kit__section-head h1, .ui-kit__section-head h2, .ui-kit__section-head h3, .ui-kit__section-head h4, .ui-kit__section-head h5, .ui-kit__section-head h6 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.4; }
        .ui-kit__section-head h1:not(:first-child), .ui-kit__section-head h2:not(:first-child), .ui-kit__section-head h3:not(:first-child), .ui-kit__section-head h4:not(:first-child), .ui-kit__section-head h5:not(:first-child), .ui-kit__section-head h6:not(:first-child) {
          margin-top: 20px; }
        .ui-kit__section-head h1:not(:last-child), .ui-kit__section-head h2:not(:last-child), .ui-kit__section-head h3:not(:last-child), .ui-kit__section-head h4:not(:last-child), .ui-kit__section-head h5:not(:last-child), .ui-kit__section-head h6:not(:last-child) {
          margin-bottom: 20px; }
      .ui-kit__section-head p {
        font-size: 16px;
        line-height: 1.5; }
        .ui-kit__section-head p:not(:last-child) {
          margin-bottom: 10px; }
    .ui-kit__section_grid .grid__col::after {
      content: '';
      display: block;
      height: 40px;
      background-color: #ccc; }
  .ui-kit__ui-popups {
    margin-top: 30px; }
    .ui-kit__ui-popups .popup {
      display: block !important;
      border: 1px solid #ccc; }
      .ui-kit__ui-popups .popup:not(:first-child) {
        margin-top: 30px; }
  .ui-kit__scrolldemo {
    border: 1px solid #ccc;
    max-height: 400px; }
    .ui-kit__scrolldemo-block {
      height: 1000px; }
  .ui-kit__color {
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1); }
    .ui-kit__color_bg_dark {
      color: white; }
    .ui-kit__color_gradient_main {
      background: linear-gradient(0deg, #2252d8, #ccc); }
  .ui-kit__custom-text_style-1 {
    font-size: 14px;
    line-height: 1.65;
    font-weight: 400; }
  .ui-kit__custom-text:not(:last-child) {
    margin-bottom: 10px; }

.ui-kit {
  display: block;
  color: #222; }
  .ui-kit__navigation {
    position: fixed;
    right: 100%;
    top: 0;
    height: 100vh;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    z-index: 10;
    padding: 20px;
    background-color: white;
    transform: translate3d(15%, 0, 0);
    width: 240px;
    opacity: .5;
    transition: opacity 0.3s ease, transform 0.3s ease;
    overflow: auto;
    box-sizing: border-box; }
    .ui-kit__navigation-burger {
      position: absolute;
      width: 18px;
      height: 14px;
      box-sizing: border-box;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      top: 9px;
      right: 9px;
      opacity: 1;
      transition: opacity 0.3s ease; }
      .ui-kit__navigation-burger::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 2px;
        background-color: black; }
    .ui-kit__navigation:hover {
      transform: translate3d(100%, 0, 0);
      opacity: 1; }
      .ui-kit__navigation:hover .ui-kit__navigation-burger {
        opacity: 0; }
    .ui-kit__navigation li {
      font-size: 14px;
      line-height: 1.65;
      font-weight: 400; }
      .ui-kit__navigation li a {
        color: #2252d8;
        border: 0;
        transition: fill 0.3s ease, color 0.3s ease, opacity 0.3s ease, border-color 0.3s ease;
        fill: #2252d8; }
        .ui-kit__navigation li a__text {
          border-bottom: 1px solid;
          border-color: inherit; }
        .ui-kit__navigation li a:hover {
          opacity: .5; }
      .ui-kit__navigation li:not(:last-child) {
        margin-bottom: 5px; }
  .ui-kit__head {
    padding-top: 40px;
    padding-bottom: 20px; }
  .ui-kit__name {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.4; }
  .ui-kit__description {
    font-size: 13px;
    line-height: 1.4; }
    .ui-kit__description:not(:first-child) {
      margin-top: 30px; }
    .ui-kit__description:not(:last-child) {
      margin-bottom: 10px; }
  .ui-kit__special {
    padding: 20px;
    border: 1px solid #ccc; }
  .ui-kit__logo {
    max-height: 80px;
    font-size: 20px;
    text-align: left; }
    .ui-kit__logo svg {
      height: 100%; }
    .ui-kit__logo + .ui-kit__name {
      margin-top: 20px; }
  .ui-kit__elements-list {
    margin-top: -20px;
    font-size: 0; }
    .ui-kit__elements-list > * {
      margin-right: 20px;
      margin-top: 20px; }
  .ui-kit__section {
    padding-top: 50px;
    padding-bottom: 50px; }
    .ui-kit__section_dark {
      background-color: #111;
      color: white; }
    .ui-kit__section:not(:last-child) {
      border-bottom: 1px solid #ccc; }
    .ui-kit__section-head {
      margin-bottom: 30px;
      text-align: left; }
      .ui-kit__section-head h1, .ui-kit__section-head h2, .ui-kit__section-head h3, .ui-kit__section-head h4, .ui-kit__section-head h5, .ui-kit__section-head h6 {
        font-size: 30px;
        font-weight: 600;
        line-height: 1.4; }
        .ui-kit__section-head h1:not(:first-child), .ui-kit__section-head h2:not(:first-child), .ui-kit__section-head h3:not(:first-child), .ui-kit__section-head h4:not(:first-child), .ui-kit__section-head h5:not(:first-child), .ui-kit__section-head h6:not(:first-child) {
          margin-top: 20px; }
        .ui-kit__section-head h1:not(:last-child), .ui-kit__section-head h2:not(:last-child), .ui-kit__section-head h3:not(:last-child), .ui-kit__section-head h4:not(:last-child), .ui-kit__section-head h5:not(:last-child), .ui-kit__section-head h6:not(:last-child) {
          margin-bottom: 20px; }
      .ui-kit__section-head p {
        font-size: 16px;
        line-height: 1.5; }
        .ui-kit__section-head p:not(:last-child) {
          margin-bottom: 10px; }
    .ui-kit__section_grid .grid__col::after {
      content: '';
      display: block;
      height: 40px;
      background-color: #ccc; }
  .ui-kit__ui-popups {
    margin-top: 30px; }
    .ui-kit__ui-popups .popup {
      display: block !important;
      border: 1px solid #ccc; }
      .ui-kit__ui-popups .popup:not(:first-child) {
        margin-top: 30px; }
  .ui-kit__scrolldemo {
    border: 1px solid #ccc;
    max-height: 400px; }
    .ui-kit__scrolldemo-block {
      height: 1000px; }
  .ui-kit__color {
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1); }
    .ui-kit__color_bg_dark {
      color: white; }
    .ui-kit__color_gradient_main {
      background: linear-gradient(0deg, #2252d8, #ccc); }
  .ui-kit__custom-text_style-1 {
    font-size: 14px;
    line-height: 1.65;
    font-weight: 400; }
  .ui-kit__custom-text:not(:last-child) {
    margin-bottom: 10px; }

.index {
  display: block;
  height: 100000px; }

.overlay, .overlay_light {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
  transition-delay: .1s; }
  .overlay.is-top, .overlay_light.is-top {
    z-index: 0; }
    .header.is-fixed .overlay.is-top, .header.is-fixed .overlay_light.is-top {
      z-index: -1; }
  .overlay.is-active, .overlay_light.is-active {
    opacity: 1;
    transition-delay: 0s; }

.overlay {
  background: rgba(0, 0, 0, 0.9); }

.overlay_light {
  background: rgba(0, 0, 0, 0.5); }

.index {
  display: block;
  height: 100000px; }

.overlay, .overlay_light {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease;
  transition-delay: .1s; }
  .overlay.is-top, .overlay_light.is-top {
    z-index: 0; }
    .header.is-fixed .overlay.is-top, .header.is-fixed .overlay_light.is-top {
      z-index: -1; }
  .overlay.is-active, .overlay_light.is-active {
    opacity: 1;
    transition-delay: 0s; }

.overlay {
  background: rgba(0, 0, 0, 0.9); }

.overlay_light {
  background: rgba(0, 0, 0, 0.5); }

.sitemap {
  display: block;
  padding: 60px 0; }
  .sitemap__list {
    list-style: none; }
    .sitemap__list a {
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 1.65;
      font-weight: 400; }
      .sitemap__list a:not(:last-child) {
        margin-bottom: 10px; }

.sitemap {
  display: block;
  padding: 60px 0; }
  .sitemap__list {
    list-style: none; }
    .sitemap__list a {
      display: block;
      width: 100%;
      font-size: 14px;
      line-height: 1.65;
      font-weight: 400; }
      .sitemap__list a:not(:last-child) {
        margin-bottom: 10px; }

body {
  padding-top: 0.5px; }
  body::before {
    content: '';
    background: linear-gradient(180deg, white 44.91%, #eeeeee 100%);
    height: 94px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }

.container {
  padding-left: 5.6rem;
  padding-right: 5.6rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box; }
  @media only screen and (min-width: 1888px) {
    .container {
      width: 188rem; }
      .container__promo {
        padding: 0 1.2rem; } }
  .container_main {
    padding: 0 4rem 0 4rem; }
    @media only screen and (min-width: 1888px) {
      .container_main {
        padding: 0 4.7rem; } }
  .container_footer {
    padding: 0 5.6rem; }
    @media only screen and (min-width: 1240px) {
      .container_footer {
        display: flex;
        flex-wrap: wrap; } }
    @media only screen and (min-width: 1440px) {
      .container_footer {
        justify-content: space-between; } }
    @media only screen and (min-width: 1888px) {
      .container_footer {
        padding: 0 6.4rem; } }
  .container_promo {
    max-width: 190rem;
    padding: 0 1.6rem;
    margin: 0 auto; }
    @media only screen and (min-width: 1888px) {
      .container_promo {
        padding: 0 1.6rem; } }
    @media (min-width: 1920px) {
      .container_promo {
        padding: 0 2.2rem; } }

.js-sr {
  visibility: hidden; }
