.modal-city, .modal-support, .modal-entrance, .modal-feedback, .modal-product {
  opacity: 0;
  transition: opacity $ease-main, transform $ease-main;
  overflow: visible !important;
  z-index: 2000000;
  transform: translate3d(0, 15px, 0);

  &.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.modal-product {
  transform: translate3d(0, -15px, 0);
}

.modal-support, .modal-city, .modal-time, .modal-entrance, .modal-feedback {
  top: 100%;
  margin-top: 1.7rem;
}

.modal-product {
  bottom: 100%;
  margin-bottom: 1rem;
}

.modal-support, .modal-feedback {
  left: 0;
}

.modal-city, .modal-entrance, .modal-product {
  right: 0;
}

.modal-time, .modal-city, .modal-support, .modal-entrance, .modal-feedback, .modal-product {
  &::before {
    @include pseudo;

    width: 1.6rem;
    height: 1.2rem;
    right: 50%;
    top: -0.5rem;
    background: $color-white;
    transform: rotate(45deg);
  }
}

.modal-city {
  display: none;
  position: absolute;
  width: 63.2rem;
  padding: 1.6rem 1.6rem 2.3rem 1.6rem;
  border-radius: 0.8rem;
  background: $color-white;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);

  &::before {
    right: 1.4rem;
  }

  &__alert-box {
    margin-top: 1rem;
    color: red;
  }

  &__btn {
    position: absolute;
    width: 2rem;
    height: 2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 2.2rem;
    transition: opacity $ease-main;

    svg {
      width: 100%;
      height: 100%;
      stroke: $color-black;
      fill: none;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__group {
    position: relative;
  }

  &__input {
    padding-right: 6rem;
  }

  &__title {
    margin: 2.8rem 0 2.2rem 0.8rem;

    font-family: $font-second;
    font-weight: 400;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.9rem;
  }

  &__city {
    width: 31%;
    margin-bottom: 0.1rem;
  }

  &__text {
    position: relative;

    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.6;

    transition: all ease .3s;

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    &_selected {
      color: $color-red;

      &::before {
        @include pseudo;

        width: 1rem;
        height: 1rem;

        right: -2.1rem;
        top: 55%;

        transform: translateY(-50%);

        background: url('../images/location-pin.svg') no-repeat;
        background-position: right center;
      }
    }
  }
}

.modal-time {
  pointer-events: none;
  position: absolute;
  width: 19.2rem;
  padding: 1.5rem 1.6rem 2.2rem 1.6rem;
  left: 50%;
  border-radius: 0.8rem;
  background: $color-white;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  opacity: 0;
  transform: translate3d(-50%, 15px, 0);
  transition: opacity $ease-main, transform $ease-main;
  overflow: visible !important;
  z-index: 2000000;

  .js-time:hover & {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }

  &::before {
    transform: translateX(50%) rotate(45deg);
  }

  &__title {
    margin-bottom: 0.5rem;

    @include heading-4;
    color: $color-grey-header-first;
  }

  &__time {
    display: block;
    margin-bottom: 1.8rem;

    @include heading-5;
  }

  &__text {
    @include heading-5;
  }
}

.modal-product {
  display: none;
  position: absolute;
  width: 100%;
  padding: 1.4rem 1.1rem 1.5rem 1.1rem;
  background: $color-white;
  border-radius: 0.8rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.2);

  button {
    padding-left: 3.5rem;

    @include heading-9;
    color: $color-black;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__compare {
    margin-bottom: 1px;
    background: url('../images/rings.svg') no-repeat;
    background-position: 0 center;
  }

  &__favourites {
    background: url('../images/heart.svg') no-repeat;
    background-position: 2px center;
  }

  &::before {
    right: 1rem;
    bottom: -0.5rem;
    top: unset;
  }
}

.modal-entrance {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.8rem 1.6rem 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  background: $color-white;
  margin-right: -1rem;
  cursor: initial;
  color: black;

  $b: &;

  &::before {
    right: 1.4rem;
  }

  &_static {
    display: block;
    position: static;
    transform: none;
    opacity: 1;
    box-shadow: none;
    margin: 0;
    width: auto;

    &::before {
      display: none;
    }
  }

  &_blue {
    background: $color-dark-blue-product;
    color: white;

    .switcher__circle {
      background: $color-white;
    }

    .switcher__mover {
      background: $color-white;
    }
  }

  &__message {
    margin-bottom: 2rem;
  }

  &__entrance-error {
    display: none;
    margin-bottom: 2.4rem;

    @include heading-5;
    font-size: 1.6rem;
  }

  &__title {
    margin: 0 0 2.7rem 1rem;

    @include heading-3;

    #{$b}_blue & {
      color: $color-white;
    }
  }

  &__text {
    margin-bottom: 0.5rem;
  }

  &__group {
    position: relative;
    margin-bottom: 1.2rem;

    &_remember {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.4rem 1rem 1.6rem;
      border: 1px solid rgba(black, .1);
      border-radius: 0.6rem;

      #{$b}_blue & {
        border-color: rgba(white, .1);
      }

      .modal-entrance__text {
        margin-bottom: 0;
      }
    }
  }

  &__input {
    @include heading-5;

    font-size: 1.6rem;
    color: $color-black;
    padding: 0 4rem 0 1.7rem;
    border-radius: .6rem;
    border: 1px solid #f2f2f2;
    transition: background-color $ease-main, color $ease-main, border-color $ease-main;

    &::placeholder {
      transition: color $ease-main;
    }

    &.error {
      border-color: $color-red !important;
      color: $color-red;

      &::placeholder {
        color: $color-red;
      }
    }

    #{$b}_blue & {
      background-color: rgba(white, .1);
      border-color: $color-dark-blue-product;
      color: $color-white;

      &::placeholder {
        color: $color-white;
      }

      &.error {
        background-color: transparent;
        color: white;

        &::placeholder {
          color: $color-white;
        }
      }
    }
  }

  &__remember {
    background: transparent;
    font-size: 1.6rem;
    border: 1px solid rgba(0, 0, 0, 0.1);

    #{$b}_blue & {
      border-color: rgba(white, .1);
    }
  }

  &__pass {
    position: absolute;
    width: 1.9rem;
    height: 1.1rem;
    top: 1.5rem;
    right: 1.2rem;
    transition: opacity $ease-main;
    cursor: pointer;
    font-size: 0;

    &:hover {
      opacity: 0.5;
    }

    svg {
      width: 100%;
      height: 100%;
      fill: none !important;
      stroke: $color-blue-product !important;

      #{$b}_blue & {
        stroke: $color-white !important;
      }
    }
  }

  &__btn {
    @include heading-5;

    min-width: 16.4rem;
    margin: 1.2rem 0 0 1rem;
    padding: 0.3rem 1.5rem;
    box-sizing: border-box;
    text-align: center;
    color: $color-white;
    background: $color-blue-product;
    border-radius: 5rem;
    transition: opacity $ease-main;

    #{$b}_blue & {
      background: white;
      color: black;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__details {
    margin: 3rem 0 0 1rem;
  }

  &__info {
    @include heading-5;

    margin-bottom: 1.8rem;
  }

  &__link {
    color: $color-blue-product;
    transition: opacity $ease-main, color $ease-main;

    #{$b}_blue & {
      color: $color-white;
    }

    &_red {
      color: $color-red !important;
    }

    &:hover, &:active {
      opacity: 0.5;
    }
  }

  &__registration, &__restore, &__confirmation, &__entrance {
    display: none;
    opacity: 0;
    transform: translate3d(0, 10px, 0);
    transition: opacity $ease-main, transform $ease-main;

    &.is-active {
      display: block;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}

.modal-account {
  display: none;
  position: absolute;
  width: 43rem;
  min-height: 39.7rem;
  padding: 2.1rem 1.6rem 1.8rem 1.6rem;
  z-index: 112200;
  right: 5rem;
  top: 6rem;

  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);

  background: $color-white;

  &::before {
    @include pseudo;

    width: 1.6rem;
    height: 1.2rem;

    right: 1.4rem;
    top: -0.5rem;

    background: $color-white;

    transform: rotate(45deg);
  }

  &__profile {
    margin-left: 0.8rem;
  }

  &__title {
    position: relative;

    font-size: 1.6rem;
    opacity: 0.7;

    &::after {
      @include pseudo;

      width: 1rem;
      height: 0.6rem;
      right: -1.9rem;
      top: 0.8rem;

      background: url('../images/triangle.svg') no-repeat;
      opacity: 0.7;
    }

    &_active::after {
      right: -1.5rem;
      top: 0.7rem;

      transform: rotate(180deg);
    }
  }

  &__accounts {
    margin-top: 0.3rem;
  }

  &__account-name {
    display: none;

    &:not(:last-child) {
      margin-bottom: 1.1rem;
    }

    font-family: $font-second;
    font-size: 2.4rem;
    color: $color-black;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }

    &_active {
      display: block;

      &:hover {
        opacity: 1;
      }
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
    padding: 0 0.8rem;
  }

  &__list {
    width: 18rem;
  }

  &__item {
    margin-bottom: 0.8rem;
  }

  &__link {
    font-size: 1.8rem;
    line-height: normal;
    color: $color-blue-product;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__user-block {
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 0 4.3rem 0;
    padding: 0 0.8rem;
  }

  &__points {
    width: 18rem;

    font-size: 1.8rem;
    line-height: normal
  }

  &__count {
    display: block;
    margin-top: 0.6rem;

    font-family: $font-second;
    font-size: 1.6rem;
    letter-spacing: 0.3px;
    color: $color-promo-first;
  }

  &__exit {
    width: 18rem;

    font-size: 1.8rem;
    line-height: normal;
    color: $color-black;
    transition: all $ease-main;

    &:hover {
      color: $color-red;
    }
  }

  &__mailing {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem 1rem 1.6rem;
    margin-bottom: 1.2rem;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
  }

  &__notification {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.4rem 1rem 1.6rem;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
  }
}

.modal-support {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.7rem 1.6rem 0 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, .2);
  background: $color-white;

  &::before {
    left: 1.4rem;
  }

  &__title {
    margin: 0 0 2.6rem 0.9rem;

    @include heading-3;
  }

  &__group {
    position: relative;
    margin-bottom: 1.2rem;

    input {
      padding: 0 4rem 0 1.7rem;
    }

    textarea {
      min-height: 9.2rem;
      padding-left: 1.6rem;

      overflow: hidden;
    }
  }

  &__btn {
    width: 16.4rem;
    margin: 0.9rem 0 0 0.8rem;
    padding: 0.3rem 0 0.3rem 0;
    text-align: center;

    @include heading-5;

    color: $color-white;
    background: $color-blue-product;
    border-radius: 1.6rem;
    transition: opacity $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__details {
    margin: 3rem 0 2rem 0.9rem;
  }

  &__text {
    width: 70%;
  }

  &__link {
    display: block;
    margin-top: 0.4rem;

    color: $color-black;
    transition: all $ease-main;

    &:last-child {
      color: $color-red;
    }

    &:first-child {
      margin-bottom: 1.8rem;
    }

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }

  input {
    border: 1px solid #f2f2f2;
  }

  textarea {
    border: 1px solid #f2f2f2;
  }
}

.modal-feedback {
  display: none;
  position: absolute;
  width: 36rem;
  padding: 1.7rem 1.6rem 0 1.6rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 2.4rem 0 rgba(0, 0, 0, .2);
  background: $color-white;

  &::before {
    left: 1.4rem;
  }

  &__title {
    margin: 0 0 2.6rem 0.9rem;

    @include heading-3;
  }

  &__group {
    position: relative;
    margin-bottom: 1.2rem;

    input {
      padding: 0 4rem 0 1.7rem;
    }

    textarea {
      min-height: 9.2rem;
      padding-left: 1.6rem;

      overflow: hidden;
    }
  }

  &__btn {
    width: 16.4rem;
    margin: 0.9rem 0 0 0.8rem;
    padding: 0.3rem 0 0.3rem 0;

    text-align: center;
    @include heading-5;

    color: $color-white;
    background: $color-blue-product;
    border-radius: 1.6rem;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__details {
    margin: 3rem 0 2rem 0.9rem;
  }

  &__link {
    display: block;
    margin-top: 0.4rem;

    color: $color-black;
    transition: all $ease-main;

    &:last-child {
      color: $color-red;
    }

    &:first-child {
      margin-bottom: 1.8rem;
    }

    &:hover,
    &:active {
      opacity: 0.5;
    }
  }

  input {
    border: 1px solid #f2f2f2;
  }

  textarea {
    border: 1px solid #f2f2f2;
  }
}

.modal-search,
.modal-history {
  display: none;
  position: absolute;
  min-height: 51.6rem;
  padding: 1.7rem 2.4rem 2.4rem 2.5rem;
  z-index: 2;
  left: calc(100% + 8rem - 69rem);
  right: -8rem;
  top: -0.8rem;
  overflow: hidden;
  border-radius: 0.8rem;
  box-shadow: 0 2rem 2.4rem 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  background: $color-white;
  transition: opacity $ease-main, transform $ease-main;
  transform: translate3d(0, 5px, 0);
  opacity: 0;

  &.is-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  @include screen('sm') {
    left: calc(100% + 8rem - 91rem);
  }

  @include screen('md') {
    left: calc(100% + 8rem - 113.6rem);
  }

  @include screen('lg') {
    left: calc(100% + 8rem - 135.6rem);
  }

  .product::after {
    transition: opacity $ease-main;
  }

  @media (max-width: 1239px) {
    .swiper-slide-visible {
      &::after {
        opacity: 0;
      }
    }
  }

  @media (min-width: 1240px) and (max-width: 1439px) {
    .swiper-slide-visible + .swiper-slide-visible {
      &::after {
        opacity: 0;
      }
    }
  }

  @media (min-width: 1440px) and (max-width: 1888px) {
    .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible {
      &::after {
        opacity: 0;
      }
    }
  }

  @media (min-width: 1888px) {
    .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible + .swiper-slide-visible {
      &::after {
        opacity: 0;
      }
    }
  }

  &__header {
    font-size: 2rem;
    line-height: normal;
    color: $color-black;
    width: 24rem;

    @include screen('sm') {
      width: 40rem;
    }

    @include screen('md') {
      width: 50rem;
    }
  }

  &__count {
    color: $color-red;
  }

  &__close {
    position: absolute;
    width: 2.6rem;
    height: 2.4rem;
    right: 2.6rem;
    top: 1.5rem;
    transition: opacity $ease-main;

    &::before,
    &::after {
      @include pseudo;

      width: 100%;
      height: 2px;
      background: $color-black;
    }

    &::before {
      transform: rotate(-135deg);
    }

    &::after {
      transform: rotate(135deg);
    }

    &:hover {
      opacity: .5;
    }
  }

  &__wrapper {
    display: flex;
    margin-top: 3.3rem;
  }

  &__container {
    margin-left: -1.4rem;
    margin-right: -1.4rem;
  }

  &__col-1, &__col-2 {
    width: 22.2rem;
    padding-right: 2rem;
    box-sixing: border-box;
  }

  &__col-3 {
    position: relative;
    width: calc(100% - 44.4rem);
  }

  &__title {
    @include heading-9;
    color: #666666;
  }

  &__list {
    margin-top: 0.6rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.95rem;
    }
  }

  &__link {
    font-size: 2rem;
    color: $color-blue-product;
    transition: opacity $ease-main;

    &:hover {
      opacity: .5;
    }
  }

  &__btn {
    width: 16.4rem;
    margin-top: 4.8rem;
    padding: 0.2rem 0.5rem 0.3rem 0.5rem;

    font-size: 1.6rem;
    color: $color-white;
    background: $color-blue-product;
    text-align: center;
    border-radius: 1.6rem;
    transition: all $ease-main;

    &:hover {
      opacity: 0.5;
    }
  }

  &__buttons {
    position: absolute;
    right: 0;
    top: 0.9rem;
    z-index: 0;
  }

  &__prev {
    margin-right: 1.2rem;
  }

  &__inner {
    margin: 1rem 0 0 0;
  }

  &__history {
    display: block;
    margin-top: 1.5rem;
    color: $color-blue-product;
    text-align: right;
    transition: opacity $ease-main;

    &:hover {
      opacity: .5;
    }
  }

  .product__promo {
    display: none;
  }

  .product__buttons {
    width: 107%;
  }
}

.modal-history {
  min-height: 46.8rem;
}


.switcher {
  display: flex;
  align-items: center;

  input {
    display: none;

    &:checked~.switcher__box .switcher__circle {
      transform: translate(150%, -50%);
    }

    &:checked~.switcher__box .switcher__mover {
      background: $color-red;
    }
  }

  &__box {
    width: 3.6rem;
    height: 1.6rem;

    cursor: pointer;
    transition: all .3s ease;
  }

  &__mover {
    position: relative;
    width: 100%;
    height: 0.2rem;

    top: 50%;
    transform: translateY(-50%);
    background: $color-grey-product;
    border-radius: 2rem;
    transition: background $ease-main;
  }

  &__circle {
    @include pseudo;
    width: 1.6rem;
    height: 1.6rem;
    left: 0;
    top: 50%;

    background: $color-blue-product;
    border-radius: 50%;
    transform: translateY(-50%);
    overflow: hidden;

    transition: transform $ease-main;
  }
}

.alert-box {
  color: $color-red;
  margin: 0.5rem 0 0 0.5rem;
  display: none;
}
