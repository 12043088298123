@import '../../node_modules/swiper/dist/css/swiper.css';

.slider {
  position: relative;
  border: 1px solid $color-border;

  &__slide {
    padding: 100px;
    text-align: center;
  }

  &__dots {
    position: absolute;
    bottom: 10px;
    left: 20px;
    right: 20px;
    text-align: center;
    z-index: 2;
    pointer-events: none;
  }

  &__dot {
    margin: 5px;
    width: 10px;
    height: 10px;
    border: 1px solid $color-border;
    border-radius: 100px;
    display: inline-block;
    position: relative;
    transition: border-color $ease-main, background-color $ease-main;
    pointer-events: all;
    cursor: pointer;

    &.active {
      background-color: $color-main;
      border-color: $color-main;
    }
  }

  &__button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 1px solid $color-border;
    transition: opacity $ease-main;
    z-index: 2;
    cursor: pointer;
    background-color: white;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-style: solid;
      border-color: $color-basic;
      border-width: 0;
      position: absolute;
      transform: rotate(45deg);
      transform-origin: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:hover { opacity: .5; }

    &_next {
      right: 20px;

      &::before {
        border-top-width: 2px;
        border-right-width: 2px;
        right: 3px;
      }
    }

    &_prev {
      left: 20px;

      &::before {
        border-bottom-width: 2px;
        border-left-width: 2px;
        left: 3px;
      }
    }
  }
}
