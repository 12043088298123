.link {
  @include heading-6;

  color: $color-grey-header-first;
  transition: opacity $ease-main, color $ease-main, fill $ease-main, stroke $ease-main;

  &:hover {
    opacity: 0.5;
  }

  &_icon {
    position: relative;
    padding-left: 25px;
  }

  &__arrow {
    width: 6px;
    height: 6px;
    fill: inherit;
    vertical-align: middle;
    margin: 0 0 .1rem .5rem;
    transition: transform $ease-main;

    .link.is-active & {
      transform: rotate(180deg);
    }
  }

  &__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
  }

  &.is-disabled {
    pointer-events: none;
    fill: $color-border;
    @include link-disabled;
  }

  &__text {
  }
}
