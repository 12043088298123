@mixin link {
  color: $color-main;
  border: 0;
  transition: fill $ease-main, color $ease-main, opacity $ease-main, border-color $ease-main;
  fill: $color-main;

  &__text {
    border-bottom: 1px solid;
    border-color: inherit;
  }

  &:hover { opacity: .5; }
}

@mixin link-disabled {
  color: $color-border;
  border-color: transparent;
}

@mixin link-dashed {
  border-bottom-style: dashed;
}
