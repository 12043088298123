.list {
  @include list;
}

ul.list {
  @include ullist;
}

ol.list {
  @include ollist;
}
