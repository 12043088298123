.index {
  display: block;
  height: 100000px;
}

.overlay, .overlay_light {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  overflow: hidden;
  transition: opacity $ease-main;
  transition-delay: .1s;

  &.is-top {
    z-index: 0;

    .header.is-fixed & {
      z-index: -1;
    }
  }

  &.is-active {
    opacity: 1;
    transition-delay: 0s;
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.9);
}

.overlay_light {
  background: rgba(0, 0, 0, 0.5);
}
