.main {
  margin-top: 2.5rem;

  @include screen('sm') {
    margin-top: 2rem;
  }

  &__stock {
    margin-left: 4rem;

    @include screen('lg') {
      padding-left: 0;
      margin-left: 4.7rem;
    }

    @media (min-width: 2000px) {
      margin: 0 auto;
    }

    .goods__header {
      @include screen('lg') {
        margin-right: 0.7rem;
      }
    }

    .goods__list {
      margin-left: 13rem;

      @include screen('sm') {
        margin-left: 23.7rem;
        margin-top: 0.7rem;
      }

      @include screen('md') {
        margin-left: 33.7rem;
      }

      @include screen('lg') {
        margin-left: 56.2rem;
      }
    }

    .goods__controlers {
      margin-left: auto;
    }

    .swiper-slide {
      opacity: 1;
    }

    .swiper-container {
      overflow: visible;
    }
  }

  &__set {
    margin-top: 2.4rem;

    .goods__header {
      padding-right: 1.6rem;
    }

    .goods__list {
      margin-left: 7rem;

      @include screen('sm') {
        margin: 0.1rem 0 0 18.6rem;
      }

      @include screen('md') {
        margin-left: 27.8rem;
      }

      @include screen('lg') {
        margin-left: 50.2rem;
      }
    }
  }

  &__notebook {
    display: none;
    justify-content: space-between;
    margin-top: 3.4rem;

    @include screen('sm') {
      display: flex;
      margin-top: 3rem;
    }

    @include screen('lg') {
      display: none;
    }
  }

  &__internet {
    display: flex;
    justify-content: space-between;
    margin-top: 2.4rem;

    @include screen('sm') {
      margin-top: 1.8rem;
    }

    @include screen('lg') {
      justify-content: space-between;
      margin: 3.2rem auto 0 auto;
    }

    .collection {
      @include screen('lg') {
        margin-right: 0.8rem;
      }
    }
  }

  &__games {
    margin-top: 2.5rem;
    padding-left: 4rem;

    // @include screen('lg') {
    //   display: none;
    // }

    .goods__list {
      margin: 0.5rem 0 0 14.5rem;

      @include screen('sm') {
        margin-left: 25.3rem;
      }

      @include screen('md') {
        margin-left: 35.3rem;
      }

      @include screen('lg') {
        margin-left: 50.2rem;
      }
    }

    .goods__controlers {
      margin-left: auto;

      @include screen('sm') {
        margin-left: 13.7rem;
      }

      @include screen('md') {
        margin-left: 23.7rem;
      }

      @include screen('lg') {
        margin-left: auto;
      }
    }

    // .goods__galaxy {
    //   display: flex;
    // }

    .swiper-container {
      overflow: visible;
    }
  }

  &__buyers {
    margin-top: 2.5rem;

    @include screen('sm') {
      margin-top: 5.7rem;
    }

    @include screen('lg') {
      margin-top: 2.4rem;
    }

    .goods__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1.6rem;
    }

    .goods__link {
      margin-top: 1.2rem;
    }

    .goods__content {
      justify-content: space-between;
    }
  }

  &__services {
    display: none;
    margin-top: 2.4rem;
    padding-left: 4rem;

    @include screen('sm') {
      display: block;
    }

    // @include screen('lg') {
    //   display: none;
    // }

    .goods__list {
      margin-left: 26.1rem;

      @include screen('md') {
        margin-left: 36.1rem;
      }

      @include screen('lg') {
        margin-left: 50.2rem;
      }
    }

    .goods__controlers {
      margin-left: auto;
    }

    .swiper-container {
      overflow: visible;
    }

    .card {
      display: none;

      &.visible {
        display: flex;
      }
    }
  }

  &__review {
    margin: 4.1rem 0 0 4rem;

    @include screen('lg') {
      margin-left: 4.8rem;
    }

    @media (min-width: 2000px) {
      margin: 0 auto;
    }

    .goods__header {
      justify-content: space-between;

      @include screen('lg') {
        padding-right: 6.3rem;
      }
    }

    .goods__title {
      width: 20rem;
    }

    .swiper-container {
      overflow: visible;
    }

    .swiper-slide {
      opacity: 1;
    }
  }
}
